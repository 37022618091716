import { useIsKlarityEmployee } from './useIsKlarityEmployee'
import useCurrentUser from './useCurrentUser'

// hooks

export const useIsGoogleTenantNonKlarityUser = () => {
  const currentUser = useCurrentUser()
  const isKlarityEmployee = useIsKlarityEmployee()

  return Boolean(currentUser?.customers?.edges[0]?.node?.name.match(/^Google /) && !isKlarityEmployee)
}
