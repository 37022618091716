import 'rc-collapse/assets/index.css'
import { AccountingImpactFilter } from './AccountingImpactFilter'
import { Box } from '@mui/material'
import { ChecklistQueries } from './ChecklistQueries'
import { ChevronDown } from 'react-feather'
import { DataMatchingFilters, DataPointFieldSources, MatchingDataPointsFilters, _DataMatchingFilters } from '../DatapointField/DataMatching'
import { DataPoint, DataPointAnnotations, Maybe } from '../../graphql/codegen/schemas'
import { DataPointField } from '../DatapointField'
import { DealChecklistFooterContainer, DocumentChecklistFooterContainer } from './Footer'
import { DownloadChecklistButton } from './DownloadChecklistButton'
import { ExpandCollapseButtons } from './ExpandCollapseButtons'
import { FailureNotifications } from './FailureNotifications'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { RerunExtractionBanner } from './RerunExtractionBanner'
import { SearchBar } from './SearchBar'
import { UserReviewFilter } from '../DatapointField/UserReview'
import { countTruthyValuesForDataPointGroup } from '../../utils/dataPointUtils'
import { formatKey } from '../../utils/stringUtils'
import { isEmpty } from 'lodash'
import { parseIdFromHash } from '../PDFHighlighterDoc/utils'
import { useChecklistGroupsQuery, useDataPointsForGroupQuery } from '../../graphql/codegen/hooks'
import { useIsExtractionRerunEnabled } from '../../hooks/useIsExtractionRerunEnabled'
import ChecklistSkeleton, { GroupDataPointsSkeleton } from './ChecklistSkeleton'
import Collapse, { Panel } from 'rc-collapse'
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'
import useIsRapid7LegalTeam from '../../hooks/useIsRapid7LegalTeam'
import useQString from '../../hooks/useQString'

// types

type _ChecklistTabProps = { dealId?: string; dealIsFinalized: boolean; documentId?: string }

export type _DataPointsGrouped = { [key: number]: DataPoint[] }

type _DataPointsGroupProps = {
  dataMatchingFilters: _DataMatchingFilters
  dealIsFinalized: boolean
  filteredDataPointIdList: string[] | null
  focusedDataPointId: string | null
  group: string
  hasAccountingImpactFields: boolean
  hasMatchingDataPointFields: boolean
  hasUserReviewFields: boolean
  index: number
  isDocumentId: boolean
  isFilteringByAccountingImpact: boolean
  isFilteringByUserReview: boolean
  isLoadingDataPoints: boolean
  resourceId: string
  selectedAnnotationId: string | null
  setActiveKeys: Dispatch<SetStateAction<string[]>>
  setHasAccountingImpactFields: Dispatch<SetStateAction<boolean>>
  setHasMatchingDataPointFields: Dispatch<SetStateAction<boolean>>
  setHasUserReviewFields: Dispatch<SetStateAction<boolean>>
  stringFilter: string
  updateDataPointsGrouped: (dataPoints: DataPoint[], index: number) => void
  updateDataPointsGroupedUnfiltered: (dataPoints: DataPoint[], index: number) => void
}

// functions

export const containsSelectedAnnotation = (dataPoint: DataPoint, selectedAnnotationId: string | null | undefined) => {
  const hasAnnotation = (annotations: Maybe<DataPointAnnotations>[], selectedAnnotationId?: string | null): boolean =>
    annotations.some(edge => (edge?.annotations || []).some(annotation => annotation?.id === selectedAnnotationId))

  if (hasAnnotation(dataPoint.annotations || [], selectedAnnotationId)) return true

  const documentDataPointsAnnotations = (dataPoint.document_data_points?.edges?.flatMap(edge => edge?.node?.annotations) || []) as Maybe<DataPointAnnotations>[]

  if (hasAnnotation(documentDataPointsAnnotations, selectedAnnotationId)) return true

  const matchingChildrenDataPointsAnnotations = (dataPoint.matching_children_data_points?.edges?.flatMap(edge => edge?.node?.annotations) ||
    []) as Maybe<DataPointAnnotations>[]

  if (hasAnnotation(matchingChildrenDataPointsAnnotations, selectedAnnotationId)) return true

  return false
}

// components

export const ChecklistTab: FC<_ChecklistTabProps> = ({ dealId, dealIsFinalized, documentId }) => {
  const { data: checkListGroupsData } = useChecklistGroupsQuery({ variables: { resourceId: dealId || documentId! } })
  const { dataPoint: selectedDataPointId, unresolved: unresolvedDataPointId } = useQString()
  const isRapid7LegalTeam = useIsRapid7LegalTeam()
  const checklistGroups = useMemo(() => (checkListGroupsData ? (checkListGroupsData.checklist_groups as string[]) : null), [checkListGroupsData])
  const [accountingImpactDataPointCount, setAccountingImpactDataPointCount] = useState(0)
  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const [dataMatchingFilters, setDataMatchingFilters] = useState<_DataMatchingFilters>({
    [DataMatchingFilters.MATCHING_DATA_POINTS]: false,
    [DataMatchingFilters.NON_MATCHING_DATA_POINTS]: false
  })
  const [dataPointsGrouped, setDataPointsGrouped] = useState<_DataPointsGrouped>({})
  const [dataPointsGroupedUnfiltered, setDataPointsGroupedUnfiltered] = useState<_DataPointsGrouped>([])
  const [filteredDataPointIdList, setFilteredDataPointIdList] = useState<string[] | null>(null)
  const [focusedDataPointId, setFocusedDataPointId] = useState<string | null>(null)
  const [hasAccountingImpactFields, setHasAccountingImpactFields] = useState(false)
  const [hasMatchingDataPointFields, setHasMatchingDataPointFields] = useState(false)
  const [hasUserReviewFields, setHasUserReviewFields] = useState(false)
  const [isFilteringByAccountingImpact, setIsFilteringByAccountingImpact] = useState(false)
  const [isFilteringByUserReview, setIsFilteringByUserReview] = useState(false)
  const [matchingDataPointCount, setMatchingDataPointCount] = useState(0)
  const [nonMatchingDataPointCount, setNonMatchingDataPointCount] = useState(0)
  const [selectedAnnotationId, setSelectedAnnotationId] = useState<string | null>(null)
  const [stringFilter, setStringFilter] = useState('')
  const [userReviewDataPointCount, setUserReviewDataPointCount] = useState(0)

  const hasDealAccess = useUserAccess({ feature: Features.DEAL, permission: Permissions.READ })
  const hasFooter = Boolean(dealId || (documentId && !hasDealAccess))
  const isExtractionRerunEnabled = useIsExtractionRerunEnabled()
  const isLoadingDataPoints = Object.keys(dataPointsGrouped).length !== checklistGroups?.length

  const updateDataPointsGrouped = useCallback(
    (dataPoints: DataPoint[], index: number) =>
      setDataPointsGrouped(previous => {
        const current = { ...previous }

        current[index] = dataPoints

        return current
      }),
    []
  )

  const updateDataPointsGroupedUnfiltered = useCallback(
    (dataPoints: DataPoint[], index: number) =>
      setDataPointsGroupedUnfiltered(previous => {
        const current = { ...previous }

        current[index] = dataPoints

        return current
      }),
    []
  )

  const expandAll = useCallback(() => {
    if (checklistGroups) {
      setActiveKeys(checklistGroups.map(formatKey))
    }
  }, [checklistGroups])

  useMemo(() => {
    if (checklistGroups && stringFilter) {
      expandAll()
    }
  }, [checklistGroups, expandAll, stringFilter])

  useMemo(() => {
    const dataPointsFlattened = Object.values(dataPointsGroupedUnfiltered).flat()

    setMatchingDataPointCount(
      dataPointsFlattened.filter(dataPoint => dataPoint.data_point_field?.source === DataPointFieldSources.MATCHING && Boolean(dataPoint.value_bool)).length
    )

    setNonMatchingDataPointCount(
      dataPointsFlattened.filter(dataPoint => dataPoint.data_point_field?.source === DataPointFieldSources.MATCHING && !dataPoint.value_bool).length
    )

    setUserReviewDataPointCount(dataPointsFlattened.filter(dataPoint => dataPoint.user_needs_review).length)

    setAccountingImpactDataPointCount(dataPointsFlattened.filter(dataPoint => dataPoint.has_accounting_impact).length)
  }, [dataPointsGroupedUnfiltered])

  useEffect(() => {
    // Aria-labels must be set manually because the rc-collapse API does not provide a way to set them.
    document.querySelectorAll('.rc-collapse-header').forEach(element => element.setAttribute('aria-label', 'Expand/Collapse'))
  }, [checklistGroups])

  useEffect(() => {
    const setSelectedAnnotationIdFromHash = () => {
      const id = parseIdFromHash()

      if (checklistGroups && id) {
        expandAll()

        setSelectedAnnotationId(id)
      } else {
        setSelectedAnnotationId(null)
      }
    }

    if (checklistGroups) {
      setSelectedAnnotationIdFromHash()

      window.addEventListener('hashchange', setSelectedAnnotationIdFromHash, false)

      return () => window.removeEventListener('hashchange', setSelectedAnnotationIdFromHash, false)
    }
  }, [checklistGroups, expandAll])

  useEffect(() => {
    setFocusedDataPointId(null)
  }, [selectedAnnotationId, selectedDataPointId, unresolvedDataPointId])

  // Set the data point to focus on based on the following criteria:
  useEffect(() => {
    if (focusedDataPointId) return

    const dataPointList = Object.values(dataPointsGrouped).flat()

    // 1. Check for unresolved data point.
    const unresolvedDataPoint = dataPointList.find(({ id }) => id === unresolvedDataPointId)

    if (unresolvedDataPoint) {
      return setFocusedDataPointId(unresolvedDataPoint.id)
    }

    // 2. Check for data point selected by the "Jump to Annotation" button.
    const selectedDataPoint = dataPointList.find(
      dataPoint =>
        dataPoint.id === selectedDataPointId || dataPoint.document_data_points?.edges?.flatMap(edge => edge?.node?.id).includes(selectedDataPointId as string)
    )

    if (selectedDataPoint) {
      return setFocusedDataPointId(selectedDataPoint.id)
    }

    // 3. Check for data point with the same label or internal_name as the highlight popup.
    const highlightPopup = document.querySelector<HTMLDivElement>('#highlight-popup')
    const highlightPopupLabel = highlightPopup?.getAttribute('data-label')?.trim() || ''
    const highlightPopupInternalName = highlightPopup?.getAttribute('data-internal-name') || ''
    const highlightPopupDataPoint = dataPointList.find(
      ({ data_point_field }) =>
        data_point_field?.name.trim() === highlightPopupLabel ||
        (data_point_field?.internal_mapping?.includes(highlightPopupInternalName) && data_point_field?.options?.includes(highlightPopupLabel))
    )

    if (highlightPopupDataPoint) {
      return setFocusedDataPointId(highlightPopupDataPoint.id)
    }

    // 4. Fall back to the data point with the smallest annotations array that contains the selected annotation.
    if (!isLoadingDataPoints && selectedAnnotationId) {
      const dataPointsContainingSelectedAnnotation = dataPointList
        .filter(dataPoint => containsSelectedAnnotation(dataPoint, selectedAnnotationId))
        .sort((a, b) => (a.annotations?.length || 0) - (b.annotations?.length || 0))

      if (!isEmpty(dataPointsContainingSelectedAnnotation)) {
        return setFocusedDataPointId(dataPointsContainingSelectedAnnotation[0].id)
      }
    }
  }, [dataPointsGrouped, focusedDataPointId, isLoadingDataPoints, selectedAnnotationId, selectedDataPointId, unresolvedDataPointId])

  if (!checklistGroups) return <ChecklistSkeleton />

  return (
    <>
      {isExtractionRerunEnabled && (
        <RerunExtractionBanner dataPointsGrouped={dataPointsGrouped} dealId={dealId} documentId={documentId} isLoadingDataPoints={isLoadingDataPoints} />
      )}

      <Box sx={{ alignItems: 'center', display: 'flex', '& div:first-of-type': { pr: dealId ? 0 : 1 } }}>
        <SearchBar isLoading={isLoadingDataPoints} setFilter={setStringFilter} value={stringFilter} />

        {dealId && <DownloadChecklistButton dealId={dealId} />}
      </Box>

      <Box sx={{ display: 'flex', gap: 0.5, justifyContent: 'space-between', pb: 2, px: 2 }}>
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          <ChecklistQueries isDisabled={isLoadingDataPoints} setFilteredDataPointIdList={setFilteredDataPointIdList} />

          <MatchingDataPointsFilters
            dataMatchingFilters={dataMatchingFilters}
            expandAll={expandAll}
            hasMatchingDataPointFields={hasMatchingDataPointFields}
            isDisabled={isLoadingDataPoints}
            matchingDataPointCount={matchingDataPointCount}
            nonMatchingDataPointCount={nonMatchingDataPointCount}
            setDataMatchingFilters={setDataMatchingFilters}
          />

          <UserReviewFilter
            expandAll={expandAll}
            hasUserReviewFields={hasUserReviewFields}
            isDisabled={isLoadingDataPoints}
            setValue={setIsFilteringByUserReview}
            userReviewDataPointCount={userReviewDataPointCount}
            value={isFilteringByUserReview}
          />

          <AccountingImpactFilter
            accountingImpactDataPointCount={accountingImpactDataPointCount}
            expandAll={expandAll}
            hasAccountingImpactFields={hasAccountingImpactFields}
            isDisabled={isLoadingDataPoints}
            setValue={setIsFilteringByAccountingImpact}
            value={isFilteringByAccountingImpact}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 0.5, justifyContent: 'space-between' }}>
          <FailureNotifications dealId={dealId} documentId={documentId} />

          <ExpandCollapseButtons
            isCollapseDisabled={isEmpty(activeKeys)}
            isExpandDisabled={activeKeys.length === checklistGroups?.length}
            onCollapseClick={() => setActiveKeys([])}
            onExpandClick={expandAll}
          />
        </Box>
      </Box>

      <div className={clsx(css.inner, !hasFooter && css.noFooter)} style={{ backgroundColor: '#fff' }}>
        <Collapse
          activeKey={activeKeys}
          expandIcon={({ isActive }: { isActive: boolean }) => (
            <ChevronDown aria-label={isActive ? 'Collapse' : 'Expand'} style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />
          )}
          onChange={setActiveKeys}
        >
          {checklistGroups.map((checklistGroup, index) => (
            <DataPointsGroup
              dataMatchingFilters={dataMatchingFilters}
              dealIsFinalized={dealIsFinalized}
              filteredDataPointIdList={filteredDataPointIdList}
              focusedDataPointId={focusedDataPointId}
              group={checklistGroup}
              hasAccountingImpactFields={hasAccountingImpactFields}
              hasMatchingDataPointFields={hasMatchingDataPointFields}
              hasUserReviewFields={hasUserReviewFields}
              index={index}
              isDocumentId={Boolean(documentId)}
              isFilteringByAccountingImpact={isFilteringByAccountingImpact}
              isFilteringByUserReview={isFilteringByUserReview}
              isLoadingDataPoints={isLoadingDataPoints}
              key={formatKey(checklistGroup)}
              resourceId={dealId || documentId!}
              selectedAnnotationId={selectedAnnotationId}
              setActiveKeys={setActiveKeys}
              setHasAccountingImpactFields={setHasAccountingImpactFields}
              setHasMatchingDataPointFields={setHasMatchingDataPointFields}
              setHasUserReviewFields={setHasUserReviewFields}
              stringFilter={stringFilter.toLowerCase()}
              updateDataPointsGrouped={updateDataPointsGrouped}
              updateDataPointsGroupedUnfiltered={updateDataPointsGroupedUnfiltered}
            />
          ))}
        </Collapse>
      </div>

      {dealId && <DealChecklistFooterContainer dealIsFinalized={dealIsFinalized} />}

      {documentId && (!hasDealAccess || isRapid7LegalTeam) && <DocumentChecklistFooterContainer />}
    </>
  )
}

const DataPointsGroup: FC<_DataPointsGroupProps> = ({
  dataMatchingFilters,
  dealIsFinalized,
  filteredDataPointIdList,
  focusedDataPointId,
  group,
  hasAccountingImpactFields,
  hasMatchingDataPointFields,
  hasUserReviewFields,
  index,
  isDocumentId,
  isFilteringByAccountingImpact,
  isFilteringByUserReview,
  isLoadingDataPoints,
  resourceId,
  selectedAnnotationId,
  setActiveKeys,
  setHasAccountingImpactFields,
  setHasMatchingDataPointFields,
  setHasUserReviewFields,
  stringFilter,
  updateDataPointsGrouped,
  updateDataPointsGroupedUnfiltered,
  ...props
}) => {
  const { dataPoint: selectedDataPointId, unresolved: unresolvedDataPointId } = useQString()

  const { data: groupDataPointsData } = useDataPointsForGroupQuery({ variables: { group, resourceId } })

  useEffect(() => {
    if (!hasAccountingImpactFields && groupDataPointsData?.data_points_for_group?.some(dataPoint => dataPoint?.has_accounting_impact)) {
      setHasAccountingImpactFields(true)
    }
  }, [groupDataPointsData, hasAccountingImpactFields, setHasAccountingImpactFields])

  useEffect(() => {
    if (
      !hasMatchingDataPointFields &&
      groupDataPointsData?.data_points_for_group?.some(dataPoint => dataPoint?.data_point_field?.source === DataPointFieldSources.MATCHING)
    ) {
      setHasMatchingDataPointFields(true)
    }
  }, [groupDataPointsData, hasMatchingDataPointFields, setHasMatchingDataPointFields])

  useEffect(() => {
    if (!hasUserReviewFields && groupDataPointsData?.data_points_for_group?.some(dataPoint => dataPoint?.user_needs_review)) {
      setHasUserReviewFields(true)
    }
  }, [groupDataPointsData, hasUserReviewFields, setHasUserReviewFields])

  // Ensure auto-scroll works correctly on initial page load by only expanding the group containing the `unresolvedDataPointId` or `selectedDataPointId`.
  useLayoutEffect(() => {
    const hasActiveDataPoint = groupDataPointsData?.data_points_for_group?.some(dataPoint =>
      unresolvedDataPointId ? dataPoint?.id === unresolvedDataPointId : dataPoint?.id === selectedDataPointId
    )

    if (hasActiveDataPoint) setActiveKeys([formatKey(group)])
  }, [groupDataPointsData]) // eslint-disable-line react-hooks/exhaustive-deps

  const filterAccountingImpact = useCallback(
    (dataPoint: DataPoint) => !isFilteringByAccountingImpact || dataPoint.has_accounting_impact,
    [isFilteringByAccountingImpact]
  )

  const filterChecklistQuery = useCallback(
    (dataPoint: DataPoint) => (filteredDataPointIdList ? filteredDataPointIdList.includes(dataPoint.id) : true),
    [filteredDataPointIdList]
  )

  const filterDataMatching = useCallback(
    (dataPoint: DataPoint) =>
      dataMatchingFilters.matching && dataMatchingFilters.nonMatching
        ? dataPoint.data_point_field?.source === DataPointFieldSources.MATCHING
        : dataMatchingFilters.matching
        ? dataPoint.data_point_field?.source === DataPointFieldSources.MATCHING && (dataPoint.value_bool as boolean)
        : dataMatchingFilters.nonMatching
        ? dataPoint.data_point_field?.source === DataPointFieldSources.MATCHING && (!dataPoint.value_bool as boolean)
        : true,
    [dataMatchingFilters]
  )

  const filterString = useCallback(dataPoint => !stringFilter || dataPoint.data_point_field!.name.toLowerCase().includes(stringFilter), [stringFilter])

  const filterUserReview = useCallback((dataPoint: DataPoint) => !isFilteringByUserReview || dataPoint.user_needs_review, [isFilteringByUserReview])

  const groupDataPoints = useMemo(
    () =>
      groupDataPointsData
        ? [...(groupDataPointsData.data_points_for_group as DataPoint[])]
            .filter(filterAccountingImpact)
            .filter(filterChecklistQuery)
            .filter(filterDataMatching)
            .filter(filterString)
            .filter(filterUserReview)
            .sort((a, b) => (a.data_point_field!.priority > b.data_point_field!.priority ? 1 : -1))
        : null,
    [filterAccountingImpact, filterChecklistQuery, filterDataMatching, filterString, filterUserReview, groupDataPointsData]
  )

  const numComplete = useMemo(() => (groupDataPoints ? countTruthyValuesForDataPointGroup(groupDataPoints) : 0), [groupDataPoints])

  useEffect(() => {
    if (groupDataPointsData) {
      updateDataPointsGroupedUnfiltered(groupDataPointsData.data_points_for_group as DataPoint[], index)
    }
  }, [groupDataPointsData, index, updateDataPointsGroupedUnfiltered])

  useEffect(() => {
    if (groupDataPoints) {
      updateDataPointsGrouped(groupDataPoints, index)
    }
  }, [groupDataPoints, index, updateDataPointsGrouped])

  if (!isLoadingDataPoints && isEmpty(groupDataPoints)) return null

  return (
    <Panel
      {...props}
      header={
        <div className="groupHeader">
          <h3>{group}</h3>

          {groupDataPoints && isDocumentId && (
            <span>
              {numComplete} / {groupDataPoints.length} items completed
            </span>
          )}
        </div>
      }
    >
      {groupDataPoints ? (
        <ul>
          {groupDataPoints.map(dataPoint => (
            <DataPointField
              dataPoint={dataPoint}
              dealIsFinalized={dealIsFinalized}
              focusedDataPointId={focusedDataPointId}
              key={dataPoint.id}
              selectedAnnotationId={selectedAnnotationId}
            />
          ))}
        </ul>
      ) : (
        <GroupDataPointsSkeleton />
      )}
    </Panel>
  )
}
