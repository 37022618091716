import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import { CciDealDataPointFieldsQuery } from '../../../../../../../graphql/codegen/operations'
import { toast } from 'react-toastify'
import { useAppContext } from '../../../../../../../app'
import { useCciDealDataPointFieldsQuery, useChangeNeutralTagMutation } from '../../../../../../../graphql/codegen/hooks'
import { useCciMainContext } from '../../../../../CCI_Main'
import React, { FC, useCallback, useEffect, useState } from 'react'
import css from './style.module.scss'

// functions

export const validateAutomationTag = (
  automationTag: string | undefined,
  checklistData: CciDealDataPointFieldsQuery | undefined,
  currentFieldId?: string
): string | undefined => {
  const trimmedAutomationTag = automationTag?.trim()

  if (!trimmedAutomationTag) return undefined

  const existingField = checklistData?.cci_deal_data_point_fields?.find(
    field => field !== null && (!currentFieldId || field.id !== currentFieldId) && (field.external_mapping?.includes(trimmedAutomationTag) ?? false)
  )

  return existingField ? `Automation tag "${trimmedAutomationTag}" is already in use by the field "${existingField.name}"` : undefined
}

// components

export const AutomationTagInput: FC = () => {
  const { data: cciDealDataPointFieldsData } = useCciDealDataPointFieldsQuery()
  const { selectedItem, setSelectedItem } = useCciMainContext()
  const { setErrorMessage, setExtendedErrorMessage } = useAppContext()

  const [validationError, setValidationError] = useState<string>()
  const [value, setValue] = useState(selectedItem?.external_mapping?.[0] || '')

  const [editAutomationTag, { loading: isMutationLoading }] = useChangeNeutralTagMutation({
    onCompleted: data => {
      // Updates to Apollo's cache are not automatically dispatched to `selectedItem`, so it must be updated manually.
      setSelectedItem(data.change_neutral_tag?.data_point_fields?.[0])

      toast.success('Automation tag successfully updated', { autoClose: 5000 })
    },
    onError: error => {
      setErrorMessage('Failed to update automation tag')
      setExtendedErrorMessage(error.message)
    }
  })

  const handleBlur = useCallback(() => {
    const newValue = value?.trim()

    if (!newValue) return setValue(selectedItem?.external_mapping?.[0])

    if (selectedItem?.id && newValue !== selectedItem.external_mapping?.[0] && !validationError) {
      editAutomationTag({
        variables: {
          change_annotations: true,
          change_other_fields: false,
          data_point_field_id: selectedItem.id,
          new_neutral_tag: newValue,
          old_neutral_tag: selectedItem.external_mapping?.[0]
        }
      })
    }
  }, [editAutomationTag, value, selectedItem, validationError, setValue])

  useEffect(() => {
    const validationError = validateAutomationTag(value, cciDealDataPointFieldsData, selectedItem.id)

    setValidationError(validationError)
  }, [cciDealDataPointFieldsData, selectedItem, value])

  return (
    <Box className={css.inputWrapper} sx={{ mt: 1 }}>
      <Typography component="label" htmlFor="automation-tag-input" sx={{ flexShrink: 0, fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        Automation Tag
      </Typography>

      <TextField
        FormHelperTextProps={{ sx: { ml: 0 } }}
        InputProps={{ endAdornment: isMutationLoading ? <CircularProgress size={20} /> : null, sx: { fontSize: 14 } }}
        autoComplete="off"
        disabled={isMutationLoading}
        error={Boolean(validationError)}
        fullWidth
        helperText={validationError}
        id="automation-tag-input"
        onBlur={handleBlur}
        onChange={event => setValue(event.target.value)}
        size="small"
        value={value}
        variant="outlined"
      />
    </Box>
  )
}
