import { Box, Button, Collapse, MenuItem, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'
import { ButtonLoading } from '../ButtonLoading'
import { CalculationSources, ExternalSources } from '../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/CreateInputs/SourceInput'
import { DATA_POINT_PRODUCT_TABLE_MATCH_LIST } from './DatapointField'
import { DataPoint } from '../../graphql/codegen/schemas'
import { NavLink, useHistory } from 'react-router-dom'
import { Opening } from '@hoologic/use-opening'
import { Source } from './Source'
import { grey } from '@mui/material/colors'
import { useDocumentPageWrapperContext } from '../DocumentPageWrapper'
import { useFormik } from 'formik'
import { useIsSpecificGoogleTenantNonCalculationField } from '../../hooks/useIsSpecificGoogleTenantNonCalculationField'
import { useResolveMatchingMutation } from '../../graphql/codegen/hooks'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import React, { Dispatch, FC, Fragment, SetStateAction, useCallback, useMemo, useState } from 'react'
import css from './style.module.scss'

// types

type _BypassMatchingControlProps = { opening: Opening }
type _BypassMatchingFormProps = { dataPoint: DataPoint; opening: Opening }
export type _DataMatchingFilters = { [DataMatchingFilters.MATCHING_DATA_POINTS]: boolean; [DataMatchingFilters.NON_MATCHING_DATA_POINTS]: boolean }

type _MatchingDataPointsFiltersProps = {
  dataMatchingFilters: _DataMatchingFilters
  expandAll: () => void
  hasMatchingDataPointFields: boolean
  isDisabled: boolean
  matchingDataPointCount: number
  nonMatchingDataPointCount: number
  setDataMatchingFilters: Dispatch<SetStateAction<_DataMatchingFilters>>
}

type _MatchingOrNonMatchingDataIconProps = { dataPoint: DataPoint }
type _MatchingOrNonMatchingInfoIconProps = { dataPoint: DataPoint; parentDataPoint: DataPoint; placement?: _Placement }
type _MissingMatchingReferenceProps = { dataPoint: DataPoint }
type _MatchingReferenceIconProps = { placement?: _Placement }

type _Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'
  | undefined

type _ShowFieldsControlProps = { opening: Opening }

// enums

export enum DataMatchingFilters {
  MATCHING_DATA_POINTS = 'matching',
  NON_MATCHING_DATA_POINTS = 'nonMatching'
}

export enum DataPointFieldSources {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
  MANUAL = 'MANUAL',
  MATCHING = 'MATCHING'
}

// functions

export const getDataPointDisplayValue = (
  dataPoint: DataPoint,
  isSpecificGoogleTenantNonCalculationField: boolean,
  parentDataPoint: DataPoint,
  options?: { history?: any; index?: number; isOpen?: boolean; skipTooltip?: boolean }
) => {
  const displayValueBase = (() => {
    switch (dataPoint.data_point_field!.source) {
      case DataPointFieldSources.EXTERNAL:
        return dataPoint.data_point_field?.external_source === CalculationSources.CALCULATION ? (
          (dataPoint.data_point_field?.name as CalculationSources)
        ) : isSpecificGoogleTenantNonCalculationField && dataPoint.data_point_field?.external_source === ExternalSources.SALESFORCE ? (
          'Simba'
        ) : (
          <>
            {dataPoint.data_point_field?.name}

            <Box sx={{ ml: -0.25, mr: 0.25 }}>
              <Source {...dataPoint.data_point_field} />
            </Box>
          </>
        )
      case DataPointFieldSources.MANUAL:
        return 'Manual'
      default:
        return `${dataPoint.data_point_field!.name}${dataPoint.document?.document_type?.name ? ` — ${dataPoint.document.document_type.name}` : ''}`
    }
  })()

  return (
    <Box>
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        {options?.index && `${options.index}. `}

        {displayValueBase}

        {!options?.skipTooltip && <MatchingOrNonMatchingInfoIcon dataPoint={dataPoint} parentDataPoint={parentDataPoint} />}
      </Box>

      {options?.history &&
        ![DataPointFieldSources.EXTERNAL, DataPointFieldSources.MANUAL].includes(dataPoint.data_point_field!.source as DataPointFieldSources) && (
          <NavLink to={`${options.history.location.pathname}?documentTab=${dataPoint.document?.id}`}>
            <Box sx={{ color: grey[700], fontSize: 12, fontWeight: 500, ...(options?.isOpen ? { ':hover': { textDecoration: 'underline' } } : {}) }}>
              {dataPoint.document?.alias || dataPoint.document?.name}
            </Box>
          </NavLink>
        )}
    </Box>
  )
}

// components

export const BypassMatchingControl: FC<_BypassMatchingControlProps> = ({ opening }) => (
  <Button onClick={opening.toggle} sx={{ display: 'flex', mb: -0.5, p: 1, pt: 1.5, width: 'fit-content', '&:hover': { background: 'none' } }}>
    <Typography color="primary" fontSize={14} fontWeight={600}>
      Bypass matching
    </Typography>

    <ExpandMoreIcon color="primary" sx={{ transform: `rotate(${opening.isOpen ? '0' : '-90'}deg)`, transition: 'transform 0.3s' }} />
  </Button>
)

export const BypassMatchingForm: FC<_BypassMatchingFormProps> = ({ dataPoint, opening }) => {
  const { isDataTableMatchingDemoEnabled } = useDocumentPageWrapperContext()
  const formik = useFormik({
    initialValues: { sourceId: '' },

    onSubmit: ({ sourceId }) => resolveMatching({ variables: { matchingDataPointGlobalId: dataPoint.id, matchingResolvedDataPointGlobalId: sourceId } })
  })
  const history = useHistory()
  const isSpecificGoogleTenantNonCalculationField = useIsSpecificGoogleTenantNonCalculationField(dataPoint.data_point_field?.external_source!)
  const [resolveMatching, { loading: isLoading }] = useResolveMatchingMutation({ onCompleted: opening.close })
  const [isOpen, setIsOpen] = useState(false)

  const bypassList = useMemo(
    () =>
      dataPoint.matching_children_data_points!.edges.map((edge, index) => ({
        label: getDataPointDisplayValue(edge!.node!, isSpecificGoogleTenantNonCalculationField, dataPoint, {
          history,
          index: index + 1,
          isOpen,
          skipTooltip: true
        }),
        value: edge!.node!.id
      })),
    [dataPoint, history, isSpecificGoogleTenantNonCalculationField, isOpen]
  )

  if (dataPoint.value_bool) return null

  const { getFieldProps, handleSubmit, values } = formik

  const isDataTableMatchingDemoField = isDataTableMatchingDemoEnabled && DATA_POINT_PRODUCT_TABLE_MATCH_LIST.includes(dataPoint.id)

  return (
    <Collapse in={opening.isOpen}>
      <Box bgcolor="grey.100" borderRadius={2} mt={1} p={2}>
        <Typography fontSize={14} fontWeight={600} mb={2}>
          Select the correct source
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            {...getFieldProps('sourceId')}
            InputLabelProps={{ shrink: true, sx: { fontSize: 14 } }}
            InputProps={{ sx: { bgcolor: 'white', fontSize: 14 } }}
            SelectProps={{
              open: isOpen,
              onOpen: () => setIsOpen(true),
              onClose: () => setIsOpen(false)
            }}
            fullWidth
            label="Source"
            select
            size="small"
          >
            {[{ label: '', value: '' }, ...bypassList].map(({ label, value }) => (
              <MenuItem key={value} sx={{ fontSize: 14, fontWeight: 700, ...(!label && { height: 33 }) }} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>

          <Box display="flex" gap={1} justifyContent="flex-end" mt={2}>
            <Button color="tertiary" disabled={isLoading} onClick={opening.close} size="small" sx={{ bgcolor: 'white' }} variant="outlined">
              Cancel
            </Button>

            <Button
              color="primary"
              disabled={isDataTableMatchingDemoField || isLoading || !values.sourceId}
              size="small"
              sx={{ bgcolor: 'white' }}
              type="submit"
              variant="outlined"
            >
              {isLoading ? (
                <>
                  <>Confirming…</>

                  <ButtonLoading />
                </>
              ) : (
                <>Confirm</>
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Collapse>
  )
}

export const MatchingDataPointsFilters: FC<_MatchingDataPointsFiltersProps> = ({
  dataMatchingFilters,
  expandAll,
  hasMatchingDataPointFields,
  isDisabled,
  matchingDataPointCount,
  nonMatchingDataPointCount,
  setDataMatchingFilters
}) => {
  const handleChange = useCallback(
    (_, filters: DataMatchingFilters[]) => {
      expandAll()

      setDataMatchingFilters(
        Object.keys(dataMatchingFilters).reduce(
          (previous, current) => ({ ...previous, [current]: filters.includes(current as DataMatchingFilters) }),
          {} as _DataMatchingFilters
        )
      )
    },
    [dataMatchingFilters, expandAll, setDataMatchingFilters]
  )

  if (!hasMatchingDataPointFields) return null

  return (
    <ToggleButtonGroup
      disabled={isDisabled}
      onChange={handleChange}
      size="small"
      value={Object.keys(dataMatchingFilters).filter(key => dataMatchingFilters[key as DataMatchingFilters])}
    >
      <ToggleButton aria-label="Matching fields" sx={{ px: 0.75 }} value={DataMatchingFilters.MATCHING_DATA_POINTS}>
        <Tooltip arrow placement="top" title="Matching fields">
          <Box alignItems="center" display="flex" gap={0.5}>
            <CheckCircleOutlineIcon fontSize="small" sx={{ color: '#32a980' }} />

            <>{matchingDataPointCount}</>
          </Box>
        </Tooltip>
      </ToggleButton>

      <ToggleButton aria-label="Non-matching fields" sx={{ px: 0.75 }} value={DataMatchingFilters.NON_MATCHING_DATA_POINTS}>
        <Tooltip arrow placement="top" title="Non-matching fields">
          <Box alignItems="center" display="flex" gap={0.5}>
            <ErrorOutlineIcon
              color={nonMatchingDataPointCount ? 'warning' : undefined}
              fontSize="small"
              sx={{ color: nonMatchingDataPointCount ? undefined : 'grey.500' }}
            />

            <>{nonMatchingDataPointCount}</>
          </Box>
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export const MatchingOrNonMatchingDataIcon: FC<_MatchingOrNonMatchingDataIconProps> = ({ dataPoint }) => (
  <Tooltip arrow placement="top" title={dataPoint.match_result?.reason || `Data ${dataPoint.value_bool ? 'matches' : 'doesn’t match'}`}>
    <Box sx={{ fontSize: 18, lineHeight: 0, mr: 0.5 }}>
      {dataPoint.value_bool ? <CheckCircleOutlineIcon fontSize="inherit" sx={{ color: '#32a980' }} /> : <ErrorOutlineIcon color="warning" fontSize="inherit" />}
    </Box>
  </Tooltip>
)

export const MatchingOrNonMatchingInfoIcon: FC<_MatchingOrNonMatchingInfoIconProps> = ({ dataPoint, parentDataPoint, placement = 'top-start' }) => {
  const isFirstPosition = useMemo(() => dataPoint.id === parentDataPoint.matching_children_data_points?.edges?.[0]?.node?.id, [dataPoint.id, parentDataPoint])

  const isMatchingReference = useMemo(() => {
    const matchingReference = parentDataPoint.data_point_field?.match_config?.match_fields?.edges.find(edge => edge?.node?.is_truth_field)?.node

    return (
      dataPoint.data_point_field?.id === matchingReference?.data_point_field?.id &&
      dataPoint.document?.document_type?.id === matchingReference?.document_type?.id
    )
  }, [dataPoint, parentDataPoint.data_point_field])

  if (!isFirstPosition || !isMatchingReference) {
    if (!parentDataPoint.mismatched_data_points?.find(nonMatchingDataPoint => nonMatchingDataPoint?.id === dataPoint?.id)) return null

    return (
      <Tooltip arrow placement={placement} title="Does not match the matching reference">
        <InfoIcon color="warning" />
      </Tooltip>
    )
  }

  return <MatchingReferenceIcon placement={placement} />
}

const MatchingReferenceIcon: FC<_MatchingReferenceIconProps> = ({ placement = 'top-start' }) => (
  <Tooltip arrow placement={placement} title="The matching reference">
    <InfoIcon color="primary" />
  </Tooltip>
)

export const MissingMatchingReference: FC<_MissingMatchingReferenceProps> = ({ dataPoint }) => {
  const matchingReference = dataPoint.data_point_field?.match_config?.match_fields?.edges.find(edge => edge?.node?.is_truth_field)?.node!

  if (
    dataPoint.matching_children_data_points?.edges.find(
      edge =>
        edge?.node?.data_point_field?.id === matchingReference?.data_point_field?.id &&
        edge?.node?.document?.document_type?.id === matchingReference?.document_type?.id
    )
  )
    return null

  return (
    <>
      <Box className={css.labelRow} mt={1} pb={0.5}>
        <Box className={css.label}>
          <Box component="span" fontSize={14}>
            {matchingReference.document_type?.name}

            <MatchingReferenceIcon />
          </Box>
        </Box>
      </Box>

      <Box
        bgcolor="grey.100"
        border="1px solid rgba(255, 122, 0, 0.3)"
        borderRadius={1}
        color="grey.700"
        display="flex"
        mb={1}
        mr={6.5}
        px={1}
        py={0.8}
        sx={{ userSelect: 'none' }}
      >
        <Typography component="span" fontSize={15}>
          The matching reference wasn’t found.
        </Typography>
      </Box>
    </>
  )
}

export const NonMatchingData: FC = () => (
  <Box border="2px solid rgba(255, 122, 0, 0.2)" borderRadius={2} display="flex" gap={0.75} mb={1} p={1}>
    <ErrorOutlineIcon color="warning" />

    <Typography fontSize={15}>Non-matching values detected.</Typography>
  </Box>
)

export const ShowFieldsControl: FC<_ShowFieldsControlProps> = ({ opening }) => (
  <Button onClick={opening.toggle} sx={{ display: 'flex', mb: -0.5, p: 1, pt: 1.5, width: 'fit-content', '&:hover': { background: 'none' } }}>
    <Typography color="primary" fontSize={14} fontWeight={600}>
      Show fields
    </Typography>

    <ExpandMoreIcon color="primary" sx={{ transform: `rotate(${opening.isOpen ? '0' : '-90'}deg)`, transition: 'transform 0.3s' }} />
  </Button>
)
