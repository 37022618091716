import { Box, Typography } from '@mui/material'
import { CciDealDataPointFieldsDocument, MatchingChildrenDataPointFieldsDocument, useEditDataPointFieldMutation } from '../../../../../graphql/codegen/hooks'
import { CollisionTypeLabels } from '../../RightPanel/RightPanel_components/ChecklistTab/SharedInputs/CollisionTypeInput'
import { DisplayYesOrNo } from '../../Utils'
import { _DocumentType as _ResolutionStrategy } from '../../RightPanel/RightPanel_components/ChecklistTab/SharedInputs/MatchFields'
import { displayExtractionFieldType } from '../../RightPanel/RightPanel_components/ChecklistTab/SharedInputs/ExtractionFieldTypeInput'
import { formatDisplayedExtractionPath } from '../../RightPanel/RightPanel_components/ChecklistTab/Accordions/ExternalExtractionConfigurationAccordion/ExternalExtractionConfigurationAccordion'
import { formatMentions } from '../../../../../utils/commentUtils'
import { getFieldTypeLabel } from '../../../../../utils/cci'
import { isEmpty } from 'lodash'
import { useCciMainContext } from '../../../CCI_Main'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../components/Button'
import React, { FC, useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

// types

type _ReviewEditsProps = {
  closeModal: () => void
  dataPointId?: string
  dataPointName?: string
  selectedItem?: any
  values?: any
}

// components

export const ReviewEdits: FC<_ReviewEditsProps> = ({ closeModal, dataPointId, dataPointName, selectedItem, values }) => {
  const { setActiveComponent, setMessageTitle, setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess, setSelectedItem } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const { t } = useTranslation()

  const [editDataPointFieldMutation, { error: editDPFError, loading: editDPFLoading }] = useEditDataPointFieldMutation({
    onCompleted: response => {
      setActiveComponent(null)
      setMessageTitle(response.edit_data_point_field!.data_point_field!.name)
      setModalSuccess('Field successfully updated.')
      setSelectedItem('')
    },
    refetchQueries: [{ query: CciDealDataPointFieldsDocument }, { query: MatchingChildrenDataPointFieldsDocument }]
  })

  useEffect(() => {
    if (confirmed) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [confirmed])

  useEffect(() => {
    if (editDPFLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Updating Field…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [editDPFLoading])

  useEffect(() => {
    if (editDPFError) {
      setMessageTitle('Error updating field:')
      setModalError(editDPFError)
    }
    // eslint-disable-next-line
    }, [editDPFError])

  const handleSubmit = () => {
    const localValues = { ...values }

    if (localValues.field_semantics) {
      localValues.field_semantics = formatMentions(localValues.field_semantics)
    }

    const options = formatOptions()
    delete localValues.options
    delete localValues.deleted_options

    const booleans = formatBooleanInputs()
    delete localValues.default_field_on_dashboard
    delete localValues.display_accounting_impact
    delete localValues.display_annotations
    delete localValues.display_if_empty
    delete localValues.display_on_checklist

    editDataPointFieldMutation({ variables: { data_point_field_id: dataPointId, options, ...localValues, ...booleans } })
  }

  const formatOptions = () => {
    // remove deleted new options
    const formattedOptions =
      values?.options?.filter((item: any) => {
        if (!item?.currentValue && item?.newValue === '@@_DELETE_THIS_ITEM') {
          return false
        } else {
          return true
        }
      }) || []
    const formattedDeletedOptions =
      values?.deleted_options?.filter((item: any) => {
        if (!item?.currentValue && item?.newValue === '@@_DELETE_THIS_ITEM') {
          return false
        } else {
          return true
        }
      }) || []

    const combinedOptions = [...formattedOptions, ...formattedDeletedOptions]

    return combinedOptions?.length ? JSON.stringify(combinedOptions) : undefined
  }

  const formatBooleanInputs = () => {
    const localObject: any = {}

    if (selectedItem?.default_field_on_dashboard !== values?.default_field_on_dashboard) {
      localObject.default_field_on_dashboard = values?.default_field_on_dashboard
    }

    if (selectedItem?.display_accounting_impact !== values?.display_accounting_impact) {
      localObject.display_accounting_impact = values?.display_accounting_impact
    }

    if (selectedItem?.display_annotations !== values?.display_annotations) {
      localObject.display_annotations = values?.display_annotations
    }

    if (selectedItem?.display_if_empty !== values?.display_if_empty) {
      localObject.display_if_empty = values?.display_if_empty
    }

    if (selectedItem?.display_on_checklist !== values?.display_on_checklist) {
      localObject.display_on_checklist = values?.display_on_checklist
    }

    return localObject
  }

  return (
    <>
      <h5 style={{ textAlign: 'center', marginBottom: '32px' }}>{dataPointName}</h5>

      {values?.name && (
        <div className={css.reviewDiv}>
          <h4>{`Name:`}</h4>
          <p>{values?.name}</p>
        </div>
      )}

      {values?.field_semantics && (
        <div className={css.reviewDiv}>
          <h4>Field Semantics:</h4>

          {/* Handle nested brackets in `react-mentions` */}
          <p>{values?.field_semantics.replace(/@\[(.*?)\]\((.*?)\)/g, '“$1”')}</p>
        </div>
      )}

      {values?.group && (
        <div className={css.reviewDiv}>
          <h4>{`Group:`}</h4>
          <p>{values?.group}</p>
        </div>
      )}

      {values?.source && (
        <div className={css.reviewDiv}>
          <h4>{`Source:`}</h4>
          <p>{values?.source}</p>
        </div>
      )}

      {values?.neutral_tag && (
        <div className={css.reviewDiv}>
          <h4>Automation Tag</h4>
          <p>{values?.neutral_tag}</p>
        </div>
      )}

      {values?.field_type && (
        <div className={css.reviewDiv}>
          <h4>{`Field Type:`}</h4>
          <p>{getFieldTypeLabel(values.field_type)}</p>
        </div>
      )}

      {!isEmpty(values?.extraction_field_type) && (
        <div className={css.reviewDiv}>
          <h4>Extraction Type:</h4>

          <p>{displayExtractionFieldType(values.extraction_field_type)}</p>
        </div>
      )}

      {values?.extraction_logic_path && (
        <div className={css.reviewDiv}>
          <h4>Extraction Path:</h4>

          <p>{formatDisplayedExtractionPath(values.extraction_logic_path)}</p>
        </div>
      )}

      {!isEmpty(values?.options) && (
        <div className={css.reviewDiv}>
          <h4>{`Options:`}</h4>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {values?.options?.map((item: any, index: number) => {
              if (!item?.newValue) {
                return null
              }
              return (
                <div className={css.reviewOptions} key={item?.currentValue + item?.newValue + index}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    {item?.currentValue ? (
                      <>
                        {item?.newValue !== '@@_DELETE_THIS_ITEM' && (
                          <>
                            <p style={{ width: '100%' }}>{`${item?.currentValue} has been renamed to: ${item?.newValue}`}</p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <p style={{ width: '100%' }}>{`New Option: ${item?.newValue}`}</p>
                        {item?.neutralTag && <p style={{ width: '100%' }}>{`Automation Tag: ${item?.neutralTag}`}</p>}
                      </>
                    )}
                  </div>
                </div>
              )
            })}
            {values?.deleted_options?.length > 0 && (
              <>
                <h5 style={{ textAlign: 'left' }}>{`Deleted Options:`}</h5>
                {values?.deleted_options?.map((item: any, index: number) => {
                  return (
                    <p key={item?.option + index} style={{ width: '100%', textAlign: 'right' }}>
                      {item?.currentValue}
                    </p>
                  )
                })}
              </>
            )}
          </div>
        </div>
      )}

      {values?.value_format && values?.value_format !== '@@_DELETE_THIS_ITEM' && (
        <div className={css.reviewDiv}>
          <h4>{`Value Format:`}</h4>
          <p>{values?.value_format}</p>
        </div>
      )}

      {values?.default_value && (
        <div className={css.reviewDiv}>
          <h4>{`Default Value:`}</h4>
          <p>{values?.default_value}</p>
        </div>
      )}

      {values?.collision_type && (
        <div className={css.reviewDiv}>
          <h4>{`Collision Type:`}</h4>

          <p>{CollisionTypeLabels[values?.collision_type as keyof typeof CollisionTypeLabels]}</p>
        </div>
      )}

      {!isEmpty(values?.resolution_strategy) && (
        <div className={css.reviewDiv}>
          <h4>{`Resolution Strategy:`}</h4>

          <Box sx={{ textAlign: 'right' }}>
            {values.resolution_strategy.map((resolutionStrategy: _ResolutionStrategy, index: number) => (
              <Box key={index}>{resolutionStrategy}</Box>
            ))}
          </Box>
        </div>
      )}

      {values?.matching_prompt_description && (
        <div className={css.reviewDiv}>
          <h4>Match Prompt:</h4>

          <Typography sx={{ fontSize: 10 }}>{values.matching_prompt_description}</Typography>
        </div>
      )}

      {values?.description && (
        <div className={css.reviewDiv}>
          <h4>Additional Info:</h4>

          <p>{values?.description}</p>
        </div>
      )}

      {(values.display_on_checklist === true || values.display_on_checklist === false) && (
        <div className={css.reviewDiv}>
          <h4>Always hide this field:</h4>

          <DisplayYesOrNo value={!values.display_on_checklist} />
        </div>
      )}

      {(values?.display_if_empty === true || values?.display_if_empty === false) && (
        <div className={css.reviewDiv}>
          <h4>Hide this field only when it has no value:</h4>

          <DisplayYesOrNo value={!values?.display_if_empty} />
        </div>
      )}

      {(values.display_annotations === true || values.display_annotations === false) && (
        <div className={css.reviewDiv}>
          <h4>Display annotation highlights:</h4>

          <DisplayYesOrNo value={values.display_annotations} />
        </div>
      )}

      {(values?.display_accounting_impact === true || values?.display_accounting_impact === false) && (
        <div className={css.reviewDiv}>
          <h4>Hide accounting impact button:</h4>

          <DisplayYesOrNo value={!values?.display_accounting_impact} />
        </div>
      )}

      {(values?.default_field_on_dashboard === true || values?.default_field_on_dashboard === false) && (
        <div className={css.reviewDiv}>
          <h4>Display as default column in {t('Deals')} and Documents table views:</h4>

          <DisplayYesOrNo value={values?.default_field_on_dashboard} />
        </div>
      )}

      {selectedItem?.option_to_neutral_tag_mapping && values?.deleted_options?.length > 0 && (
        <Typography sx={{ my: 2 }} variant="body2">
          Warning: Annotations may be permanently deleted when deleting options with an automation tag.
        </Typography>
      )}

      {values?.extraction_logic_path && (
        <Typography sx={{ my: 2 }} variant="body2">
          {`Note: Updating the extraction path will initiate Klarity’s value extraction process. Values for this checklist field will be updated across all
          ${t('deals')}
          while the process is running.`}
        </Typography>
      )}

      <TypeToConfirm setConfirmed={setConfirmed} />

      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>

        <WithTooltip content={isDisabled ? 'Type Confirm to proceed' : ''}>
          <Button disabled={isDisabled} onClick={handleSubmit}>
            Submit
          </Button>
        </WithTooltip>
      </div>
    </>
  )
}
