import { Box, Checkbox, ToggleButton, Tooltip, Typography } from '@mui/material'
import { DataPoint } from '../../graphql/codegen/schemas'
import { EditDataPointUserNeedsReviewMutation } from '../../graphql/codegen/operations'
import { FetchResult, MutationResult } from '@apollo/client'
import { Sources } from '../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/CreateInputs/SourceInput'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import React, { FC, useCallback } from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

// types

type _UserReviewFilterProps = {
  expandAll: () => void
  hasUserReviewFields: boolean
  isDisabled: boolean
  setValue: (value: boolean) => void
  userReviewDataPointCount: number
  value: boolean
}

type _UserReviewNotificationProps = {
  dataPoint: DataPoint
  mutation: (options?: any) => Promise<FetchResult>
  mutationState: MutationResult<EditDataPointUserNeedsReviewMutation>
}

// enums

export enum UserReviewTypes {
  VALUE_CHANGE = 'Value change'
}

// components

export const UserReviewFilter: FC<_UserReviewFilterProps> = ({ expandAll, hasUserReviewFields, isDisabled, setValue, userReviewDataPointCount, value }) => {
  const handleChange = useCallback(() => {
    expandAll()

    setValue(!value)
  }, [expandAll, setValue, value])

  if (!hasUserReviewFields) return null

  return (
    <ToggleButton
      aria-label="Fields needing review"
      disabled={isDisabled}
      onChange={handleChange}
      selected={value}
      size="small"
      sx={{ pl: 0.5, pr: 0.75 }}
      value={value}
    >
      <Tooltip arrow placement="top" title="Fields needing review">
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.25 }}>
          <WarningAmberIcon color="warning" sx={{ height: 20 }} />

          <>{userReviewDataPointCount}</>
        </Box>
      </Tooltip>
    </ToggleButton>
  )
}

export const UserReviewNotification: FC<_UserReviewNotificationProps> = ({ dataPoint, mutation, mutationState }) => {
  const { t } = useTranslation()

  const { data_point_field, id, user_needs_review, user_needs_review_type } = dataPoint
  const { loading: isLoading } = mutationState
  const hasSourceFields =
    Boolean(data_point_field?.collision_type) && data_point_field?.source === Sources.INTERNAL && data_point_field?.field_type !== 'DATA_TABLE'
  const isMarkedAsReviewed = !user_needs_review || isLoading
  const userReviewTypeList = user_needs_review_type?.map(
    type => UserReviewTypes[type as keyof typeof UserReviewTypes] + (hasSourceFields ? ' in source fields' : '')
  )

  const markAsReviewed = () => mutation({ variables: { dataPointId: id, userNeedsReview: false } })

  return (
    <Box border="2px solid rgba(255, 122, 0, 0.2)" borderRadius={2} display="flex" gap={0.75} mb={1} p={1}>
      <WarningAmberIcon color="warning" />

      <Typography fontSize={15}>
        Needs review{!isEmpty(userReviewTypeList) && `: ${userReviewTypeList?.join('. ')}.`}{' '}
        {hasSourceFields && (
          <Tooltip
            arrow
            placement="top-start"
            title={`Click “Show source fields” below to review all values extracted from each document in this ${t('deal')}.`}
          >
            <InfoIcon color="warning" sx={{ fontSize: 14 }} />
          </Tooltip>
        )}
      </Typography>

      <Tooltip PopperProps={{ sx: { '.MuiTooltip-tooltip': { visibility: isLoading ? 'hidden' : 'visible' } } }} arrow placement="top" title="Mark as reviewed">
        <Checkbox
          checked={isMarkedAsReviewed}
          disabled={isLoading}
          inputProps={{ 'aria-label': 'Mark as reviewed' }}
          onChange={markAsReviewed}
          size="small"
          sx={{ ml: 'auto', p: 0 }}
        />
      </Tooltip>
    </Box>
  )
}
