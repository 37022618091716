import { Alert, Box, Typography } from '@mui/material'
import { DOCUMENT_BULK_UPLOAD_GET_SIGNED_URL_URL, DOCUMENT_BULK_UPLOAD_URL } from '../../utils/apiUtils'
import { FiXSquare } from 'react-icons/fi'
import { getAuthorization, getIdToken } from '../../utils/sessionApiUtils'
import { isEmpty, isMatch } from 'lodash'
import { useAppContext } from '../../app'
import { useSupportedFileExtensions } from '../../hooks/useSupportedFileExtensions'
import Button from '../Button'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import Dropzone from '../Dropzone'
import React, { FC, useCallback, useEffect, useState } from 'react'
import css from './style.module.scss'

// types

type _BulkUploadV2Props = { closeModal?: any; refetchDashboardData?: () => void; setComponentLoading?: any }
type _CompletedData = { message: string; stats: _Stats; status: string }
type _FailedUploadResponse = { error: string; name: string }
type _FileError = { error_message: string; file_name: string; file_path: string }
type _FileSuccess = { file_name: string; file_path: string }
type _SignedUrlData = { fields: _SignedUrlFields; id: string; url: string }
type _SignedUrlFields = { AWSAccessKeyId: string; key: string; policy: string; signature: string; 'x-amz-security-token': string }
type _Stats = { errors: _FileError[]; success: _FileSuccess[] }

// functions

const formatError = (response: Response) => {
  const { status, statusText, type, url } = response

  return JSON.stringify({ status, statusText, type, url })
}

const uploadFile = (file: File, fields: _SignedUrlFields, url: string): Promise<_FailedUploadResponse | void> => {
  const body = new FormData()

  Object.entries(fields).forEach(([key, value]) => body.append(key, value))

  body.append('file', file)

  return new Promise(resolve =>
    fetch(url, { body, method: 'post' })
      .then(() => resolve())
      .catch(error => resolve({ error, name: file.name }))
  )
}

// components

export const BulkUploadV2: FC<_BulkUploadV2Props> = ({ closeModal, refetchDashboardData, setComponentLoading }) => {
  const { setErrorMessage, setExtendedErrorMessage } = useAppContext()
  const [disabledMessage, setDisabledMessage] = useState('')
  const [files, setFiles] = useState<File[]>([])
  const [isComplete, setIsComplete] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  const { extensions, extensionsMessage } = useSupportedFileExtensions({ appendZip: true, multiple: true })

  const handleClose = useCallback(() => {
    setComponentLoading(false)
    setDisabledMessage('')
    setFiles([])
    setIsComplete(false)
    setIsUploading(false)

    closeModal()
  }, [closeModal, setComponentLoading])

  const handleRemoveFile = useCallback((index: number) => setFiles(current => [...current.slice(0, index), ...current.slice(index + 1)]), [])

  const handleUpload = useCallback(async () => {
    const localFailedUploads: _FailedUploadResponse[] = []

    setComponentLoading(true)
    setIsUploading(true)

    const idToken = await getIdToken()

    try {
      // first API call, to Klarity backend

      const signedUrlResponse = await fetch(DOCUMENT_BULK_UPLOAD_GET_SIGNED_URL_URL, { headers: { Authorization: getAuthorization(idToken) } })

      if (!signedUrlResponse.ok) throw new Error(formatError(signedUrlResponse))

      const signedUrlData: _SignedUrlData = await signedUrlResponse.json()

      const { fields, id, url } = signedUrlData

      // second API call(s), to S3

      await Promise.all(files.map(file => uploadFile(file, fields, url))).then(responses => {
        responses.forEach(response => response && localFailedUploads.push(response))

        setComponentLoading(false)
      })

      // third API call, to Klarity backend

      const body = new FormData()

      body.append('signed_url_id', id)

      const completedResponse = await fetch(DOCUMENT_BULK_UPLOAD_URL, { body, headers: { Authorization: getAuthorization(idToken) }, method: 'post' })

      const completedData: _CompletedData = await completedResponse.json()

      if (!completedResponse.ok) throw new Error(completedData.message)

      refetchDashboardData?.()

      setIsComplete(true)
      setIsUploading(false)
    } catch (error: any) {
      setErrorMessage('Error: bulk upload failed.')

      setExtendedErrorMessage(error.message as string)

      handleClose()
    }
  }, [files, handleClose, refetchDashboardData, setComponentLoading, setErrorMessage, setExtendedErrorMessage])

  useEffect(() => setDisabledMessage(isEmpty(files) ? 'Select at least one file' : ''), [files])

  return (
    <>
      {isUploading && <ComponentLoadingOverlay loading message="Uploading files…" />}

      {isComplete ? (
        <>
          <Alert sx={{ my: 1 }}>Files are uploading and will appear once the upload completes.</Alert>

          <Box className={css.modalButtonRow}>
            <Button onClick={handleClose} style={{ marginRight: 0 }} variant="secondary">
              Close
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography sx={{ fontSize: 15, fontWeight: 'bold', mb: 1 }}>Select files to upload:</Typography>

          <Dropzone
            accept={extensions.join(',')}
            idleContent={
              <>
                <>Drag and drop {extensionsMessage} files here, or </>

                <Box component="span" sx={{ textDecoration: 'underline' }}>
                  browse files
                </Box>
              </>
            }
            idleMessage={`Drag and drop ${extensionsMessage} files here, or browse files`}
            onDrop={(addedFiles: File[]) =>
              setFiles(existingFiles => [
                ...existingFiles,
                ...addedFiles.filter(
                  addedFile =>
                    !existingFiles.some(existingFile =>
                      isMatch(existingFile, { name: addedFile.name, size: addedFile.size, type: addedFile.type, lastModified: addedFile.lastModified })
                    )
                )
              ])
            }
          />

          {!isEmpty(files) && (
            <Box sx={{ maxHeight: '50vh', mb: -1.5, mt: 2, overflowY: 'auto' }}>
              {files.map((file: File, index: number) => (
                <Box className={css.documentRow} key={index}>
                  <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>{file.name}</Box>

                  <Button aria-label={`Remove ${file.name}`} icon={<FiXSquare />} onClick={() => handleRemoveFile(index)} />
                </Box>
              ))}
            </Box>
          )}

          <Box className={css.modalButtonRow}>
            <Button onClick={handleClose} variant="secondary">
              Cancel
            </Button>

            <Button disabled={Boolean(disabledMessage)} onClick={handleUpload} title={disabledMessage}>
              Upload
            </Button>
          </Box>
        </>
      )}
    </>
  )
}
