import { DataPointFieldMentions } from '../../../../../../../components/DataPointFieldMentions'
import { FiXSquare } from 'react-icons/fi'
import { Typography } from '@mui/material'
import { _ExtractionMethod } from './ExtractionMethodInput'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import { useCciMainContext } from '../../../../../CCI_Main'
import Button from '../../../../../../../components/Button'
import React, { FC } from 'react'
import css from './style.module.scss'

// constants

const FIELD = 'extraction_description'

const LABEL = 'AI Prompt'

// components

export const ExtractionDescriptionInput: FC = () => {
  const { fieldValues, updateFieldValue } = useCciChecklistGptContext()
  const { selectedItem } = useCciMainContext()

  const selectedExtractionMethod = JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}') as _ExtractionMethod

  const { description, extraction_method_instance_id: extractionMethodInstanceId } = selectedExtractionMethod

  const value = fieldValues?.extraction_description || description

  if (!extractionMethodInstanceId) return null

  return (
    <div className={css.inputWrapper}>
      <Typography component="label" htmlFor="extraction-description-input" sx={{ fontWeight: 600, minWidth: 164, mr: 2 }} variant="body2">
        {LABEL}
      </Typography>

      <DataPointFieldMentions
        id="extraction-description-input"
        label={LABEL}
        onChange={(event: { target: { value: string } }) => {
          updateFieldValue(FIELD, event.target.value)
          updateFieldValue('extraction_method_instance_id', extractionMethodInstanceId)
        }}
        value={value}
      />

      {value && (
        <Button
          className={css.cciButton}
          icon={<FiXSquare />}
          onClick={() => {
            updateFieldValue(FIELD, null)
            updateFieldValue('extraction_method_instance_id', null)
          }}
        />
      )}
    </div>
  )
}
