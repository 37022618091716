export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
};

/** GraphQL type for ActionType. */
export type ActionType = Node & {
  __typename?: "ActionType";
  default_extraction_method?: Maybe<ExtractionMethod>;
  description?: Maybe<Scalars["String"]>;
  extraction_methods?: Maybe<ExtractionMethodConnection>;
  /** The ID of the object. */
  id: Scalars["ID"];
  name: Scalars["String"];
  source: Scalars["String"];
  valid_field_types: Array<Scalars["String"]>;
  extraction_field_type: Scalars["String"];
};

/** GraphQL type for ActionType. */
export type ActionTypeExtraction_MethodsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  allowed_for_every_customer?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_freetext?: InputMaybe<Scalars["Boolean"]>;
  is_tabular?: InputMaybe<Scalars["Boolean"]>;
  version?: InputMaybe<Scalars["String"]>;
};

export type AdvancedDashboard = {
  __typename?: "AdvancedDashboard";
  dashboard_data?: Maybe<Array<Maybe<AdvancedDashboardData>>>;
  total_number_of_documents?: Maybe<Scalars["Int"]>;
  total_number_of_filtered_documents?: Maybe<Scalars["Int"]>;
  spreadsheet_export?: Maybe<AdvancedDashboardFileExport>;
  export_status?: Maybe<Scalars["String"]>;
};

export type AdvancedDashboardCounterpartyMeta = {
  __typename?: "AdvancedDashboardCounterpartyMeta";
  internal_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["JSONString"]>;
};

export type AdvancedDashboardData = Node & {
  __typename?: "AdvancedDashboardData";
  id: Scalars["ID"];
  document?: Maybe<AdvancedDashboardDocumentMeta>;
  document_id?: Maybe<AdvancedDashboardDocumentIdMeta>;
  counter_party?: Maybe<AdvancedDashboardCounterpartyMeta>;
  non_standard_terms_count?: Maybe<Scalars["Int"]>;
  revenue_impact_count?: Maybe<Scalars["Int"]>;
  comments_count?: Maybe<Scalars["Int"]>;
  unresolved_comments_count?: Maybe<AdvancedDashboardUnresolvedCommentsCountMeta>;
  group_counts?: Maybe<Scalars["JSONString"]>;
  assignee?: Maybe<AdvancedDashboardUserMeta>;
  all_assignees?: Maybe<Array<Maybe<AdvancedDashboardUserMeta>>>;
  status?: Maybe<AdvancedDashboardStateMeta>;
  data_points?: Maybe<Array<Maybe<AdvancedDashboardDataPoint>>>;
  processed?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<AdvancedDashboardDateMeta>;
  internal_status?: Maybe<AdvancedDashboardInternalStatusMeta>;
  extraction_status?: Maybe<AdvancedDashboardInternalStatusMeta>;
  discard_reason?: Maybe<Scalars["String"]>;
  integration_type?: Maybe<AdvancedDashboardIntegrationTypeMeta>;
  documents_count?: Maybe<AdvancedDashboardDocumentsCountMeta>;
  attachments_count?: Maybe<AdvancedDashboardDocumentsCountMeta>;
  is_legacy?: Maybe<AdvancedDashboardIsLegacyMeta>;
  errors?: Maybe<AdvancedDashboardFieldErrorsMeta>;
  turn_around_time_hours?: Maybe<Scalars["Float"]>;
  turn_around_time_minutes?: Maybe<Scalars["Float"]>;
  internal_state?: Maybe<AdvancedDashboardStateMeta>;
  internal_assignee?: Maybe<AdvancedDashboardUserMeta>;
  is_deal_level?: Maybe<Scalars["Boolean"]>;
  is_counter_party_level?: Maybe<Scalars["Boolean"]>;
  created_by?: Maybe<AdvancedDashboardUserMeta>;
};

export type AdvancedDashboardDataPoint = {
  __typename?: "AdvancedDashboardDataPoint";
  data_point_id?: Maybe<Scalars["String"]>;
  data_point_field_id?: Maybe<Scalars["String"]>;
  internal_name?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  sortable_value?: Maybe<Scalars["String"]>;
  group?: Maybe<Scalars["String"]>;
  data_type?: Maybe<Scalars["String"]>;
  filter_allowed?: Maybe<Scalars["Boolean"]>;
  is_collision_resolved?: Maybe<Scalars["Boolean"]>;
  hyperlink_url?: Maybe<Scalars["String"]>;
};

export type AdvancedDashboardDateMeta = {
  __typename?: "AdvancedDashboardDateMeta";
  internal_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type AdvancedDashboardDocumentIdMeta = {
  __typename?: "AdvancedDashboardDocumentIDMeta";
  internal_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type AdvancedDashboardDocumentMeta = {
  __typename?: "AdvancedDashboardDocumentMeta";
  id?: Maybe<Scalars["String"]>;
  internal_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  value_alias?: Maybe<Scalars["String"]>;
  value_original?: Maybe<Scalars["String"]>;
};

export type AdvancedDashboardDocumentsCountMeta = {
  __typename?: "AdvancedDashboardDocumentsCountMeta";
  internal_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Int"]>;
};

export type AdvancedDashboardField = Node & {
  __typename?: "AdvancedDashboardField";
  /** The ID of the object. */
  id: Scalars["ID"];
  document?: Maybe<AdvancedDashboardFieldDocumentMeta>;
  document_id?: Maybe<AdvancedDashboardFieldDocumentMeta>;
  counter_party?: Maybe<AdvancedDashboardFieldCounterpartyMeta>;
  assignee?: Maybe<AdvancedDashboardFieldUserMeta>;
  all_assignees?: Maybe<AdvancedDashboardFieldUserMeta>;
  status?: Maybe<AdvancedDashboardFieldStateMeta>;
  non_standard_terms_count?: Maybe<Scalars["Int"]>;
  revenue_impact_count?: Maybe<Scalars["Int"]>;
  comments_count?: Maybe<Scalars["Int"]>;
  group_counts?: Maybe<Scalars["JSONString"]>;
  data_points?: Maybe<Array<Maybe<AdvancedDashboardFieldDataPoint>>>;
  multi_select_data_points?: Maybe<
    Array<Maybe<AdvancedDashboardFieldDataPointMultiSelect>>
  >;
  processed?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<AdvancedDashboardFieldDateMeta>;
  internal_status?: Maybe<AdvancedDashboardFieldInternalStatusMeta>;
  documents_count?: Maybe<AdvancedDashboardFieldDocumentsCountMeta>;
  attachments_count?: Maybe<AdvancedDashboardFieldDocumentsCountMeta>;
  unresolved_comments_count?: Maybe<AdvancedDashboardFieldUnresolvedCommentsCountMeta>;
  is_legacy?: Maybe<AdvancedDashboardFieldIsLegacyMeta>;
  integration_type?: Maybe<AdvancedDashboardFieldIntegrationTypeMeta>;
  errors?: Maybe<AdvancedDashboardFieldErrorsMeta>;
  internal_state?: Maybe<AdvancedDashboardFieldInternalStateMeta>;
  internal_assignee?: Maybe<AdvancedDashboardFieldUserMeta>;
  extraction_status?: Maybe<AdvancedDashboardFieldInternalStatusMeta>;
};

export type AdvancedDashboardFieldCounterpartyMeta = {
  __typename?: "AdvancedDashboardFieldCounterpartyMeta";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<CounterParty>>>;
  options?: Maybe<Array<Maybe<CounterParty>>>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFieldDataPoint = {
  __typename?: "AdvancedDashboardFieldDataPoint";
  data_point_field_id?: Maybe<Scalars["String"]>;
  internal_name?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  sortable_value?: Maybe<Scalars["String"]>;
  group?: Maybe<Scalars["String"]>;
  data_type?: Maybe<Scalars["String"]>;
  field_type?: Maybe<Scalars["String"]>;
  filter_type?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  dropdown_options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
  filter_allowed?: Maybe<Scalars["Boolean"]>;
  default_table_column?: Maybe<Scalars["Boolean"]>;
};

export type AdvancedDashboardFieldDataPointMultiSelect = {
  __typename?: "AdvancedDashboardFieldDataPointMultiSelect";
  data_point_field_id?: Maybe<Scalars["String"]>;
  internal_name?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  data_type?: Maybe<Scalars["String"]>;
  filter_type?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
  options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  dropdown_options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sortable_value?: Maybe<Scalars["String"]>;
  group?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
  filter_allowed?: Maybe<Scalars["Boolean"]>;
  default_table_column?: Maybe<Scalars["Boolean"]>;
};

export type AdvancedDashboardFieldDateMeta = {
  __typename?: "AdvancedDashboardFieldDateMeta";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFieldDocumentMeta = {
  __typename?: "AdvancedDashboardFieldDocumentMeta";
  id?: Maybe<Scalars["String"]>;
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFieldDocumentsCountMeta = {
  __typename?: "AdvancedDashboardFieldDocumentsCountMeta";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFieldErrorsMeta = {
  __typename?: "AdvancedDashboardFieldErrorsMeta";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<ErrorCodeMeta>>>;
  options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFieldIntegrationTypeMeta = {
  __typename?: "AdvancedDashboardFieldIntegrationTypeMeta";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFieldInternalStateMeta = {
  __typename?: "AdvancedDashboardFieldInternalStateMeta";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<InternalState>>>;
  options?: Maybe<Array<Maybe<InternalState>>>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFieldInternalStatusMeta = {
  __typename?: "AdvancedDashboardFieldInternalStatusMeta";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
  options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFieldIsLegacyMeta = {
  __typename?: "AdvancedDashboardFieldIsLegacyMeta";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFieldStateMeta = {
  __typename?: "AdvancedDashboardFieldStateMeta";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<State>>>;
  options?: Maybe<Array<Maybe<State>>>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFieldUnresolvedCommentsCountMeta = {
  __typename?: "AdvancedDashboardFieldUnresolvedCommentsCountMeta";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFieldUserMeta = {
  __typename?: "AdvancedDashboardFieldUserMeta";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<User>>>;
  options?: Maybe<Array<Maybe<User>>>;
  filter_type?: Maybe<Scalars["String"]>;
  operators?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type AdvancedDashboardFileExport = {
  __typename?: "AdvancedDashboardFileExport";
  file_name?: Maybe<Scalars["String"]>;
  file_contents?: Maybe<Scalars["String"]>;
};

export type AdvancedDashboardIntegrationTypeMeta = {
  __typename?: "AdvancedDashboardIntegrationTypeMeta";
  internal_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type AdvancedDashboardInternalStatusMeta = {
  __typename?: "AdvancedDashboardInternalStatusMeta";
  internal_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type AdvancedDashboardIsLegacyMeta = {
  __typename?: "AdvancedDashboardIsLegacyMeta";
  internal_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type AdvancedDashboardStateMeta = {
  __typename?: "AdvancedDashboardStateMeta";
  id?: Maybe<Scalars["String"]>;
  internal_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  final?: Maybe<Scalars["Boolean"]>;
};

export type AdvancedDashboardUnresolvedCommentsCountMeta = {
  __typename?: "AdvancedDashboardUnresolvedCommentsCountMeta";
  internal_name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Int"]>;
};

export type AdvancedDashboardUserMeta = {
  __typename?: "AdvancedDashboardUserMeta";
  internal_name?: Maybe<Scalars["String"]>;
  value?: Maybe<User>;
};

export type AffectedDealsItem = {
  __typename?: "AffectedDealsItem";
  active_deals?: Maybe<Scalars["Int"]>;
  total_deals?: Maybe<Scalars["Int"]>;
  completed_deals?: Maybe<Scalars["Int"]>;
  deal_details?: Maybe<Array<Maybe<Deal>>>;
};

export type AnalyticsField = {
  __typename?: "AnalyticsField";
  field_name: Scalars["String"];
  id: Scalars["String"];
};

export type Annotation = Node & {
  __typename?: "Annotation";
  core_id?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  /** The ID of the object. */
  id: Scalars["ID"];
  internal_name: Scalars["String"];
  is_deleted: Scalars["Boolean"];
  label_name: Scalars["String"];
  label_type?: Maybe<Scalars["String"]>;
  last_updated_at?: Maybe<Scalars["DateTime"]>;
  last_updated_by?: Maybe<User>;
  positions?: Maybe<AnnotationPositionConnection>;
  review_state?: Maybe<Scalars["String"]>;
};

export type AnnotationPositionsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  page_height?: InputMaybe<Scalars["Float"]>;
  page_number?: InputMaybe<Scalars["Int"]>;
  page_width?: InputMaybe<Scalars["Float"]>;
};

export type AnnotationConnection = {
  __typename?: "AnnotationConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AnnotationEdge>>;
};

/** A Relay edge containing a `Annotation` and its cursor. */
export type AnnotationEdge = {
  __typename?: "AnnotationEdge";
  /** The item at the end of the edge */
  node?: Maybe<Annotation>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type AnnotationPosition = Node & {
  __typename?: "AnnotationPosition";
  bounding_rect?: Maybe<AnnotationPositionBbox>;
  page_height: Scalars["Float"];
  page_number: Scalars["Int"];
  page_width: Scalars["Float"];
  rects?: Maybe<AnnotationPositionBboxConnection>;
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type AnnotationPositionRectsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  x1?: InputMaybe<Scalars["Float"]>;
  x2?: InputMaybe<Scalars["Float"]>;
  y1?: InputMaybe<Scalars["Float"]>;
  y2?: InputMaybe<Scalars["Float"]>;
};

export type AnnotationPositionBbox = Node & {
  __typename?: "AnnotationPositionBBOX";
  x1: Scalars["Float"];
  x2: Scalars["Float"];
  y1: Scalars["Float"];
  y2: Scalars["Float"];
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type AnnotationPositionBboxConnection = {
  __typename?: "AnnotationPositionBBOXConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AnnotationPositionBboxEdge>>;
};

/** A Relay edge containing a `AnnotationPositionBBOX` and its cursor. */
export type AnnotationPositionBboxEdge = {
  __typename?: "AnnotationPositionBBOXEdge";
  /** The item at the end of the edge */
  node?: Maybe<AnnotationPositionBbox>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type AnnotationPositionBboxInput = {
  x1: Scalars["Float"];
  y1: Scalars["Float"];
  x2: Scalars["Float"];
  y2: Scalars["Float"];
  text?: InputMaybe<Scalars["String"]>;
};

export type AnnotationPositionConnection = {
  __typename?: "AnnotationPositionConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AnnotationPositionEdge>>;
};

/** A Relay edge containing a `AnnotationPosition` and its cursor. */
export type AnnotationPositionEdge = {
  __typename?: "AnnotationPositionEdge";
  /** The item at the end of the edge */
  node?: Maybe<AnnotationPosition>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type AnnotationPositionInput = {
  page_number: Scalars["Int"];
  page_width: Scalars["Float"];
  page_height: Scalars["Float"];
  bounding_rect: AnnotationPositionBboxInput;
  rects: Array<InputMaybe<AnnotationPositionBboxInput>>;
  text: Scalars["String"];
};

export type AnnotationTodo = Node & {
  __typename?: "AnnotationTODO";
  created_at?: Maybe<Scalars["DateTime"]>;
  customer?: Maybe<Customer>;
  /** The ID of the object. */
  id: Scalars["ID"];
  last_updated_at?: Maybe<Scalars["DateTime"]>;
  todolist?: Maybe<AnnotationTodoItemConnection>;
};

export type AnnotationTodoTodolistArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  item_type?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
};

export type AnnotationTodoConnection = {
  __typename?: "AnnotationTODOConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AnnotationTodoEdge>>;
};

/** A Relay edge containing a `AnnotationTODO` and its cursor. */
export type AnnotationTodoEdge = {
  __typename?: "AnnotationTODOEdge";
  /** The item at the end of the edge */
  node?: Maybe<AnnotationTodo>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type AnnotationTodoItem = Node & {
  __typename?: "AnnotationTODOItem";
  item_type?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type AnnotationTodoItemConnection = {
  __typename?: "AnnotationTODOItemConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AnnotationTodoItemEdge>>;
};

/** A Relay edge containing a `AnnotationTODOItem` and its cursor. */
export type AnnotationTodoItemEdge = {
  __typename?: "AnnotationTODOItemEdge";
  /** The item at the end of the edge */
  node?: Maybe<AnnotationTodoItem>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type AnnotationTag = {
  __typename?: "AnnotationTag";
  internal_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
};

export type Attachment = Node & {
  __typename?: "Attachment";
  alias?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  document_id?: Maybe<Scalars["String"]>;
  has_pdf_preview?: Maybe<Scalars["Boolean"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_deleted: Scalars["Boolean"];
  name: Scalars["String"];
};

export type AttachmentConnection = {
  __typename?: "AttachmentConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AttachmentEdge>>;
};

/** A Relay edge containing a `Attachment` and its cursor. */
export type AttachmentEdge = {
  __typename?: "AttachmentEdge";
  /** The item at the end of the edge */
  node?: Maybe<Attachment>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type BulkEditDealAssignee = {
  __typename?: "BulkEditDealAssignee";
  advanced_dashboard?: Maybe<AdvancedDashboard>;
  deals?: Maybe<Array<Maybe<Deal>>>;
};

export type BulkEditDealState = {
  __typename?: "BulkEditDealState";
  advanced_dashboard?: Maybe<AdvancedDashboard>;
  deals?: Maybe<Array<Maybe<Deal>>>;
};

export type BulkEditDocumentAssignee = {
  __typename?: "BulkEditDocumentAssignee";
  advanced_dashboard?: Maybe<AdvancedDashboard>;
  documents?: Maybe<Array<Maybe<Document>>>;
};

export type BulkEditDocumentState = {
  __typename?: "BulkEditDocumentState";
  advanced_dashboard?: Maybe<AdvancedDashboard>;
  documents?: Maybe<Array<Maybe<Document>>>;
};

export type BulkEditDocumentType = {
  __typename?: "BulkEditDocumentType";
  document_types?: Maybe<Array<Maybe<DocumentType>>>;
  data_point_fields?: Maybe<Array<Maybe<DataPointField>>>;
};

export type BulkEditInternalAssignee = {
  __typename?: "BulkEditInternalAssignee";
  advanced_dashboard?: Maybe<AdvancedDashboard>;
  documents?: Maybe<Array<Maybe<Document>>>;
};

export enum BusinessWorkflow {
  OrderManagement = "ORDER_MANAGEMENT",
  InvoiceProcessing = "INVOICE_PROCESSING",
  RevenueRecognition = "REVENUE_RECOGNITION",
  LeaseAccounting = "LEASE_ACCOUNTING",
  PurchaseCommitmentDisclosure = "PURCHASE_COMMITMENT_DISCLOSURE",
  VendorOrderReview = "VENDOR_ORDER_REVIEW",
  Other = "OTHER",
}

export type ChangeDealAttachmentAsDocument = {
  __typename?: "ChangeDealAttachmentAsDocument";
  deal?: Maybe<Deal>;
};

export type ChangeDocumentClassificationLevel = {
  __typename?: "ChangeDocumentClassificationLevel";
  deals?: Maybe<Array<Maybe<Deal>>>;
  document?: Maybe<Document>;
};

export type ChangeLog = Node & {
  __typename?: "ChangeLog";
  action_on?: Maybe<Scalars["String"]>;
  action_type: Scalars["String"];
  created_at?: Maybe<Scalars["DateTime"]>;
  document_name?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  new_value?: Maybe<Scalars["String"]>;
  old_value?: Maybe<Scalars["String"]>;
  user_name?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  event?: Maybe<Scalars["String"]>;
};

export type ChangeNeutralTag = {
  __typename?: "ChangeNeutralTag";
  data_point_fields?: Maybe<Array<Maybe<DataPointField>>>;
};

export type ChecklistQuery = {
  __typename?: "ChecklistQuery";
  query_id: Scalars["ID"];
  query_name: Scalars["String"];
};

export type ChecklistQueryResult = {
  __typename?: "ChecklistQueryResult";
  data_point_ids: Array<Scalars["ID"]>;
};

export enum ClassificationLevel {
  Deal = "DEAL",
  CounterParty = "COUNTER_PARTY",
}

export type Comment = Node & {
  __typename?: "Comment";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  data_point?: Maybe<DataPoint>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_deleted: Scalars["Boolean"];
  is_pinned?: Maybe<Scalars["Boolean"]>;
  last_updated_at?: Maybe<Scalars["DateTime"]>;
  last_updated_by?: Maybe<User>;
  pinned_at?: Maybe<Scalars["DateTime"]>;
  pinned_by?: Maybe<User>;
  text?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type CounterParty = Node & {
  __typename?: "CounterParty";
  customer?: Maybe<Customer>;
  /** The ID of the object. */
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type CounterPartyConnection = {
  __typename?: "CounterPartyConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CounterPartyEdge>>;
};

/** A Relay edge containing a `CounterParty` and its cursor. */
export type CounterPartyEdge = {
  __typename?: "CounterPartyEdge";
  /** The item at the end of the edge */
  node?: Maybe<CounterParty>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type CreateAdditionalReview = {
  __typename?: "CreateAdditionalReview";
  document?: Maybe<Document>;
};

export type CreateAnnotationPayload =
  | CreateAnnotationSuccess
  | CreateAnnotationRetry;

export type CreateAnnotationRetry = {
  __typename?: "CreateAnnotationRetry";
  annotated_text?: Maybe<Scalars["String"]>;
  data_points?: Maybe<Array<Maybe<DataPoint>>>;
};

export type CreateAnnotationSuccess = {
  __typename?: "CreateAnnotationSuccess";
  document?: Maybe<Document>;
  data_points?: Maybe<Array<Maybe<DataPoint>>>;
};

export type CreateComment = {
  __typename?: "CreateComment";
  comment?: Maybe<Comment>;
};

export type CreateCounterParty = {
  __typename?: "CreateCounterParty";
  counter_party?: Maybe<CounterParty>;
};

export type CreateDashboardQuery = {
  __typename?: "CreateDashboardQuery";
  dashboard_query?: Maybe<DashboardQuery>;
};

/**
 * Creates deal/document data point field. Document DPF will be created for INTERNAL fields.
 * Data point fields are validate
 * return: The deal data point field created
 */
export type CreateDataPointField = {
  __typename?: "CreateDataPointField";
  data_point_field?: Maybe<DataPointField>;
};

export type CreateDeal = {
  __typename?: "CreateDeal";
  deal?: Maybe<Deal>;
};

export type CreateDealNote = {
  __typename?: "CreateDealNote";
  deal?: Maybe<Deal>;
};

export type CreateDocumentNote = {
  __typename?: "CreateDocumentNote";
  document?: Maybe<Document>;
};

export type CreateFieldExtractionMethodInstance = {
  __typename?: "CreateFieldExtractionMethodInstance";
  extraction_method_instance?: Maybe<ExtractionMethodInstance>;
  data_point_field?: Maybe<DataPointField>;
};

export type CreateIntegration = {
  __typename?: "CreateIntegration";
  integration?: Maybe<Integration>;
};

export type CreateRole = {
  __typename?: "CreateRole";
  role?: Maybe<Role>;
};

export type CreateState = {
  __typename?: "CreateState";
  state?: Maybe<State>;
};

export type CreateUser = {
  __typename?: "CreateUser";
  user?: Maybe<User>;
};

export type CreateWorkflow = {
  __typename?: "CreateWorkflow";
  workflow?: Maybe<Workflow>;
  states?: Maybe<Array<Maybe<State>>>;
};

export type CreateWorkspace = {
  __typename?: "CreateWorkspace";
  workspace?: Maybe<Customer>;
};

export type CustomState = {
  __typename?: "CustomState";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  initial?: Maybe<Scalars["Boolean"]>;
  final?: Maybe<Scalars["Boolean"]>;
  priority?: Maybe<Scalars["Int"]>;
};

export type Customer = Node & {
  __typename?: "Customer";
  alternate_emails?: Maybe<Array<Maybe<Scalars["String"]>>>;
  counter_party_level_classification_config?: Maybe<Scalars["JSONString"]>;
  customer_email?: Maybe<Scalars["String"]>;
  deal_level_classification_config?: Maybe<Scalars["JSONString"]>;
  document_deduplication_config?: Maybe<Scalars["JSONString"]>;
  email_domain_allow_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  email_domain_for_idp?: Maybe<Scalars["String"]>;
  expiry_date?: Maybe<Scalars["DateTime"]>;
  external_context_logic?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  idp_id?: Maybe<Scalars["String"]>;
  limits?: Maybe<Scalars["String"]>;
  manual_upload_config?: Maybe<Scalars["JSONString"]>;
  name: Scalars["String"];
  trial?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<Scalars["String"]>;
  /** Customer-specific metadata */
  metadata?: Maybe<WorkspaceMetadata>;
  /** Customer-specific configuration */
  config?: Maybe<CustomerConfig>;
};

export type CustomerConfig = Node & {
  __typename?: "CustomerConfig";
  /** The ID of the object. */
  id: Scalars["ID"];
  failure_notifications_enabled?: Maybe<Scalars["Boolean"]>;
};

export type CustomerConnection = {
  __typename?: "CustomerConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerEdge>>;
};

/** A Relay edge containing a `Customer` and its cursor. */
export type CustomerEdge = {
  __typename?: "CustomerEdge";
  /** The item at the end of the edge */
  node?: Maybe<Customer>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type DashboardConfiguration = Node & {
  __typename?: "DashboardConfiguration";
  customer?: Maybe<Customer>;
  deal_default_fields?: Maybe<DealDashboardDefaultFieldConnection>;
  document_default_fields?: Maybe<DocumentDashboardDefaultFieldConnection>;
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type DashboardConfigurationDeal_Default_FieldsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
  is_visible?: InputMaybe<Scalars["Boolean"]>;
};

export type DashboardConfigurationDocument_Default_FieldsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
  is_visible?: InputMaybe<Scalars["Boolean"]>;
};

export type DashboardQuery = Node & {
  __typename?: "DashboardQuery";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  customer?: Maybe<Customer>;
  dashboard?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_shared: Scalars["Boolean"];
  name: Scalars["String"];
  query_string: Scalars["String"];
};

export type DashboardQueryConnection = {
  __typename?: "DashboardQueryConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DashboardQueryEdge>>;
};

/** A Relay edge containing a `DashboardQuery` and its cursor. */
export type DashboardQueryEdge = {
  __typename?: "DashboardQueryEdge";
  /** The item at the end of the edge */
  node?: Maybe<DashboardQuery>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type DataPoint = Node & {
  __typename?: "DataPoint";
  annotator_needs_review?: Maybe<Scalars["Boolean"]>;
  annotator_needs_review_type?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /**
   * FILTERS, SORT FIELD TYPE
   * # TEXT - value_str (can also represent an address)
   * # BOOLEAN - value_bool
   * # DROP_DOWN - value_str
   * # MULTI_SELECT_DROP_DOWN - value_list
   * # DATE - value_iso_date
   * # DURATION - value_float (# TODO: value_duration)
   * # PERCENTAGE - value_float
   * # CURRENCY - value_float (# TODO: value_currency)
   * # CURRENCY_TYPE - value_str
   * # GEO_COUNTRY, GEO_STATE, GEO_CITY - value_str
   */
  data_point_field?: Maybe<DataPointField>;
  deal?: Maybe<Deal>;
  display_value?: Maybe<Scalars["String"]>;
  document?: Maybe<Document>;
  has_accounting_impact?: Maybe<Scalars["Boolean"]>;
  hyperlink?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  hyperlink_url?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_collision_resolved?: Maybe<Scalars["Boolean"]>;
  is_reviewed: Scalars["Boolean"];
  is_reviewed_1: Scalars["Boolean"];
  is_reviewed_2: Scalars["Boolean"];
  is_reviewed_3: Scalars["Boolean"];
  is_reviewed_4: Scalars["Boolean"];
  is_reviewed_5: Scalars["Boolean"];
  last_updated_at?: Maybe<Scalars["DateTime"]>;
  last_updated_by?: Maybe<User>;
  /** Models the result of a matching verification  */
  match_result?: Maybe<MatchResult>;
  matching_resolved_data_point?: Maybe<DataPoint>;
  parent_data_point?: Maybe<DataPoint>;
  pin?: Maybe<Scalars["Boolean"]>;
  user_needs_review?: Maybe<Scalars["Boolean"]>;
  user_needs_review_type?: Maybe<Array<Maybe<Scalars["String"]>>>;
  value_bool?: Maybe<Scalars["Boolean"]>;
  value_data_table?: Maybe<DataPointDataTable>;
  value_float?: Maybe<Scalars["Float"]>;
  value_iso_date?: Maybe<Scalars["DateTime"]>;
  value_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  value_str?: Maybe<Scalars["String"]>;
  document_data_points?: Maybe<DataPointConnection>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  change_log?: Maybe<Array<Maybe<ChangeLog>>>;
  annotations?: Maybe<Array<Maybe<DataPointAnnotations>>>;
  mismatch_count?: Maybe<Scalars["Int"]>;
  matching_children_data_points?: Maybe<DataPointConnection>;
  mismatched_data_points?: Maybe<Array<Maybe<DataPoint>>>;
  has_nested_children_data_points?: Maybe<Scalars["Boolean"]>;
  formula_input_data_points?: Maybe<Array<Maybe<DataPoint>>>;
  resolved_data_points?: Maybe<DataPointConnection>;
  errors?: Maybe<SystemFailureConnection>;
};

export type DataPointDocument_Data_PointsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  annotator_needs_review?: InputMaybe<Scalars["Boolean"]>;
  annotator_needs_review_type?: InputMaybe<Scalars["String"]>;
  display_value?: InputMaybe<Scalars["String"]>;
  has_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  hyperlink?: InputMaybe<Scalars["Int"]>;
  hyperlink_url?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_collision_resolved?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_1?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_2?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_3?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_4?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_5?: InputMaybe<Scalars["Boolean"]>;
  last_updated_at?: InputMaybe<Scalars["DateTime"]>;
  pin?: InputMaybe<Scalars["Boolean"]>;
  user_needs_review?: InputMaybe<Scalars["Boolean"]>;
  user_needs_review_type?: InputMaybe<Scalars["String"]>;
  value_bool?: InputMaybe<Scalars["Boolean"]>;
  value_float?: InputMaybe<Scalars["Float"]>;
  value_iso_date?: InputMaybe<Scalars["DateTime"]>;
  value_list?: InputMaybe<Scalars["String"]>;
  value_str?: InputMaybe<Scalars["String"]>;
  data_point_field?: InputMaybe<Scalars["ID"]>;
  deal?: InputMaybe<Scalars["ID"]>;
  document?: InputMaybe<Scalars["ID"]>;
  last_updated_by?: InputMaybe<Scalars["ID"]>;
  matching_resolved_data_point?: InputMaybe<Scalars["ID"]>;
  parent_data_point?: InputMaybe<Scalars["ID"]>;
};

export type DataPointMatching_Children_Data_PointsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  annotator_needs_review?: InputMaybe<Scalars["Boolean"]>;
  annotator_needs_review_type?: InputMaybe<Scalars["String"]>;
  display_value?: InputMaybe<Scalars["String"]>;
  has_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  hyperlink?: InputMaybe<Scalars["Int"]>;
  hyperlink_url?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_collision_resolved?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_1?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_2?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_3?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_4?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_5?: InputMaybe<Scalars["Boolean"]>;
  last_updated_at?: InputMaybe<Scalars["DateTime"]>;
  pin?: InputMaybe<Scalars["Boolean"]>;
  user_needs_review?: InputMaybe<Scalars["Boolean"]>;
  user_needs_review_type?: InputMaybe<Scalars["String"]>;
  value_bool?: InputMaybe<Scalars["Boolean"]>;
  value_float?: InputMaybe<Scalars["Float"]>;
  value_iso_date?: InputMaybe<Scalars["DateTime"]>;
  value_list?: InputMaybe<Scalars["String"]>;
  value_str?: InputMaybe<Scalars["String"]>;
  data_point_field?: InputMaybe<Scalars["ID"]>;
  deal?: InputMaybe<Scalars["ID"]>;
  document?: InputMaybe<Scalars["ID"]>;
  last_updated_by?: InputMaybe<Scalars["ID"]>;
  matching_resolved_data_point?: InputMaybe<Scalars["ID"]>;
  parent_data_point?: InputMaybe<Scalars["ID"]>;
};

export type DataPointResolved_Data_PointsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  annotator_needs_review?: InputMaybe<Scalars["Boolean"]>;
  annotator_needs_review_type?: InputMaybe<Scalars["String"]>;
  display_value?: InputMaybe<Scalars["String"]>;
  has_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  hyperlink?: InputMaybe<Scalars["Int"]>;
  hyperlink_url?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_collision_resolved?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_1?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_2?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_3?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_4?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_5?: InputMaybe<Scalars["Boolean"]>;
  last_updated_at?: InputMaybe<Scalars["DateTime"]>;
  pin?: InputMaybe<Scalars["Boolean"]>;
  user_needs_review?: InputMaybe<Scalars["Boolean"]>;
  user_needs_review_type?: InputMaybe<Scalars["String"]>;
  value_bool?: InputMaybe<Scalars["Boolean"]>;
  value_float?: InputMaybe<Scalars["Float"]>;
  value_iso_date?: InputMaybe<Scalars["DateTime"]>;
  value_list?: InputMaybe<Scalars["String"]>;
  value_str?: InputMaybe<Scalars["String"]>;
  data_point_field?: InputMaybe<Scalars["ID"]>;
  deal?: InputMaybe<Scalars["ID"]>;
  document?: InputMaybe<Scalars["ID"]>;
  last_updated_by?: InputMaybe<Scalars["ID"]>;
  matching_resolved_data_point?: InputMaybe<Scalars["ID"]>;
  parent_data_point?: InputMaybe<Scalars["ID"]>;
};

export type DataPointErrorsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  error_message?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DataPointAnnotations = {
  __typename?: "DataPointAnnotations";
  document?: Maybe<Document>;
  annotations?: Maybe<Array<Maybe<Annotation>>>;
};

export type DataPointConnection = {
  __typename?: "DataPointConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataPointEdge>>;
};

export type DataPointDataTable = Node & {
  __typename?: "DataPointDataTable";
  columns?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The ID of the object. */
  id: Scalars["ID"];
  rows?: Maybe<Scalars["String"]>;
  table_matching_result?: Maybe<Scalars["JSONString"]>;
};

/** A Relay edge containing a `DataPoint` and its cursor. */
export type DataPointEdge = {
  __typename?: "DataPointEdge";
  /** The item at the end of the edge */
  node?: Maybe<DataPoint>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type DataPointField = Node & {
  __typename?: "DataPointField";
  /**
   * Models the actions with their corresponding extraction methods, field types
   * and sources.
   */
  action_type?: Maybe<ActionType>;
  automation_tag?: Maybe<Scalars["String"]>;
  collision_type?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  default_field_on_dashboard: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  display_accounting_impact: Scalars["Boolean"];
  display_annotations: Scalars["Boolean"];
  display_comment_field: Scalars["Boolean"];
  display_if_empty: Scalars["Boolean"];
  display_in_dashboard_filter: Scalars["Boolean"];
  display_manual_review: Scalars["Boolean"];
  display_on_checklist: Scalars["Boolean"];
  display_on_document: Scalars["Boolean"];
  document_types?: Maybe<DocumentTypeConnection>;
  external_source?: Maybe<Scalars["String"]>;
  extraction_consolidation_preferences?: Maybe<Scalars["JSONString"]>;
  extraction_field_type?: Maybe<Array<Maybe<Scalars["String"]>>>;
  extraction_logic?: Maybe<Scalars["JSONString"]>;
  extraction_method_instances_config?: Maybe<
    Array<Maybe<Scalars["JSONString"]>>
  >;
  extraction_prompt_fragments?: Maybe<ExtractionPromptFragmentConnection>;
  field_semantics?: Maybe<Scalars["String"]>;
  field_type: Scalars["String"];
  group?: Maybe<Scalars["String"]>;
  group_priority: Scalars["Int"];
  /** The ID of the object. */
  id: Scalars["ID"];
  inheritance_logic?: Maybe<Scalars["JSONString"]>;
  internal_mapping?: Maybe<Array<Maybe<Scalars["String"]>>>;
  is_deleted: Scalars["Boolean"];
  is_non_standard: Scalars["Boolean"];
  last_published_at?: Maybe<Scalars["DateTime"]>;
  last_published_by?: Maybe<User>;
  manual_review: Scalars["Boolean"];
  /** Models the specs of one matching verification  */
  match_config?: Maybe<MatchConfig>;
  name: Scalars["String"];
  options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /**
   * FILTERS, SORT FIELD TYPE
   * # TEXT - value_str (can also represent an address)
   * # BOOLEAN - value_bool
   * # DROP_DOWN - value_str
   * # MULTI_SELECT_DROP_DOWN - value_list
   * # DATE - value_iso_date
   * # DURATION - value_float (# TODO: value_duration)
   * # PERCENTAGE - value_float
   * # CURRENCY - value_float (# TODO: value_currency)
   * # CURRENCY_TYPE - value_str
   * # GEO_COUNTRY, GEO_STATE, GEO_CITY - value_str
   */
  parent_data_point_field?: Maybe<DataPointField>;
  pin_default_comment?: Maybe<Scalars["Boolean"]>;
  priority: Scalars["Int"];
  required_for_push_integration?: Maybe<Scalars["Boolean"]>;
  source: Scalars["String"];
  table_composition_config?: Maybe<TableCompositionConfigConnection>;
  value_format?: Maybe<Scalars["String"]>;
  option_to_neutral_tag_mapping?: Maybe<Scalars["JSONString"]>;
  external_mapping?: Maybe<Array<Maybe<Scalars["String"]>>>;
  samples?: Maybe<Array<Maybe<DataPointSample>>>;
  formula_config?: Maybe<Formula>;
  /** Main status of the data point field */
  main_status?: Maybe<DataPointFieldMainStatusEnum>;
  /** Sub status of the data point field */
  sub_status?: Maybe<DataPointFieldSubStatusEnum>;
  prompt_fragments: Array<Maybe<PromptFragment>>;
};

export type DataPointFieldDocument_TypesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_fallback_type?: InputMaybe<Scalars["Boolean"]>;
  multiple_documents?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ID"]>;
};

export type DataPointFieldExtraction_Prompt_FragmentsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  content?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["DateTime"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  extraction_prompt_fragment_template?: InputMaybe<Scalars["ID"]>;
  modified_by?: InputMaybe<Scalars["ID"]>;
};

export type DataPointFieldTable_Composition_ConfigArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  column_description?: InputMaybe<Scalars["String"]>;
  column_name?: InputMaybe<Scalars["String"]>;
  column_type?: InputMaybe<Scalars["String"]>;
};

export type DataPointFieldConnection = {
  __typename?: "DataPointFieldConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataPointFieldEdge>>;
};

/** A Relay edge containing a `DataPointField` and its cursor. */
export type DataPointFieldEdge = {
  __typename?: "DataPointFieldEdge";
  /** The item at the end of the edge */
  node?: Maybe<DataPointField>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** Enumeration for main statuses of DataPointField. */
export enum DataPointFieldMainStatusEnum {
  Draft = "DRAFT",
  PublishPending = "PUBLISH_PENDING",
  Published = "PUBLISHED",
  Archived = "ARCHIVED",
}

/** Enumeration for sub statuses of DataPointField. */
export enum DataPointFieldSubStatusEnum {
  PendingSamplesGathering = "PENDING_SAMPLES_GATHERING",
  SamplesReadyForReview = "SAMPLES_READY_FOR_REVIEW",
  SamplesReviewInProgress = "SAMPLES_REVIEW_IN_PROGRESS",
  SampleGenerationFailed = "SAMPLE_GENERATION_FAILED",
  NoSamplesFound = "NO_SAMPLES_FOUND",
  ReviewCompleted = "REVIEW_COMPLETED",
  PublishingInProgress = "PUBLISHING_IN_PROGRESS",
  PublishingFailed = "PUBLISHING_FAILED",
  Published = "PUBLISHED",
  Archived = "ARCHIVED",
}

export type DataPointSample = {
  __typename?: "DataPointSample";
  data_point_id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  is_collision_resolved?: Maybe<Scalars["Boolean"]>;
  document_text?: Maybe<Scalars["String"]>;
  deal_id?: Maybe<Scalars["String"]>;
  deal_name?: Maybe<Scalars["String"]>;
  children?: Maybe<Array<Maybe<DataPointSampleChild>>>;
  feedback_submitted?: Maybe<Scalars["Boolean"]>;
};

export type DataPointSampleChild = {
  __typename?: "DataPointSampleChild";
  value?: Maybe<Scalars["String"]>;
  is_resolved_data_point?: Maybe<Scalars["Boolean"]>;
  data_point_id?: Maybe<Scalars["String"]>;
  field_name?: Maybe<Scalars["String"]>;
  document_id?: Maybe<Scalars["String"]>;
  document_name?: Maybe<Scalars["String"]>;
  document_type?: Maybe<Scalars["String"]>;
  feedback_submitted?: Maybe<Scalars["Boolean"]>;
};

export type Deal = Node & {
  __typename?: "Deal";
  alias?: Maybe<Scalars["String"]>;
  all_assignees?: Maybe<UserConnection>;
  assignee?: Maybe<User>;
  attachments?: Maybe<AttachmentConnection>;
  counter_party?: Maybe<CounterParty>;
  created_at?: Maybe<Scalars["DateTime"]>;
  documents?: Maybe<DocumentConnection>;
  errors?: Maybe<SystemFailureConnection>;
  excluded_documents?: Maybe<DocumentConnection>;
  external_metadata?: Maybe<Scalars["JSONString"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  name: Scalars["String"];
  notes?: Maybe<NoteConnection>;
  original_external_metadata?: Maybe<Scalars["JSONString"]>;
  state?: Maybe<State>;
  processing_documents?: Maybe<Array<Maybe<Document>>>;
};

export type DealAll_AssigneesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  demo?: InputMaybe<Scalars["Boolean"]>;
  first_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_super_admin?: InputMaybe<Scalars["Boolean"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  notification_email_ids?: InputMaybe<Scalars["String"]>;
  notification_preferences?: InputMaybe<Scalars["String"]>;
  user_name?: InputMaybe<Scalars["String"]>;
  default_deals_query?: InputMaybe<Scalars["ID"]>;
  default_documents_query?: InputMaybe<Scalars["ID"]>;
};

export type DealAttachmentsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  alias?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  document_id?: InputMaybe<Scalars["String"]>;
  has_pdf_preview?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
};

export type DealDocumentsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  alias?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  external_document?: InputMaybe<Scalars["Boolean"]>;
  extraction_status?: InputMaybe<Scalars["String"]>;
  hash?: InputMaybe<Scalars["JSONString"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_status?: InputMaybe<Scalars["String"]>;
  is_counter_party_level?: InputMaybe<Scalars["Boolean"]>;
  is_deal_level?: InputMaybe<Scalars["Boolean"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  is_legacy?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  original_name?: InputMaybe<Scalars["String"]>;
  page_count?: InputMaybe<Scalars["Int"]>;
  prizmdoc_id?: InputMaybe<Scalars["String"]>;
  project?: InputMaybe<Scalars["String"]>;
  qc_reason?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<Scalars["String"]>;
  visualizer_version?: InputMaybe<Scalars["Float"]>;
  assignee?: InputMaybe<Scalars["ID"]>;
  counter_party?: InputMaybe<Scalars["ID"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  document_type?: InputMaybe<Scalars["ID"]>;
  internal_assignee?: InputMaybe<Scalars["ID"]>;
  internal_state?: InputMaybe<Scalars["ID"]>;
  internal_workflow?: InputMaybe<Scalars["ID"]>;
  state?: InputMaybe<Scalars["ID"]>;
};

export type DealErrorsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  error_message?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DealExcluded_DocumentsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  alias?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  external_document?: InputMaybe<Scalars["Boolean"]>;
  extraction_status?: InputMaybe<Scalars["String"]>;
  hash?: InputMaybe<Scalars["JSONString"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_status?: InputMaybe<Scalars["String"]>;
  is_counter_party_level?: InputMaybe<Scalars["Boolean"]>;
  is_deal_level?: InputMaybe<Scalars["Boolean"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  is_legacy?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  original_name?: InputMaybe<Scalars["String"]>;
  page_count?: InputMaybe<Scalars["Int"]>;
  prizmdoc_id?: InputMaybe<Scalars["String"]>;
  project?: InputMaybe<Scalars["String"]>;
  qc_reason?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<Scalars["String"]>;
  visualizer_version?: InputMaybe<Scalars["Float"]>;
  assignee?: InputMaybe<Scalars["ID"]>;
  counter_party?: InputMaybe<Scalars["ID"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  document_type?: InputMaybe<Scalars["ID"]>;
  internal_assignee?: InputMaybe<Scalars["ID"]>;
  internal_state?: InputMaybe<Scalars["ID"]>;
  internal_workflow?: InputMaybe<Scalars["ID"]>;
  state?: InputMaybe<Scalars["ID"]>;
};

export type DealNotesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  text?: InputMaybe<Scalars["String"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
};

export type DealConnection = {
  __typename?: "DealConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DealEdge>>;
};

export type DealDashboardDefaultField = Node & {
  __typename?: "DealDashboardDefaultField";
  internal_name: Scalars["String"];
  is_visible?: Maybe<Scalars["Boolean"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type DealDashboardDefaultFieldConnection = {
  __typename?: "DealDashboardDefaultFieldConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DealDashboardDefaultFieldEdge>>;
};

/** A Relay edge containing a `DealDashboardDefaultField` and its cursor. */
export type DealDashboardDefaultFieldEdge = {
  __typename?: "DealDashboardDefaultFieldEdge";
  /** The item at the end of the edge */
  node?: Maybe<DealDashboardDefaultField>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** A Relay edge containing a `Deal` and its cursor. */
export type DealEdge = {
  __typename?: "DealEdge";
  /** The item at the end of the edge */
  node?: Maybe<Deal>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** Represents the output for rerunning extraction for a single deal */
export type DealRerunExtractionResult = {
  __typename?: "DealRerunExtractionResult";
  deal_id: Scalars["ID"];
  queued_for_extraction: Scalars["Boolean"];
  display_message: Scalars["String"];
  queued_documents: Array<Maybe<Scalars["ID"]>>;
  failed_to_queue_documents: Array<Maybe<Scalars["ID"]>>;
  already_processing_documents: Array<Maybe<Scalars["ID"]>>;
};

export type DealsDocumentsWithAttachment = {
  __typename?: "DealsDocumentsWithAttachment";
  deals?: Maybe<Array<Maybe<Deal>>>;
  documents?: Maybe<Array<Maybe<Document>>>;
};

/** Represents the output for rerunning extraction for a list of deals */
export type DealsRerunExtractionResult = {
  __typename?: "DealsRerunExtractionResult";
  deals_rerun_extraction_result: Array<Maybe<DealRerunExtractionResult>>;
};

export type DeleteAnnotation = {
  __typename?: "DeleteAnnotation";
  document?: Maybe<Document>;
  data_points?: Maybe<Array<Maybe<DataPoint>>>;
};

export type DeleteAnnotationsByInternalName = {
  __typename?: "DeleteAnnotationsByInternalName";
  document?: Maybe<Document>;
  data_points?: Maybe<Array<Maybe<DataPoint>>>;
};

export type DeleteComment = {
  __typename?: "DeleteComment";
  status?: Maybe<Scalars["Boolean"]>;
};

export type DeleteDashboardQuery = {
  __typename?: "DeleteDashboardQuery";
  dashboard_query?: Maybe<DashboardQuery>;
};

/**
 * Deletes data point field
 * data_point_field_id: The data point field being deleted
 * return: success if the data point field was successfully deleted
 */
export type DeleteDataPointField = {
  __typename?: "DeleteDataPointField";
  success?: Maybe<Scalars["Boolean"]>;
};

export type DeleteDeal = {
  __typename?: "DeleteDeal";
  deal?: Maybe<Deal>;
};

export type DeleteDealAttachment = {
  __typename?: "DeleteDealAttachment";
  deal?: Maybe<Deal>;
};

export type DeleteDealNote = {
  __typename?: "DeleteDealNote";
  deal?: Maybe<Deal>;
};

export type DeleteDocument = {
  __typename?: "DeleteDocument";
  document?: Maybe<Document>;
  deal?: Maybe<Deal>;
  deal_data_points?: Maybe<Array<Maybe<DataPoint>>>;
};

export type DeleteDocumentAttachment = {
  __typename?: "DeleteDocumentAttachment";
  document?: Maybe<Document>;
};

export type DeleteDocumentNote = {
  __typename?: "DeleteDocumentNote";
  document?: Maybe<Document>;
};

export type DeleteIntegration = {
  __typename?: "DeleteIntegration";
  success?: Maybe<Scalars["Boolean"]>;
};

export type DeleteUser = {
  __typename?: "DeleteUser";
  user?: Maybe<User>;
};

export type Document = Node & {
  __typename?: "Document";
  alias?: Maybe<Scalars["String"]>;
  all_assignees?: Maybe<UserConnection>;
  annotations?: Maybe<AnnotationConnection>;
  annotator_annotations?: Maybe<AnnotationConnection>;
  assignee?: Maybe<User>;
  attachments?: Maybe<AttachmentConnection>;
  counter_party?: Maybe<CounterParty>;
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  document_type?: Maybe<DocumentType>;
  external_document?: Maybe<Scalars["Boolean"]>;
  extraction_status?: Maybe<Scalars["String"]>;
  hash?: Maybe<Scalars["JSONString"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  internal_assignee?: Maybe<User>;
  internal_state?: Maybe<InternalState>;
  internal_status: Scalars["String"];
  internal_workflow?: Maybe<InternalWorkflow>;
  is_counter_party_level?: Maybe<Scalars["Boolean"]>;
  is_deal_level?: Maybe<Scalars["Boolean"]>;
  is_deleted: Scalars["Boolean"];
  is_legacy?: Maybe<Scalars["Boolean"]>;
  is_reviewed: Scalars["Boolean"];
  name: Scalars["String"];
  notes?: Maybe<NoteConnection>;
  original_name?: Maybe<Scalars["String"]>;
  page_count?: Maybe<Scalars["Int"]>;
  prizmdoc_id?: Maybe<Scalars["String"]>;
  project: Scalars["String"];
  qc_reason?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
  state?: Maybe<State>;
  versions?: Maybe<DocumentVersionConnection>;
  visualizer_version: Scalars["Float"];
  integration_type?: Maybe<Scalars["String"]>;
  requeued_for_processing?: Maybe<Scalars["Boolean"]>;
  novel_para_annotations: Array<Annotation>;
  errors?: Maybe<SystemFailureConnection>;
};

export type DocumentAll_AssigneesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  demo?: InputMaybe<Scalars["Boolean"]>;
  first_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_super_admin?: InputMaybe<Scalars["Boolean"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  notification_email_ids?: InputMaybe<Scalars["String"]>;
  notification_preferences?: InputMaybe<Scalars["String"]>;
  user_name?: InputMaybe<Scalars["String"]>;
  default_deals_query?: InputMaybe<Scalars["ID"]>;
  default_documents_query?: InputMaybe<Scalars["ID"]>;
};

export type DocumentAnnotationsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  core_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  label_name?: InputMaybe<Scalars["String"]>;
  label_type?: InputMaybe<Scalars["String"]>;
  last_updated_at?: InputMaybe<Scalars["DateTime"]>;
  review_state?: InputMaybe<Scalars["String"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  last_updated_by?: InputMaybe<Scalars["ID"]>;
};

export type DocumentAnnotator_AnnotationsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  core_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  label_name?: InputMaybe<Scalars["String"]>;
  label_type?: InputMaybe<Scalars["String"]>;
  last_updated_at?: InputMaybe<Scalars["DateTime"]>;
  review_state?: InputMaybe<Scalars["String"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  last_updated_by?: InputMaybe<Scalars["ID"]>;
};

export type DocumentAttachmentsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  alias?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  document_id?: InputMaybe<Scalars["String"]>;
  has_pdf_preview?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
};

export type DocumentNotesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  text?: InputMaybe<Scalars["String"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
};

export type DocumentVersionsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["ID"]>;
  last_updated_at?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  prizmdoc_diff_id?: InputMaybe<Scalars["String"]>;
  prizmdoc_id?: InputMaybe<Scalars["String"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  last_updated_by?: InputMaybe<Scalars["ID"]>;
};

export type DocumentErrorsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  error_message?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DocumentConnection = {
  __typename?: "DocumentConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DocumentEdge>>;
};

export type DocumentDashboardDefaultField = Node & {
  __typename?: "DocumentDashboardDefaultField";
  internal_name: Scalars["String"];
  is_visible?: Maybe<Scalars["Boolean"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type DocumentDashboardDefaultFieldConnection = {
  __typename?: "DocumentDashboardDefaultFieldConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DocumentDashboardDefaultFieldEdge>>;
};

/** A Relay edge containing a `DocumentDashboardDefaultField` and its cursor. */
export type DocumentDashboardDefaultFieldEdge = {
  __typename?: "DocumentDashboardDefaultFieldEdge";
  /** The item at the end of the edge */
  node?: Maybe<DocumentDashboardDefaultField>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** A Relay edge containing a `Document` and its cursor. */
export type DocumentEdge = {
  __typename?: "DocumentEdge";
  /** The item at the end of the edge */
  node?: Maybe<Document>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type DocumentType = Node & {
  __typename?: "DocumentType";
  customer?: Maybe<Customer>;
  description?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_fallback_type?: Maybe<Scalars["Boolean"]>;
  multiple_documents?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
};

export type DocumentTypeConnection = {
  __typename?: "DocumentTypeConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DocumentTypeEdge>>;
};

/** A Relay edge containing a `DocumentType` and its cursor. */
export type DocumentTypeEdge = {
  __typename?: "DocumentTypeEdge";
  /** The item at the end of the edge */
  node?: Maybe<DocumentType>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type DocumentVersion = Node & {
  __typename?: "DocumentVersion";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  /** The ID of the object. */
  id: Scalars["ID"];
  last_updated_at?: Maybe<Scalars["DateTime"]>;
  last_updated_by?: Maybe<User>;
  name: Scalars["String"];
  prizmdoc_diff_id?: Maybe<Scalars["String"]>;
  prizmdoc_id?: Maybe<Scalars["String"]>;
};

export type DocumentVersionConnection = {
  __typename?: "DocumentVersionConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DocumentVersionEdge>>;
};

/** A Relay edge containing a `DocumentVersion` and its cursor. */
export type DocumentVersionEdge = {
  __typename?: "DocumentVersionEdge";
  /** The item at the end of the edge */
  node?: Maybe<DocumentVersion>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type DocumentsAffectedByDealDelete = {
  __typename?: "DocumentsAffectedByDealDelete";
  documents_to_delete?: Maybe<Array<Maybe<Document>>>;
  documents_to_preserve?: Maybe<Array<Maybe<Document>>>;
};

/**
 * Represents the output for rerunning extraction for a list of documents
 * queued_documents: List of IDs of successfully queued documents
 * failed_to_queue_documents: List of IDs of documents that failed to
 *                            queue, because exception raised while loading LLM config from DB.
 * already_processing_documents: List of IDs of documents that are already in processing state
 */
export type DocumentsRerunExtractionResult = {
  __typename?: "DocumentsRerunExtractionResult";
  queued_documents: Array<Maybe<Scalars["ID"]>>;
  failed_to_queue_documents: Array<Maybe<Scalars["ID"]>>;
  already_processing_documents: Array<Maybe<Scalars["ID"]>>;
};

export type EditComment = {
  __typename?: "EditComment";
  comment?: Maybe<Comment>;
};

export type EditCounterParty = {
  __typename?: "EditCounterParty";
  counter_party?: Maybe<CounterParty>;
};

export type EditDataPoint = {
  __typename?: "EditDataPoint";
  data_points?: Maybe<Array<Maybe<DataPoint>>>;
};

/**
 * Edits deal/document data point field.
 * If variable is None, the field will not be changed in the data point field
 * return: The deal data point field after the edit
 */
export type EditDataPointField = {
  __typename?: "EditDataPointField";
  data_point_field?: Maybe<DataPointField>;
};

export type EditDataTableDataPoint = {
  __typename?: "EditDataTableDataPoint";
  data_points?: Maybe<Array<Maybe<DataPoint>>>;
};

export type EditDeal = {
  __typename?: "EditDeal";
  deal?: Maybe<Deal>;
  dashboard_data?: Maybe<AdvancedDashboardData>;
  deal_data_points?: Maybe<Array<Maybe<DataPoint>>>;
  transition_audit?: Maybe<TransitionAudit>;
};

export type EditDealAttachment = {
  __typename?: "EditDealAttachment";
  deal?: Maybe<Deal>;
};

export type EditDocument = {
  __typename?: "EditDocument";
  document?: Maybe<Document>;
  dashboard_data?: Maybe<AdvancedDashboardData>;
  transition_audit?: Maybe<TransitionAudit>;
};

export type EditDocumentAttachment = {
  __typename?: "EditDocumentAttachment";
  document?: Maybe<Document>;
};

export type EditFieldExtractionMethodInstance = {
  __typename?: "EditFieldExtractionMethodInstance";
  extraction_method_instance?: Maybe<ExtractionMethodInstance>;
  data_point_field?: Maybe<DataPointField>;
};

/**
 * Edits name for a group
 * old_group_name: Current name of the group
 * new_group_name: New name of the group
 * return: The data point fields with the group edited
 */
export type EditGroupName = {
  __typename?: "EditGroupName";
  data_point_fields?: Maybe<Array<Maybe<DataPointField>>>;
};

/**
 * Edit group priority
 * group_name: Group name that is being reordered
 * move_after_group_name: Group that group_name is being moved after. If move_after_group_name is None, then
 * group_name will be moved to the top
 * return: The data point fields with group priority edited
 */
export type EditGroupPriority = {
  __typename?: "EditGroupPriority";
  data_point_fields?: Maybe<Array<Maybe<DataPointField>>>;
};

export type EditIntegration = {
  __typename?: "EditIntegration";
  integration?: Maybe<Integration>;
};

export type EditRole = {
  __typename?: "EditRole";
  role?: Maybe<Role>;
};

export type EditState = {
  __typename?: "EditState";
  state?: Maybe<State>;
};

export type EditUser = {
  __typename?: "EditUser";
  user?: Maybe<User>;
};

export type EditWorkflow = {
  __typename?: "EditWorkflow";
  workflow?: Maybe<Workflow>;
};

export type ErrorCodeMeta = {
  __typename?: "ErrorCodeMeta";
  error_message?: Maybe<Scalars["String"]>;
  error_code?: Maybe<Scalars["String"]>;
  failure_type?: Maybe<Scalars["String"]>;
};

export type ExtractionMethod = Node & {
  __typename?: "ExtractionMethod";
  allowed_for_every_customer?: Maybe<Scalars["Boolean"]>;
  customer_allowlist?: Maybe<CustomerConnection>;
  description?: Maybe<Scalars["String"]>;
  display_name: Scalars["String"];
  extraction_prompt_templates?: Maybe<ExtractionPromptTemplateConnection>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_freetext?: Maybe<Scalars["Boolean"]>;
  is_tabular?: Maybe<Scalars["Boolean"]>;
  latest_prompt_templates?: Maybe<PromptTemplateConnection>;
  version?: Maybe<Scalars["String"]>;
  active_prompt_templates?: Maybe<Array<Maybe<PromptTemplate>>>;
};

export type ExtractionMethodCustomer_AllowlistArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  alternate_emails?: InputMaybe<Scalars["String"]>;
  counter_party_level_classification_config?: InputMaybe<Scalars["JSONString"]>;
  customer_email?: InputMaybe<Scalars["String"]>;
  deal_level_classification_config?: InputMaybe<Scalars["JSONString"]>;
  document_deduplication_config?: InputMaybe<Scalars["JSONString"]>;
  email_domain_allow_list?: InputMaybe<Scalars["String"]>;
  email_domain_for_idp?: InputMaybe<Scalars["String"]>;
  expiry_date?: InputMaybe<Scalars["DateTime"]>;
  external_context_logic?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  idp_id?: InputMaybe<Scalars["String"]>;
  limits?: InputMaybe<Scalars["String"]>;
  manual_upload_config?: InputMaybe<Scalars["JSONString"]>;
  name?: InputMaybe<Scalars["String"]>;
  trial?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type ExtractionMethodExtraction_Prompt_TemplatesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["DateTime"]>;
  service_provider?: InputMaybe<Scalars["String"]>;
  system_prompt_template?: InputMaybe<Scalars["String"]>;
  user_prompt_template?: InputMaybe<Scalars["String"]>;
  version?: InputMaybe<Scalars["Int"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  modified_by?: InputMaybe<Scalars["ID"]>;
};

export type ExtractionMethodLatest_Prompt_TemplatesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
  models?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["DateTime"]>;
  service_providers?: InputMaybe<Scalars["String"]>;
  system_prompt_template?: InputMaybe<Scalars["String"]>;
  user_prompt_template?: InputMaybe<Scalars["String"]>;
  version?: InputMaybe<Scalars["Int"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  modified_by?: InputMaybe<Scalars["ID"]>;
};

export type ExtractionMethodConnection = {
  __typename?: "ExtractionMethodConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExtractionMethodEdge>>;
};

/** A Relay edge containing a `ExtractionMethod` and its cursor. */
export type ExtractionMethodEdge = {
  __typename?: "ExtractionMethodEdge";
  /** The item at the end of the edge */
  node?: Maybe<ExtractionMethod>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type ExtractionMethodInstance = Node & {
  __typename?: "ExtractionMethodInstance";
  config?: Maybe<Scalars["JSONString"]>;
  current_prompt_templates?: Maybe<PromptTemplateConnection>;
  customer?: Maybe<Customer>;
  data_point_fields?: Maybe<DataPointFieldConnection>;
  extraction_method?: Maybe<ExtractionMethod>;
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type ExtractionMethodInstanceCurrent_Prompt_TemplatesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
  models?: InputMaybe<Scalars["String"]>;
  modified_at?: InputMaybe<Scalars["DateTime"]>;
  service_providers?: InputMaybe<Scalars["String"]>;
  system_prompt_template?: InputMaybe<Scalars["String"]>;
  user_prompt_template?: InputMaybe<Scalars["String"]>;
  version?: InputMaybe<Scalars["Int"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  modified_by?: InputMaybe<Scalars["ID"]>;
};

export type ExtractionMethodInstanceData_Point_FieldsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  automation_tag?: InputMaybe<Scalars["String"]>;
  collision_type?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_field_on_dashboard?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  display_annotations?: InputMaybe<Scalars["Boolean"]>;
  display_comment_field?: InputMaybe<Scalars["Boolean"]>;
  display_if_empty?: InputMaybe<Scalars["Boolean"]>;
  display_in_dashboard_filter?: InputMaybe<Scalars["Boolean"]>;
  display_manual_review?: InputMaybe<Scalars["Boolean"]>;
  display_on_checklist?: InputMaybe<Scalars["Boolean"]>;
  display_on_document?: InputMaybe<Scalars["Boolean"]>;
  external_source?: InputMaybe<Scalars["String"]>;
  extraction_consolidation_preferences?: InputMaybe<Scalars["JSONString"]>;
  extraction_field_type?: InputMaybe<Scalars["String"]>;
  extraction_logic?: InputMaybe<Scalars["JSONString"]>;
  extraction_method_instances_config?: InputMaybe<Scalars["JSONString"]>;
  field_semantics?: InputMaybe<Scalars["String"]>;
  field_type?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  group_priority?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  inheritance_logic?: InputMaybe<Scalars["JSONString"]>;
  internal_mapping?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  is_non_standard?: InputMaybe<Scalars["Boolean"]>;
  last_published_at?: InputMaybe<Scalars["DateTime"]>;
  manual_review?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  options?: InputMaybe<Scalars["String"]>;
  pin_default_comment?: InputMaybe<Scalars["Boolean"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  required_for_push_integration?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<Scalars["String"]>;
  value_format?: InputMaybe<Scalars["String"]>;
  action_type?: InputMaybe<Scalars["ID"]>;
  last_published_by?: InputMaybe<Scalars["ID"]>;
  parent_data_point_field?: InputMaybe<Scalars["ID"]>;
};

export type ExtractionPromptFragment = Node & {
  __typename?: "ExtractionPromptFragment";
  content: Scalars["String"];
  created_at: Scalars["DateTime"];
  created_by?: Maybe<User>;
  extraction_prompt_fragment_template?: Maybe<ExtractionPromptFragmentTemplate>;
  internal_name: Scalars["String"];
  modified_at: Scalars["DateTime"];
  modified_by?: Maybe<User>;
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type ExtractionPromptFragmentConnection = {
  __typename?: "ExtractionPromptFragmentConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExtractionPromptFragmentEdge>>;
};

/** A Relay edge containing a `ExtractionPromptFragment` and its cursor. */
export type ExtractionPromptFragmentEdge = {
  __typename?: "ExtractionPromptFragmentEdge";
  /** The item at the end of the edge */
  node?: Maybe<ExtractionPromptFragment>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type ExtractionPromptFragmentTemplate = Node & {
  __typename?: "ExtractionPromptFragmentTemplate";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  data_type: Scalars["String"];
  default_value?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  display_guidelines?: Maybe<Scalars["String"]>;
  display_name: Scalars["String"];
  display_question?: Maybe<Scalars["String"]>;
  fragment_type: Scalars["String"];
  /** The ID of the object. */
  id: Scalars["ID"];
  internal_name: Scalars["String"];
  is_hidden?: Maybe<Scalars["Boolean"]>;
  is_mandatory?: Maybe<Scalars["Boolean"]>;
  modified_at?: Maybe<Scalars["DateTime"]>;
  modified_by?: Maybe<User>;
  version: Scalars["Int"];
};

export type ExtractionPromptFragmentTemplateConnection = {
  __typename?: "ExtractionPromptFragmentTemplateConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExtractionPromptFragmentTemplateEdge>>;
};

/** A Relay edge containing a `ExtractionPromptFragmentTemplate` and its cursor. */
export type ExtractionPromptFragmentTemplateEdge = {
  __typename?: "ExtractionPromptFragmentTemplateEdge";
  /** The item at the end of the edge */
  node?: Maybe<ExtractionPromptFragmentTemplate>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type ExtractionPromptTemplate = Node & {
  __typename?: "ExtractionPromptTemplate";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  description?: Maybe<Scalars["String"]>;
  display_name: Scalars["String"];
  extraction_prompt_fragment_templates?: Maybe<ExtractionPromptFragmentTemplateConnection>;
  /** The ID of the object. */
  id: Scalars["ID"];
  internal_name: Scalars["String"];
  model: Scalars["String"];
  modified_at?: Maybe<Scalars["DateTime"]>;
  modified_by?: Maybe<User>;
  service_provider: Scalars["String"];
  system_prompt_template: Scalars["String"];
  user_prompt_template: Scalars["String"];
  version: Scalars["Int"];
};

export type ExtractionPromptTemplateExtraction_Prompt_Fragment_TemplatesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  data_type?: InputMaybe<Scalars["String"]>;
  default_value?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_guidelines?: InputMaybe<Scalars["String"]>;
  display_name?: InputMaybe<Scalars["String"]>;
  display_question?: InputMaybe<Scalars["String"]>;
  fragment_type?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
  is_hidden?: InputMaybe<Scalars["Boolean"]>;
  is_mandatory?: InputMaybe<Scalars["Boolean"]>;
  modified_at?: InputMaybe<Scalars["DateTime"]>;
  version?: InputMaybe<Scalars["Int"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  modified_by?: InputMaybe<Scalars["ID"]>;
};

export type ExtractionPromptTemplateConnection = {
  __typename?: "ExtractionPromptTemplateConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExtractionPromptTemplateEdge>>;
};

/** A Relay edge containing a `ExtractionPromptTemplate` and its cursor. */
export type ExtractionPromptTemplateEdge = {
  __typename?: "ExtractionPromptTemplateEdge";
  /** The item at the end of the edge */
  node?: Maybe<ExtractionPromptTemplate>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type Feature = Node & {
  __typename?: "Feature";
  /** The ID of the object. */
  id: Scalars["ID"];
  name: Scalars["String"];
  permission?: Maybe<Scalars["String"]>;
};

export type FeatureConnection = {
  __typename?: "FeatureConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FeatureEdge>>;
};

/** A Relay edge containing a `Feature` and its cursor. */
export type FeatureEdge = {
  __typename?: "FeatureEdge";
  /** The item at the end of the edge */
  node?: Maybe<Feature>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** GraphQL type for feedback instances. */
export type Feedback = Node & {
  __typename?: "Feedback";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  feedback_highlights?: Maybe<FeedbackHighlightConnection>;
  feedback_session_events?: Maybe<FeedbackSessionEventConnection>;
  /**
   * Models the user feedback template with version control.
   *
   * Each update or attempt to create a similar template increments the version
   * number, storing each state as an immutable snapshot.
   */
  feedback_template?: Maybe<FeedbackTemplate>;
  /** The ID of the object. */
  id: Scalars["ID"];
  modified_at?: Maybe<Scalars["DateTime"]>;
  modified_by?: Maybe<User>;
  /** Type of feedback (DATA-POINT, DOCUMENT, etc.) */
  feedback_type?: Maybe<Scalars["String"]>;
  /** Category of feedback. */
  feedback_category?: Maybe<FeedbackCategory>;
  /** User's option like Correctly Extracted, Incorrectly Extracted, Completely Missed. */
  user_option?: Maybe<UserOption>;
};

/** GraphQL type for feedback instances. */
export type FeedbackFeedback_HighlightsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
};

/** GraphQL type for feedback instances. */
export type FeedbackFeedback_Session_EventsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  event_log?: InputMaybe<Scalars["JSONString"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
};

/** Enum for Feedback Category */
export enum FeedbackCategory {
  TpApprox = "TP_APPROX",
  Tp = "TP",
  Fp = "FP",
  Tn = "TN",
  Fn = "FN",
}

export type FeedbackHighlight = Node & {
  __typename?: "FeedbackHighlight";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  internal_name: Scalars["String"];
  positions?: Maybe<FeedbackHighlightPositionConnection>;
  text?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type FeedbackHighlightPositionsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  page_height?: InputMaybe<Scalars["Float"]>;
  page_number?: InputMaybe<Scalars["Int"]>;
  page_width?: InputMaybe<Scalars["Float"]>;
};

export type FeedbackHighlightConnection = {
  __typename?: "FeedbackHighlightConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FeedbackHighlightEdge>>;
};

/** A Relay edge containing a `FeedbackHighlight` and its cursor. */
export type FeedbackHighlightEdge = {
  __typename?: "FeedbackHighlightEdge";
  /** The item at the end of the edge */
  node?: Maybe<FeedbackHighlight>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** Represents the input for creating a feedback highlight. */
export type FeedbackHighlightInput = {
  internal_name: Scalars["String"];
  positions: Array<InputMaybe<FeedbackHighlightPositionInput>>;
};

export type FeedbackHighlightPosition = Node & {
  __typename?: "FeedbackHighlightPosition";
  /**
   * Data model for bounding box of feedback highlight position
   *
   */
  bounding_rect?: Maybe<FeedbackHighlightPositionBbox>;
  page_height: Scalars["Float"];
  page_number: Scalars["Int"];
  page_width: Scalars["Float"];
  rects?: Maybe<FeedbackHighlightPositionBboxConnection>;
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type FeedbackHighlightPositionRectsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  x1?: InputMaybe<Scalars["Float"]>;
  x2?: InputMaybe<Scalars["Float"]>;
  y1?: InputMaybe<Scalars["Float"]>;
  y2?: InputMaybe<Scalars["Float"]>;
};

export type FeedbackHighlightPositionBbox = Node & {
  __typename?: "FeedbackHighlightPositionBBOX";
  x1: Scalars["Float"];
  x2: Scalars["Float"];
  y1: Scalars["Float"];
  y2: Scalars["Float"];
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type FeedbackHighlightPositionBboxConnection = {
  __typename?: "FeedbackHighlightPositionBBOXConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FeedbackHighlightPositionBboxEdge>>;
};

/** A Relay edge containing a `FeedbackHighlightPositionBBOX` and its cursor. */
export type FeedbackHighlightPositionBboxEdge = {
  __typename?: "FeedbackHighlightPositionBBOXEdge";
  /** The item at the end of the edge */
  node?: Maybe<FeedbackHighlightPositionBbox>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** Represents the bounding box of a feedback highlight. */
export type FeedbackHighlightPositionBboxInput = {
  x1: Scalars["Float"];
  y1: Scalars["Float"];
  x2: Scalars["Float"];
  y2: Scalars["Float"];
};

export type FeedbackHighlightPositionConnection = {
  __typename?: "FeedbackHighlightPositionConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FeedbackHighlightPositionEdge>>;
};

/** A Relay edge containing a `FeedbackHighlightPosition` and its cursor. */
export type FeedbackHighlightPositionEdge = {
  __typename?: "FeedbackHighlightPositionEdge";
  /** The item at the end of the edge */
  node?: Maybe<FeedbackHighlightPosition>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** Represents the position of a feedback highlight. */
export type FeedbackHighlightPositionInput = {
  page_number: Scalars["Int"];
  page_width: Scalars["Float"];
  page_height: Scalars["Float"];
  bounding_rect: FeedbackHighlightPositionBboxInput;
  rects: Array<InputMaybe<FeedbackHighlightPositionBboxInput>>;
};

/** GraphQL type for feedback session event type */
export type FeedbackSessionEvent = Node & {
  __typename?: "FeedbackSessionEvent";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  event_log?: Maybe<Scalars["JSONString"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  /** Feedback session event */
  feedback_session_event_type?: Maybe<FeedbackSessionEventType>;
};

export type FeedbackSessionEventConnection = {
  __typename?: "FeedbackSessionEventConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FeedbackSessionEventEdge>>;
};

/** A Relay edge containing a `FeedbackSessionEvent` and its cursor. */
export type FeedbackSessionEventEdge = {
  __typename?: "FeedbackSessionEventEdge";
  /** The item at the end of the edge */
  node?: Maybe<FeedbackSessionEvent>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** Enum for Feedback Session Event Type */
export enum FeedbackSessionEventType {
  UserFeedback = "USER_FEEDBACK",
  LocalLearningResponse = "LOCAL_LEARNING_RESPONSE",
  SampleGeneration = "SAMPLE_GENERATION",
}

/** Enum for Feedback Status of the Verified Sample */
export enum FeedbackStatus {
  SampleGenerated = "SAMPLE_GENERATED",
  Draft = "DRAFT",
  FeedbackInProgressExtractionInProgress = "FEEDBACK_IN_PROGRESS_EXTRACTION_IN_PROGRESS",
  FeedbackInProgressExtractionFailed = "FEEDBACK_IN_PROGRESS_EXTRACTION_FAILED",
  FeedbackApplied = "FEEDBACK_APPLIED",
  FeedbackNotRequired = "FEEDBACK_NOT_REQUIRED",
  Deleted = "DELETED",
}

/** GraphQL type for feedback templates, handling different types of feedback scenarios. */
export type FeedbackTemplate = Node & {
  __typename?: "FeedbackTemplate";
  created_at?: Maybe<Scalars["DateTime"]>;
  default_model: Scalars["String"];
  default_vendor: Scalars["String"];
  feedback_type_templates?: Maybe<FeedbackTypeTemplateConnection>;
  /** The ID of the object. */
  id: Scalars["ID"];
  local_learning_prompt_fragment?: Maybe<Array<Maybe<Scalars["JSONString"]>>>;
  version: Scalars["Int"];
  /** Type of feedback (DATA-POINT, DOCUMENT, etc.) */
  feedback_type?: Maybe<Scalars["String"]>;
  /** Type of data extraction field (TABULAR, FREE-TEXT, etc.) */
  extraction_field_type?: Maybe<Scalars["String"]>;
  user_learning_options?: Maybe<UserLearningOptionConnection>;
};

/** GraphQL type for feedback templates, handling different types of feedback scenarios. */
export type FeedbackTemplateFeedback_Type_TemplatesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  images_needed?: InputMaybe<Scalars["Boolean"]>;
  user_feedback_template?: InputMaybe<Scalars["String"]>;
};

/** GraphQL type for feedback templates, handling different types of feedback scenarios. */
export type FeedbackTemplateUser_Learning_OptionsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  option_label?: InputMaybe<Scalars["String"]>;
};

/** GraphQL type for individual feedback type templates within a feedback template. */
export type FeedbackTypeTemplate = Node & {
  __typename?: "FeedbackTypeTemplate";
  images_needed?: Maybe<Scalars["Boolean"]>;
  user_feedback_template: Scalars["String"];
  /** The ID of the object. */
  id: Scalars["ID"];
  /** Category of feedback such as TP, TN, FP, FN. */
  feedback_category?: Maybe<FeedbackCategory>;
  /** User's option like Correctly Extracted, Incorrectly Extracted, Completely Missed. */
  user_option?: Maybe<UserOption>;
};

export type FeedbackTypeTemplateConnection = {
  __typename?: "FeedbackTypeTemplateConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FeedbackTypeTemplateEdge>>;
};

/** A Relay edge containing a `FeedbackTypeTemplate` and its cursor. */
export type FeedbackTypeTemplateEdge = {
  __typename?: "FeedbackTypeTemplateEdge";
  /** The item at the end of the edge */
  node?: Maybe<FeedbackTypeTemplate>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** Enum for First Look Status */
export enum FirstLookStatus {
  ReadyForReview = "READY_FOR_REVIEW",
  SampleIsCorrect = "SAMPLE_IS_CORRECT",
  FeedbackInProgress = "FEEDBACK_IN_PROGRESS",
  FeedbackInProgressExtractionInProgress = "FEEDBACK_IN_PROGRESS_EXTRACTION_IN_PROGRESS",
  FeedbackInProgressExtractionFailed = "FEEDBACK_IN_PROGRESS_EXTRACTION_FAILED",
  FeedbackAppliedReadyForReview = "FEEDBACK_APPLIED_READY_FOR_REVIEW",
  ErrorInvalidStatus = "ERROR_INVALID_STATUS",
}

export type Formula = Node & {
  __typename?: "Formula";
  customer?: Maybe<Customer>;
  function_name: Scalars["String"];
  /** The ID of the object. */
  id: Scalars["ID"];
  input_optional_params?: Maybe<Scalars["JSONString"]>;
  is_deleted: Scalars["Boolean"];
  is_enabled: Scalars["Boolean"];
  /**
   * FILTERS, SORT FIELD TYPE
   * # TEXT - value_str (can also represent an address)
   * # BOOLEAN - value_bool
   * # DROP_DOWN - value_str
   * # MULTI_SELECT_DROP_DOWN - value_list
   * # DATE - value_iso_date
   * # DURATION - value_float (# TODO: value_duration)
   * # PERCENTAGE - value_float
   * # CURRENCY - value_float (# TODO: value_currency)
   * # CURRENCY_TYPE - value_str
   * # GEO_COUNTRY, GEO_STATE, GEO_CITY - value_str
   */
  output_data_point_field?: Maybe<DataPointField>;
  input_data_point_fields?: Maybe<DataPointFieldConnection>;
};

export type FormulaInput_Data_Point_FieldsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  automation_tag?: InputMaybe<Scalars["String"]>;
  collision_type?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_field_on_dashboard?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  display_annotations?: InputMaybe<Scalars["Boolean"]>;
  display_comment_field?: InputMaybe<Scalars["Boolean"]>;
  display_if_empty?: InputMaybe<Scalars["Boolean"]>;
  display_in_dashboard_filter?: InputMaybe<Scalars["Boolean"]>;
  display_manual_review?: InputMaybe<Scalars["Boolean"]>;
  display_on_checklist?: InputMaybe<Scalars["Boolean"]>;
  display_on_document?: InputMaybe<Scalars["Boolean"]>;
  external_source?: InputMaybe<Scalars["String"]>;
  extraction_consolidation_preferences?: InputMaybe<Scalars["JSONString"]>;
  extraction_field_type?: InputMaybe<Scalars["String"]>;
  extraction_logic?: InputMaybe<Scalars["JSONString"]>;
  extraction_method_instances_config?: InputMaybe<Scalars["JSONString"]>;
  field_semantics?: InputMaybe<Scalars["String"]>;
  field_type?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  group_priority?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  inheritance_logic?: InputMaybe<Scalars["JSONString"]>;
  internal_mapping?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  is_non_standard?: InputMaybe<Scalars["Boolean"]>;
  last_published_at?: InputMaybe<Scalars["DateTime"]>;
  manual_review?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  options?: InputMaybe<Scalars["String"]>;
  pin_default_comment?: InputMaybe<Scalars["Boolean"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  required_for_push_integration?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<Scalars["String"]>;
  value_format?: InputMaybe<Scalars["String"]>;
  action_type?: InputMaybe<Scalars["ID"]>;
  last_published_by?: InputMaybe<Scalars["ID"]>;
  parent_data_point_field?: InputMaybe<Scalars["ID"]>;
};

export type Integration = Node & {
  __typename?: "Integration";
  api_url: Scalars["String"];
  customer?: Maybe<Customer>;
  /** The ID of the object. */
  id: Scalars["ID"];
  integration_type: Scalars["String"];
  token_type: Scalars["String"];
  user_name?: Maybe<Scalars["String"]>;
  domain?: Maybe<Scalars["String"]>;
  organization_id?: Maybe<Scalars["String"]>;
};

export type IntegrationConnection = {
  __typename?: "IntegrationConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationEdge>>;
};

/** A Relay edge containing a `Integration` and its cursor. */
export type IntegrationEdge = {
  __typename?: "IntegrationEdge";
  /** The item at the end of the edge */
  node?: Maybe<Integration>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type InternalState = Node & {
  __typename?: "InternalState";
  customer?: Maybe<Customer>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_deleted: Scalars["Boolean"];
  name: Scalars["String"];
  role?: Maybe<Role>;
};

export type InternalStateConnection = {
  __typename?: "InternalStateConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InternalStateEdge>>;
};

/** A Relay edge containing a `InternalState` and its cursor. */
export type InternalStateEdge = {
  __typename?: "InternalStateEdge";
  /** The item at the end of the edge */
  node?: Maybe<InternalState>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type InternalWorkflow = Node & {
  __typename?: "InternalWorkflow";
  customer?: Maybe<Customer>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_deleted: Scalars["Boolean"];
  name: Scalars["String"];
  is_final_state?: Maybe<Scalars["Boolean"]>;
  states?: Maybe<InternalStateConnection>;
};

export type InternalWorkflowStatesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  role?: InputMaybe<Scalars["ID"]>;
};

/** Represents the input for submitting feedback */
export type LearningFeedbackInput = {
  verified_sample_id: Scalars["ID"];
  user_option: Scalars["String"];
  user_learning_option?: InputMaybe<Scalars["String"]>;
  user_feedback?: InputMaybe<Scalars["String"]>;
  feedback_highlights?: InputMaybe<Array<InputMaybe<FeedbackHighlightInput>>>;
};

export type ManualUploadField = {
  __typename?: "ManualUploadField";
  data_point_field?: Maybe<DataPointField>;
  is_optional?: Maybe<Scalars["Boolean"]>;
  deal_or_document?: Maybe<Scalars["String"]>;
};

export type ManualUploadFieldList = {
  __typename?: "ManualUploadFieldList";
  manual_upload_fields?: Maybe<Array<Maybe<ManualUploadField>>>;
};

export type MarkDocumentAsReviewed = {
  __typename?: "MarkDocumentAsReviewed";
  document?: Maybe<Document>;
};

export type MatchConfig = Node & {
  __typename?: "MatchConfig";
  fetch_values_from: Scalars["String"];
  match_fields?: Maybe<MatchFieldConfigConnection>;
  rule: Scalars["String"];
  rule_config?: Maybe<Scalars["JSONString"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type MatchConfigMatch_FieldsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  is_optional?: InputMaybe<Scalars["Boolean"]>;
  is_truth_field?: InputMaybe<Scalars["Boolean"]>;
  data_point_field?: InputMaybe<Scalars["ID"]>;
  document_type?: InputMaybe<Scalars["ID"]>;
};

export type MatchField = {
  is_truth_field?: InputMaybe<Scalars["Boolean"]>;
  data_point_field_id: Scalars["ID"];
  document_type_id?: InputMaybe<Scalars["ID"]>;
};

export type MatchFieldConfig = Node & {
  __typename?: "MatchFieldConfig";
  /**
   * FILTERS, SORT FIELD TYPE
   * # TEXT - value_str (can also represent an address)
   * # BOOLEAN - value_bool
   * # DROP_DOWN - value_str
   * # MULTI_SELECT_DROP_DOWN - value_list
   * # DATE - value_iso_date
   * # DURATION - value_float (# TODO: value_duration)
   * # PERCENTAGE - value_float
   * # CURRENCY - value_float (# TODO: value_currency)
   * # CURRENCY_TYPE - value_str
   * # GEO_COUNTRY, GEO_STATE, GEO_CITY - value_str
   */
  data_point_field?: Maybe<DataPointField>;
  document_type?: Maybe<DocumentType>;
  is_optional: Scalars["Boolean"];
  is_truth_field: Scalars["Boolean"];
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type MatchFieldConfigConnection = {
  __typename?: "MatchFieldConfigConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MatchFieldConfigEdge>>;
};

/** A Relay edge containing a `MatchFieldConfig` and its cursor. */
export type MatchFieldConfigEdge = {
  __typename?: "MatchFieldConfigEdge";
  /** The item at the end of the edge */
  node?: Maybe<MatchFieldConfig>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type MatchResult = Node & {
  __typename?: "MatchResult";
  confidence?: Maybe<Scalars["Float"]>;
  decision: Scalars["Boolean"];
  reason?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type MoveDocumentToDealAttachment = {
  __typename?: "MoveDocumentToDealAttachment";
  deal?: Maybe<Deal>;
  deal_data_points?: Maybe<Array<Maybe<DataPoint>>>;
};

export type MoveToNextInternalState = {
  __typename?: "MoveToNextInternalState";
  document?: Maybe<Document>;
};

export type MutateAnnotation = {
  internal_name: Scalars["String"];
  positions: Array<InputMaybe<AnnotationPositionInput>>;
};

export type Mutation = {
  __typename?: "Mutation";
  rename_attachment?: Maybe<RenameAttachment>;
  create_counter_party?: Maybe<CreateCounterParty>;
  edit_counter_party?: Maybe<EditCounterParty>;
  edit_document?: Maybe<EditDocument>;
  create_document_note?: Maybe<CreateDocumentNote>;
  delete_document_note?: Maybe<DeleteDocumentNote>;
  delete_document_attachment?: Maybe<DeleteDocumentAttachment>;
  bulk_edit_document_state?: Maybe<BulkEditDocumentState>;
  bulk_edit_document_assignee?: Maybe<BulkEditDocumentAssignee>;
  mark_document_as_reviewed?: Maybe<MarkDocumentAsReviewed>;
  edit_document_attachment?: Maybe<EditDocumentAttachment>;
  change_document_classification_level?: Maybe<ChangeDocumentClassificationLevel>;
  delete_document?: Maybe<DeleteDocument>;
  edit_data_point_value?: Maybe<EditDataPoint>;
  edit_data_table_data_point_value?: Maybe<EditDataTableDataPoint>;
  create_comment?: Maybe<CreateComment>;
  delete_comment?: Maybe<DeleteComment>;
  edit_comment?: Maybe<EditComment>;
  create_deal?: Maybe<CreateDeal>;
  edit_deal?: Maybe<EditDeal>;
  delete_deal?: Maybe<DeleteDeal>;
  create_deal_note?: Maybe<CreateDealNote>;
  delete_deal_note?: Maybe<DeleteDealNote>;
  delete_deal_attachment?: Maybe<DeleteDealAttachment>;
  bulk_edit_deal_state?: Maybe<BulkEditDealState>;
  bulk_edit_deal_assignee?: Maybe<BulkEditDealAssignee>;
  change_deal_attachment_as_document?: Maybe<ChangeDealAttachmentAsDocument>;
  move_document_to_deal_attachment?: Maybe<MoveDocumentToDealAttachment>;
  edit_deal_attachment?: Maybe<EditDealAttachment>;
  resolve_collision?: Maybe<ResolveCollisionManually>;
  resolve_matching?: Maybe<ResolveMatchingManually>;
  create_dashboard_query?: Maybe<CreateDashboardQuery>;
  delete_dashboard_query?: Maybe<DeleteDashboardQuery>;
  pre_annotation_form?: Maybe<PreAnnotationFormPayload>;
  post_annotation_publish?: Maybe<PostAnnotationPublish>;
  create_annotation?: Maybe<CreateAnnotationPayload>;
  retry_annotation?: Maybe<RetryAnnotationPayload>;
  delete_annotation?: Maybe<DeleteAnnotation>;
  update_annotation_review_state?: Maybe<UpdateAnnotationReviewState>;
  move_to_next_internal_state?: Maybe<MoveToNextInternalState>;
  create_additional_review?: Maybe<CreateAdditionalReview>;
  bulk_edit_internal_assignee?: Maybe<BulkEditInternalAssignee>;
  delete_annotations_by_internal_name?: Maybe<DeleteAnnotationsByInternalName>;
  /**
   * GraphQL mutation for submitting user-generated feedback on a verified sample in a document verification system.
   * It handles the update of the verified sample based on user feedback and manages the associated state changes.
   *
   * Attributes:
   *     verified_sample (graphene.Field): Field to contain the updated verified sample object after successful mutation.
   */
  submit_feedback?: Maybe<SubmitFeedback>;
  /** Mutation to reset feedback for a given data point field and document. */
  reset_feedback?: Maybe<ResetFeedback>;
  /** Mutation to rerun extraction for given list of documents or deals. */
  rerun_extraction?: Maybe<RerunExtraction>;
  create_workspace?: Maybe<CreateWorkspace>;
  create_role?: Maybe<CreateRole>;
  edit_role?: Maybe<EditRole>;
  create_user?: Maybe<CreateUser>;
  edit_user?: Maybe<EditUser>;
  delete_user?: Maybe<DeleteUser>;
  /**
   * Creates deal/document data point field. Document DPF will be created for INTERNAL fields.
   * Data point fields are validate
   * return: The deal data point field created
   */
  create_data_point_field?: Maybe<CreateDataPointField>;
  /**
   * Edits deal/document data point field.
   * If variable is None, the field will not be changed in the data point field
   * return: The deal data point field after the edit
   */
  edit_data_point_field?: Maybe<EditDataPointField>;
  /**
   * Edit group priority
   * group_name: Group name that is being reordered
   * move_after_group_name: Group that group_name is being moved after. If move_after_group_name is None, then
   * group_name will be moved to the top
   * return: The data point fields with group priority edited
   */
  edit_group_priority?: Maybe<EditGroupPriority>;
  /**
   * Edits name for a group
   * old_group_name: Current name of the group
   * new_group_name: New name of the group
   * return: The data point fields with the group edited
   */
  edit_group_name?: Maybe<EditGroupName>;
  /**
   * Reorders data point field in the checklist
   * data_point_field_id: The deal data point field being moved
   * move_after_data_point_field_id: If move_after_data_point_field_id is not None, data_point_field_id will be moved after this
   * move_to_top_group: If move_to_top_group is not None, data_point_field_id will be moved to the top of this group
   * return: The data point fields whose priorities have been edited
   */
  reorder_checklist_item?: Maybe<ReorderChecklistItem>;
  /**
   * Deletes data point field
   * data_point_field_id: The data point field being deleted
   * return: success if the data point field was successfully deleted
   */
  delete_data_point_field?: Maybe<DeleteDataPointField>;
  change_neutral_tag?: Maybe<ChangeNeutralTag>;
  /**
   * Publish data point field to update global learning prompt from local learnings
   * data_point_field_id: The data point field being pusblished
   * return: The data point field after publish
   */
  publish_data_point_field?: Maybe<PublishDataPointField>;
  bulk_edit_document_type?: Maybe<BulkEditDocumentType>;
  create_workflow?: Maybe<CreateWorkflow>;
  edit_workflow?: Maybe<EditWorkflow>;
  create_state?: Maybe<CreateState>;
  edit_state?: Maybe<EditState>;
  create_integration?: Maybe<CreateIntegration>;
  edit_integration?: Maybe<EditIntegration>;
  delete_integration?: Maybe<DeleteIntegration>;
  create_field_extraction_method_instance?: Maybe<CreateFieldExtractionMethodInstance>;
  edit_field_extraction_method_instance?: Maybe<EditFieldExtractionMethodInstance>;
};

export type MutationRename_AttachmentArgs = {
  attachment_document_id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationCreate_Counter_PartyArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationEdit_Counter_PartyArgs = {
  counter_party_id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationEdit_DocumentArgs = {
  assignee_id?: InputMaybe<Scalars["ID"]>;
  counter_party_id?: InputMaybe<Scalars["ID"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
  document_type_id?: InputMaybe<Scalars["ID"]>;
  edit_type?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  state_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationCreate_Document_NoteArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
  text?: InputMaybe<Scalars["String"]>;
};

export type MutationDelete_Document_NoteArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
  note_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationDelete_Document_AttachmentArgs = {
  attachment_id?: InputMaybe<Scalars["ID"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationBulk_Edit_Document_StateArgs = {
  document_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  state_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationBulk_Edit_Document_AssigneeArgs = {
  document_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  user_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationMark_Document_As_ReviewedArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
  is_reviewed?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationEdit_Document_AttachmentArgs = {
  attachment_id?: InputMaybe<Scalars["ID"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
  edit_type?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationChange_Document_Classification_LevelArgs = {
  classification_level?: InputMaybe<ClassificationLevel>;
  deal_id?: InputMaybe<Scalars["ID"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationDelete_DocumentArgs = {
  deal_id?: InputMaybe<Scalars["ID"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationEdit_Data_Point_ValueArgs = {
  accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  data_point_id?: InputMaybe<Scalars["ID"]>;
  edit_type?: InputMaybe<Scalars["String"]>;
  is_reviewed?: InputMaybe<Scalars["Boolean"]>;
  reviewed_state_priority?: InputMaybe<Scalars["Int"]>;
  user_needs_review?: InputMaybe<Scalars["Boolean"]>;
  value?: InputMaybe<Scalars["String"]>;
  values?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MutationEdit_Data_Table_Data_Point_ValueArgs = {
  data_point_field_id?: InputMaybe<Scalars["ID"]>;
  deal_id?: InputMaybe<Scalars["ID"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
  table_columns?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  table_rows?: InputMaybe<Scalars["JSONString"]>;
};

export type MutationCreate_CommentArgs = {
  comment_type?: InputMaybe<Scalars["String"]>;
  data_point_id?: InputMaybe<Scalars["ID"]>;
  text?: InputMaybe<Scalars["String"]>;
};

export type MutationDelete_CommentArgs = {
  comment_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationEdit_CommentArgs = {
  comment_id?: InputMaybe<Scalars["ID"]>;
  comment_type?: InputMaybe<Scalars["String"]>;
  edit_type?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
};

export type MutationCreate_DealArgs = {
  counter_party_id?: InputMaybe<Scalars["ID"]>;
  deal_config_id?: InputMaybe<Scalars["ID"]>;
  document_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationEdit_DealArgs = {
  assignee_id?: InputMaybe<Scalars["ID"]>;
  counter_party_id?: InputMaybe<Scalars["ID"]>;
  deal_config_id?: InputMaybe<Scalars["ID"]>;
  deal_id?: InputMaybe<Scalars["ID"]>;
  document_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  edit_type?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  state_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationDelete_DealArgs = {
  deal_id?: InputMaybe<Scalars["ID"]>;
  delete_documents?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreate_Deal_NoteArgs = {
  deal_id?: InputMaybe<Scalars["ID"]>;
  text?: InputMaybe<Scalars["String"]>;
};

export type MutationDelete_Deal_NoteArgs = {
  deal_id?: InputMaybe<Scalars["ID"]>;
  note_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationDelete_Deal_AttachmentArgs = {
  attachment_id?: InputMaybe<Scalars["ID"]>;
  deal_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationBulk_Edit_Deal_StateArgs = {
  deal_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  state_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationBulk_Edit_Deal_AssigneeArgs = {
  deal_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  user_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationChange_Deal_Attachment_As_DocumentArgs = {
  attachment_id?: InputMaybe<Scalars["ID"]>;
  deal_id?: InputMaybe<Scalars["ID"]>;
  is_counter_party_level?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationMove_Document_To_Deal_AttachmentArgs = {
  deal_id?: InputMaybe<Scalars["ID"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationEdit_Deal_AttachmentArgs = {
  attachment_id?: InputMaybe<Scalars["ID"]>;
  deal_id?: InputMaybe<Scalars["ID"]>;
  edit_type?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationResolve_CollisionArgs = {
  deal_data_point_id?: InputMaybe<Scalars["ID"]>;
  resolved_data_point_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type MutationResolve_MatchingArgs = {
  matching_data_point_global_id?: InputMaybe<Scalars["ID"]>;
  matching_resolved_data_point_global_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationCreate_Dashboard_QueryArgs = {
  dashboard?: InputMaybe<Scalars["String"]>;
  is_default?: InputMaybe<Scalars["Boolean"]>;
  is_shared?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  query_string?: InputMaybe<Scalars["String"]>;
};

export type MutationDelete_Dashboard_QueryArgs = {
  dashboard_query_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationPre_Annotation_FormArgs = {
  attachment?: InputMaybe<Scalars["Boolean"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
  document_type_id?: InputMaybe<Scalars["ID"]>;
  signed?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPost_Annotation_PublishArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationCreate_AnnotationArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
  new_annotation?: InputMaybe<MutateAnnotation>;
};

export type MutationRetry_AnnotationArgs = {
  data_points_info_to_update?: InputMaybe<
    Array<InputMaybe<SingleDataPointInput>>
  >;
  document_id?: InputMaybe<Scalars["ID"]>;
  new_annotation?: InputMaybe<MutateAnnotation>;
};

export type MutationDelete_AnnotationArgs = {
  annotation_id?: InputMaybe<Scalars["ID"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationUpdate_Annotation_Review_StateArgs = {
  annotation_id: Scalars["ID"];
  document_id: Scalars["ID"];
  review_state: Scalars["String"];
};

export type MutationMove_To_Next_Internal_StateArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
  is_move_confirmed?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreate_Additional_ReviewArgs = {
  assignee_id?: InputMaybe<Scalars["ID"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationBulk_Edit_Internal_AssigneeArgs = {
  assignee_id?: InputMaybe<Scalars["ID"]>;
  document_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type MutationDelete_Annotations_By_Internal_NameArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
};

export type MutationSubmit_FeedbackArgs = {
  feedback_input: LearningFeedbackInput;
};

export type MutationReset_FeedbackArgs = {
  data_point_field_id: Scalars["ID"];
  document_id: Scalars["ID"];
};

export type MutationRerun_ExtractionArgs = {
  rerun_extraction_input: RerunExtractionInput;
};

export type MutationCreate_WorkspaceArgs = {
  business_workflow?: InputMaybe<BusinessWorkflow>;
  business_workflow_details?: InputMaybe<Scalars["String"]>;
  customer_domain?: InputMaybe<Scalars["String"]>;
  industry?: InputMaybe<WorkspaceIndustry>;
  industry_details?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationCreate_RoleArgs = {
  feature_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationEdit_RoleArgs = {
  features_to_add?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  features_to_remove?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  role_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationCreate_UserArgs = {
  first_name?: InputMaybe<Scalars["String"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  role_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  user_primary_email?: InputMaybe<Scalars["String"]>;
  user_secondary_email?: InputMaybe<Scalars["String"]>;
};

export type MutationEdit_UserArgs = {
  first_name?: InputMaybe<Scalars["String"]>;
  last_name?: InputMaybe<Scalars["String"]>;
  roles_to_add?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  roles_to_remove?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  user_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationDelete_UserArgs = {
  user_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationCreate_Data_Point_FieldArgs = {
  action_type_id?: InputMaybe<Scalars["ID"]>;
  collision_type?: InputMaybe<Scalars["String"]>;
  default_comment_if_present?: InputMaybe<Scalars["String"]>;
  default_field_on_dashboard?: InputMaybe<Scalars["Boolean"]>;
  default_value?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  display_annotations?: InputMaybe<Scalars["Boolean"]>;
  display_if_empty?: InputMaybe<Scalars["Boolean"]>;
  display_in_dashboard_filter?: InputMaybe<Scalars["Boolean"]>;
  display_on_checklist?: InputMaybe<Scalars["Boolean"]>;
  display_on_document?: InputMaybe<Scalars["Boolean"]>;
  extraction_field_type?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  extraction_method_id?: InputMaybe<Scalars["ID"]>;
  field_semantics?: InputMaybe<Scalars["String"]>;
  field_type?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  match_children_fields?: InputMaybe<Array<InputMaybe<MatchField>>>;
  match_rule?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  neutral_tag?: InputMaybe<Scalars["String"]>;
  options?: InputMaybe<Scalars["JSONString"]>;
  prompt_fragments?: InputMaybe<Array<InputMaybe<PromptFragmentInput>>>;
  resolution_strategy?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  source?: InputMaybe<Scalars["String"]>;
  table_composition_config?: InputMaybe<
    Array<InputMaybe<TableCompositionConfigInput>>
  >;
  value_format?: InputMaybe<Scalars["String"]>;
};

export type MutationEdit_Data_Point_FieldArgs = {
  collision_type?: InputMaybe<Scalars["String"]>;
  data_point_field_id?: InputMaybe<Scalars["ID"]>;
  deal_id?: InputMaybe<Scalars["ID"]>;
  default_comment_if_present?: InputMaybe<Scalars["String"]>;
  default_field_on_dashboard?: InputMaybe<Scalars["Boolean"]>;
  default_value?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  display_annotations?: InputMaybe<Scalars["Boolean"]>;
  display_if_empty?: InputMaybe<Scalars["Boolean"]>;
  display_in_dashboard_filter?: InputMaybe<Scalars["Boolean"]>;
  display_on_checklist?: InputMaybe<Scalars["Boolean"]>;
  display_on_document?: InputMaybe<Scalars["Boolean"]>;
  extraction_description?: InputMaybe<Scalars["String"]>;
  extraction_field_type?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  extraction_logic_path?: InputMaybe<Scalars["String"]>;
  extraction_method_id?: InputMaybe<Scalars["ID"]>;
  extraction_method_instance_id?: InputMaybe<Scalars["ID"]>;
  extraction_path_selection_type?: InputMaybe<Scalars["String"]>;
  field_semantics?: InputMaybe<Scalars["String"]>;
  field_type?: InputMaybe<Scalars["String"]>;
  is_enabled?: InputMaybe<Scalars["Boolean"]>;
  match_children_fields?: InputMaybe<Array<InputMaybe<MatchField>>>;
  match_rule?: InputMaybe<Scalars["String"]>;
  matching_prompt_description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  options?: InputMaybe<Scalars["JSONString"]>;
  resolution_strategy?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  value_format?: InputMaybe<Scalars["String"]>;
};

export type MutationEdit_Group_PriorityArgs = {
  group_name?: InputMaybe<Scalars["String"]>;
  move_after_group_name?: InputMaybe<Scalars["String"]>;
};

export type MutationEdit_Group_NameArgs = {
  new_group_name?: InputMaybe<Scalars["String"]>;
  old_group_name?: InputMaybe<Scalars["String"]>;
};

export type MutationReorder_Checklist_ItemArgs = {
  data_point_field_id?: InputMaybe<Scalars["ID"]>;
  move_after_data_point_field_id?: InputMaybe<Scalars["ID"]>;
  move_to_top_group?: InputMaybe<Scalars["String"]>;
};

export type MutationDelete_Data_Point_FieldArgs = {
  data_point_field_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationChange_Neutral_TagArgs = {
  change_annotations?: InputMaybe<Scalars["Boolean"]>;
  change_other_fields?: InputMaybe<Scalars["Boolean"]>;
  data_point_field_id?: InputMaybe<Scalars["ID"]>;
  new_neutral_tag?: InputMaybe<Scalars["String"]>;
  old_neutral_tag?: InputMaybe<Scalars["String"]>;
};

export type MutationPublish_Data_Point_FieldArgs = {
  data_point_field_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationBulk_Edit_Document_TypeArgs = {
  doc_types_id_name_list?: InputMaybe<Scalars["JSONString"]>;
};

export type MutationCreate_WorkflowArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type MutationEdit_WorkflowArgs = {
  name?: InputMaybe<Scalars["String"]>;
  workflow_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationCreate_StateArgs = {
  final?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  role_id?: InputMaybe<Scalars["ID"]>;
  workflow_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationEdit_StateArgs = {
  name?: InputMaybe<Scalars["String"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  role_id?: InputMaybe<Scalars["ID"]>;
  state_id?: InputMaybe<Scalars["ID"]>;
  workflow_id?: InputMaybe<Scalars["ID"]>;
};

export type MutationCreate_IntegrationArgs = {
  api_url?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<Scalars["String"]>;
  integration_type?: InputMaybe<Scalars["String"]>;
  organization_id?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  token?: InputMaybe<Scalars["String"]>;
  token_type?: InputMaybe<Scalars["String"]>;
  user_name?: InputMaybe<Scalars["String"]>;
};

export type MutationEdit_IntegrationArgs = {
  api_url?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<Scalars["String"]>;
  integration_type?: InputMaybe<Scalars["String"]>;
  organization_id?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  token?: InputMaybe<Scalars["String"]>;
  token_type?: InputMaybe<Scalars["String"]>;
  user_name?: InputMaybe<Scalars["String"]>;
};

export type MutationCreate_Field_Extraction_Method_InstanceArgs = {
  data_point_field_id?: InputMaybe<Scalars["ID"]>;
  extraction_description?: InputMaybe<Scalars["String"]>;
  extraction_method_id?: InputMaybe<Scalars["ID"]>;
  is_enabled?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationEdit_Field_Extraction_Method_InstanceArgs = {
  data_point_field_id?: InputMaybe<Scalars["ID"]>;
  extraction_description?: InputMaybe<Scalars["String"]>;
  extraction_method_id?: InputMaybe<Scalars["ID"]>;
  extraction_method_instance_id?: InputMaybe<Scalars["ID"]>;
  is_enabled?: InputMaybe<Scalars["Boolean"]>;
};

export type NeutralTag = {
  __typename?: "NeutralTag";
  internal_name?: Maybe<Scalars["String"]>;
  external_name?: Maybe<Scalars["String"]>;
  in_neutral_tagset?: Maybe<Scalars["Boolean"]>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type Note = Node & {
  __typename?: "Note";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_deleted: Scalars["Boolean"];
  text: Scalars["String"];
};

export type NoteConnection = {
  __typename?: "NoteConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NoteEdge>>;
};

/** A Relay edge containing a `Note` and its cursor. */
export type NoteEdge = {
  __typename?: "NoteEdge";
  /** The item at the end of the edge */
  node?: Maybe<Note>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
};

export type PostAnnotationPublish = {
  __typename?: "PostAnnotationPublish";
  document?: Maybe<Document>;
  data_points?: Maybe<Array<Maybe<DataPoint>>>;
};

export type PreAnnotationFormFailure = {
  __typename?: "PreAnnotationFormFailure";
  field?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
};

export type PreAnnotationFormPayload =
  | PreAnnotationFormSuccess
  | PreAnnotationFormFailure;

export type PreAnnotationFormSuccess = {
  __typename?: "PreAnnotationFormSuccess";
  document?: Maybe<Document>;
};

export type PromptFragment = Node & {
  __typename?: "PromptFragment";
  content: Scalars["String"];
  created_at: Scalars["DateTime"];
  created_by?: Maybe<User>;
  /** The ID of the object. */
  id: Scalars["ID"];
  internal_name: Scalars["String"];
  modified_at: Scalars["DateTime"];
  modified_by?: Maybe<User>;
  prompt_fragment_template?: Maybe<PromptFragmentTemplate>;
  purpose?: Maybe<Scalars["String"]>;
  reference_type?: Maybe<Scalars["String"]>;
};

export type PromptFragmentInput = {
  prompt_fragment_template_id: Scalars["ID"];
  content: Scalars["String"];
};

export type PromptFragmentTemplate = Node & {
  __typename?: "PromptFragmentTemplate";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  data_type: Scalars["String"];
  default_value?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  display_guidelines?: Maybe<Scalars["String"]>;
  display_name: Scalars["String"];
  display_question?: Maybe<Scalars["String"]>;
  fragment_type: Scalars["String"];
  /** The ID of the object. */
  id: Scalars["ID"];
  internal_name: Scalars["String"];
  is_hidden?: Maybe<Scalars["Boolean"]>;
  is_mandatory?: Maybe<Scalars["Boolean"]>;
  modified_at?: Maybe<Scalars["DateTime"]>;
  modified_by?: Maybe<User>;
  prompt_template?: Maybe<PromptTemplate>;
};

export type PromptTemplate = Node & {
  __typename?: "PromptTemplate";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  description?: Maybe<Scalars["String"]>;
  display_name: Scalars["String"];
  /** The ID of the object. */
  id: Scalars["ID"];
  internal_name: Scalars["String"];
  models?: Maybe<Array<Maybe<Scalars["String"]>>>;
  modified_at?: Maybe<Scalars["DateTime"]>;
  modified_by?: Maybe<User>;
  service_providers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  system_prompt_template: Scalars["String"];
  user_prompt_template: Scalars["String"];
  version: Scalars["Int"];
  prompt_fragment_templates?: Maybe<Array<Maybe<PromptFragmentTemplate>>>;
};

export type PromptTemplateConnection = {
  __typename?: "PromptTemplateConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PromptTemplateEdge>>;
};

/** A Relay edge containing a `PromptTemplate` and its cursor. */
export type PromptTemplateEdge = {
  __typename?: "PromptTemplateEdge";
  /** The item at the end of the edge */
  node?: Maybe<PromptTemplate>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export enum ProvenanceStatus {
  InitialSampleGeneration = "INITIAL_SAMPLE_GENERATION",
  ManualSampleCreation = "MANUAL_SAMPLE_CREATION",
  ExtractionRerun = "EXTRACTION_RERUN",
}

/**
 * Publish data point field to update global learning prompt from local learnings
 * data_point_field_id: The data point field being pusblished
 * return: The data point field after publish
 */
export type PublishDataPointField = {
  __typename?: "PublishDataPointField";
  data_point_field?: Maybe<DataPointField>;
};

export type Query = {
  __typename?: "Query";
  node?: Maybe<Node>;
  customers?: Maybe<CustomerConnection>;
  counter_parties?: Maybe<CounterPartyConnection>;
  data_point_fields?: Maybe<DataPointFieldConnection>;
  document_data_table_fields?: Maybe<DataPointFieldConnection>;
  deal_data_table_fields?: Maybe<DataPointFieldConnection>;
  matching_children_data_point_fields?: Maybe<DataPointFieldConnection>;
  states?: Maybe<StateConnection>;
  internal_states?: Maybe<InternalStateConnection>;
  transitions?: Maybe<TransitionConnection>;
  deals?: Maybe<DealConnection>;
  documents?: Maybe<DocumentConnection>;
  assigned_to_me?: Maybe<DocumentConnection>;
  data_points?: Maybe<DataPointConnection>;
  document_types?: Maybe<DocumentTypeConnection>;
  dashboard_queries?: Maybe<DashboardQueryConnection>;
  annotation_todos?: Maybe<AnnotationTodoConnection>;
  integrations?: Maybe<IntegrationConnection>;
  extraction_methods?: Maybe<ExtractionMethodConnection>;
  dashboard_config?: Maybe<DashboardConfiguration>;
  checklist_query: ChecklistQueryResult;
  document_with_specific_annotations?: Maybe<Document>;
  current_user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  documents_by_counter_party?: Maybe<Array<Maybe<Document>>>;
  deals_by_counter_party?: Maybe<Array<Maybe<Deal>>>;
  documents_by_deal?: Maybe<Array<Maybe<Document>>>;
  counter_parties_by_name?: Maybe<Array<Maybe<CounterParty>>>;
  deals_by_name?: Maybe<Array<Maybe<Deal>>>;
  deals_affected_by_document_level_change?: Maybe<AffectedDealsItem>;
  analytics_feature_fields?: Maybe<Array<Maybe<AnalyticsField>>>;
  semantic_search_fields?: Maybe<Array<Maybe<SemanticSearchField>>>;
  manual_upload_type?: Maybe<Scalars["String"]>;
  manual_upload_fields?: Maybe<ManualUploadFieldList>;
  annotation_tagset?: Maybe<Array<Maybe<AnnotationTag>>>;
  document_attachments?: Maybe<Array<Maybe<Attachment>>>;
  deal_attachments?: Maybe<Array<Maybe<Attachment>>>;
  deals_and_documents_with_attachment?: Maybe<DealsDocumentsWithAttachment>;
  transition_audit?: Maybe<TransitionAudit>;
  fetch_advanced_dashboard_fields?: Maybe<AdvancedDashboardField>;
  fetch_advanced_dashboard?: Maybe<AdvancedDashboard>;
  fetch_advanced_dashboard_count?: Maybe<AdvancedDashboard>;
  fetch_advanced_deals_dashboard_fields?: Maybe<AdvancedDashboardField>;
  fetch_advanced_deals_dashboard?: Maybe<AdvancedDashboard>;
  fetch_advanced_deals_dashboard_count?: Maybe<AdvancedDashboard>;
  fetch_dashboard_export?: Maybe<AdvancedDashboard>;
  fetch_integration_validation?: Maybe<AdvancedDashboard>;
  fetch_advanced_attachments_dashboard_fields?: Maybe<AdvancedDashboardField>;
  fetch_advanced_attachments_dashboard?: Maybe<AdvancedDashboard>;
  fetch_advanced_attachments_dashboard_count?: Maybe<AdvancedDashboard>;
  internal_workflow?: Maybe<InternalWorkflow>;
  deals_affected_by_document_delete?: Maybe<Array<Maybe<Deal>>>;
  documents_affected_by_deal_delete?: Maybe<DocumentsAffectedByDealDelete>;
  latest_state_audits?: Maybe<Array<Maybe<StateAudit>>>;
  document_data_point_fields_by_internal_name?: Maybe<
    Array<Maybe<DataPointField>>
  >;
  checklist_groups?: Maybe<Array<Maybe<Scalars["String"]>>>;
  data_points_for_group?: Maybe<Array<Maybe<DataPoint>>>;
  notes_tab_count?: Maybe<Scalars["Int"]>;
  nested_children_data_points?: Maybe<Array<Maybe<DataPoint>>>;
  comments_data?: Maybe<Array<Maybe<Comment>>>;
  notes_data?: Maybe<Array<Maybe<Note>>>;
  customers_by_name?: Maybe<Array<Maybe<Customer>>>;
  cci_roles?: Maybe<Array<Maybe<Role>>>;
  cci_features?: Maybe<Array<Maybe<Feature>>>;
  cci_users?: Maybe<Array<Maybe<User>>>;
  cci_workflows?: Maybe<Array<Maybe<Workflow>>>;
  cci_states?: Maybe<Array<Maybe<State>>>;
  cci_document_types?: Maybe<Array<Maybe<DocumentType>>>;
  cci_groups?: Maybe<Array<Maybe<Scalars["String"]>>>;
  cci_deal_data_point_fields?: Maybe<Array<Maybe<DataPointField>>>;
  cci_neutral_tags?: Maybe<Array<Maybe<NeutralTag>>>;
  prompt_fragments?: Maybe<Array<Maybe<PromptFragment>>>;
  actions_to_extraction_method_mapping: Array<ActionType>;
  latest_feedback_template?: Maybe<FeedbackTemplate>;
  latest_verified_sample?: Maybe<VerifiedSample>;
  latest_verified_samples_by_document?: Maybe<Array<Maybe<VerifiedSample>>>;
  latest_sample_generation_run?: Maybe<SampleGenerationRun>;
};

export type QueryNodeArgs = {
  id: Scalars["ID"];
};

export type QueryCustomersArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  alternate_emails?: InputMaybe<Scalars["String"]>;
  counter_party_level_classification_config?: InputMaybe<Scalars["JSONString"]>;
  customer_email?: InputMaybe<Scalars["String"]>;
  deal_level_classification_config?: InputMaybe<Scalars["JSONString"]>;
  document_deduplication_config?: InputMaybe<Scalars["JSONString"]>;
  email_domain_allow_list?: InputMaybe<Scalars["String"]>;
  email_domain_for_idp?: InputMaybe<Scalars["String"]>;
  expiry_date?: InputMaybe<Scalars["DateTime"]>;
  external_context_logic?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  idp_id?: InputMaybe<Scalars["String"]>;
  limits?: InputMaybe<Scalars["String"]>;
  manual_upload_config?: InputMaybe<Scalars["JSONString"]>;
  name?: InputMaybe<Scalars["String"]>;
  trial?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type QueryCounter_PartiesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ID"]>;
};

export type QueryData_Point_FieldsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  automation_tag?: InputMaybe<Scalars["String"]>;
  collision_type?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_field_on_dashboard?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  display_annotations?: InputMaybe<Scalars["Boolean"]>;
  display_comment_field?: InputMaybe<Scalars["Boolean"]>;
  display_if_empty?: InputMaybe<Scalars["Boolean"]>;
  display_in_dashboard_filter?: InputMaybe<Scalars["Boolean"]>;
  display_manual_review?: InputMaybe<Scalars["Boolean"]>;
  display_on_checklist?: InputMaybe<Scalars["Boolean"]>;
  display_on_document?: InputMaybe<Scalars["Boolean"]>;
  external_source?: InputMaybe<Scalars["String"]>;
  extraction_consolidation_preferences?: InputMaybe<Scalars["JSONString"]>;
  extraction_field_type?: InputMaybe<Scalars["String"]>;
  extraction_logic?: InputMaybe<Scalars["JSONString"]>;
  extraction_method_instances_config?: InputMaybe<Scalars["JSONString"]>;
  field_semantics?: InputMaybe<Scalars["String"]>;
  field_type?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  group_priority?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  inheritance_logic?: InputMaybe<Scalars["JSONString"]>;
  internal_mapping?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  is_non_standard?: InputMaybe<Scalars["Boolean"]>;
  last_published_at?: InputMaybe<Scalars["DateTime"]>;
  manual_review?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  options?: InputMaybe<Scalars["String"]>;
  pin_default_comment?: InputMaybe<Scalars["Boolean"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  required_for_push_integration?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<Scalars["String"]>;
  value_format?: InputMaybe<Scalars["String"]>;
  action_type?: InputMaybe<Scalars["ID"]>;
  last_published_by?: InputMaybe<Scalars["ID"]>;
  parent_data_point_field?: InputMaybe<Scalars["ID"]>;
};

export type QueryDocument_Data_Table_FieldsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  automation_tag?: InputMaybe<Scalars["String"]>;
  collision_type?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_field_on_dashboard?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  display_annotations?: InputMaybe<Scalars["Boolean"]>;
  display_comment_field?: InputMaybe<Scalars["Boolean"]>;
  display_if_empty?: InputMaybe<Scalars["Boolean"]>;
  display_in_dashboard_filter?: InputMaybe<Scalars["Boolean"]>;
  display_manual_review?: InputMaybe<Scalars["Boolean"]>;
  display_on_checklist?: InputMaybe<Scalars["Boolean"]>;
  display_on_document?: InputMaybe<Scalars["Boolean"]>;
  external_source?: InputMaybe<Scalars["String"]>;
  extraction_consolidation_preferences?: InputMaybe<Scalars["JSONString"]>;
  extraction_field_type?: InputMaybe<Scalars["String"]>;
  extraction_logic?: InputMaybe<Scalars["JSONString"]>;
  extraction_method_instances_config?: InputMaybe<Scalars["JSONString"]>;
  field_semantics?: InputMaybe<Scalars["String"]>;
  field_type?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  group_priority?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  inheritance_logic?: InputMaybe<Scalars["JSONString"]>;
  internal_mapping?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  is_non_standard?: InputMaybe<Scalars["Boolean"]>;
  last_published_at?: InputMaybe<Scalars["DateTime"]>;
  manual_review?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  options?: InputMaybe<Scalars["String"]>;
  pin_default_comment?: InputMaybe<Scalars["Boolean"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  required_for_push_integration?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<Scalars["String"]>;
  value_format?: InputMaybe<Scalars["String"]>;
  action_type?: InputMaybe<Scalars["ID"]>;
  last_published_by?: InputMaybe<Scalars["ID"]>;
  parent_data_point_field?: InputMaybe<Scalars["ID"]>;
};

export type QueryDeal_Data_Table_FieldsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  automation_tag?: InputMaybe<Scalars["String"]>;
  collision_type?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_field_on_dashboard?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  display_annotations?: InputMaybe<Scalars["Boolean"]>;
  display_comment_field?: InputMaybe<Scalars["Boolean"]>;
  display_if_empty?: InputMaybe<Scalars["Boolean"]>;
  display_in_dashboard_filter?: InputMaybe<Scalars["Boolean"]>;
  display_manual_review?: InputMaybe<Scalars["Boolean"]>;
  display_on_checklist?: InputMaybe<Scalars["Boolean"]>;
  display_on_document?: InputMaybe<Scalars["Boolean"]>;
  external_source?: InputMaybe<Scalars["String"]>;
  extraction_consolidation_preferences?: InputMaybe<Scalars["JSONString"]>;
  extraction_field_type?: InputMaybe<Scalars["String"]>;
  extraction_logic?: InputMaybe<Scalars["JSONString"]>;
  extraction_method_instances_config?: InputMaybe<Scalars["JSONString"]>;
  field_semantics?: InputMaybe<Scalars["String"]>;
  field_type?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  group_priority?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  inheritance_logic?: InputMaybe<Scalars["JSONString"]>;
  internal_mapping?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  is_non_standard?: InputMaybe<Scalars["Boolean"]>;
  last_published_at?: InputMaybe<Scalars["DateTime"]>;
  manual_review?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  options?: InputMaybe<Scalars["String"]>;
  pin_default_comment?: InputMaybe<Scalars["Boolean"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  required_for_push_integration?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<Scalars["String"]>;
  value_format?: InputMaybe<Scalars["String"]>;
  action_type?: InputMaybe<Scalars["ID"]>;
  last_published_by?: InputMaybe<Scalars["ID"]>;
  parent_data_point_field?: InputMaybe<Scalars["ID"]>;
};

export type QueryMatching_Children_Data_Point_FieldsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  automation_tag?: InputMaybe<Scalars["String"]>;
  collision_type?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  default_field_on_dashboard?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  display_annotations?: InputMaybe<Scalars["Boolean"]>;
  display_comment_field?: InputMaybe<Scalars["Boolean"]>;
  display_if_empty?: InputMaybe<Scalars["Boolean"]>;
  display_in_dashboard_filter?: InputMaybe<Scalars["Boolean"]>;
  display_manual_review?: InputMaybe<Scalars["Boolean"]>;
  display_on_checklist?: InputMaybe<Scalars["Boolean"]>;
  display_on_document?: InputMaybe<Scalars["Boolean"]>;
  external_source?: InputMaybe<Scalars["String"]>;
  extraction_consolidation_preferences?: InputMaybe<Scalars["JSONString"]>;
  extraction_field_type?: InputMaybe<Scalars["String"]>;
  extraction_logic?: InputMaybe<Scalars["JSONString"]>;
  extraction_method_instances_config?: InputMaybe<Scalars["JSONString"]>;
  field_semantics?: InputMaybe<Scalars["String"]>;
  field_type?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  group_priority?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  inheritance_logic?: InputMaybe<Scalars["JSONString"]>;
  internal_mapping?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  is_non_standard?: InputMaybe<Scalars["Boolean"]>;
  last_published_at?: InputMaybe<Scalars["DateTime"]>;
  manual_review?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  options?: InputMaybe<Scalars["String"]>;
  pin_default_comment?: InputMaybe<Scalars["Boolean"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  required_for_push_integration?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<Scalars["String"]>;
  value_format?: InputMaybe<Scalars["String"]>;
  action_type?: InputMaybe<Scalars["ID"]>;
  last_published_by?: InputMaybe<Scalars["ID"]>;
  parent_data_point_field?: InputMaybe<Scalars["ID"]>;
};

export type QueryStatesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  final?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  initial?: InputMaybe<Scalars["Boolean"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<Scalars["ID"]>;
  workflow?: InputMaybe<Scalars["ID"]>;
};

export type QueryInternal_StatesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  role?: InputMaybe<Scalars["ID"]>;
};

export type QueryTransitionsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  push_on_transition?: InputMaybe<Scalars["Boolean"]>;
  current_state?: InputMaybe<Scalars["ID"]>;
  next_state?: InputMaybe<Scalars["ID"]>;
};

export type QueryDealsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  alias?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  external_metadata?: InputMaybe<Scalars["JSONString"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  original_external_metadata?: InputMaybe<Scalars["JSONString"]>;
  assignee?: InputMaybe<Scalars["ID"]>;
  counter_party?: InputMaybe<Scalars["ID"]>;
  state?: InputMaybe<Scalars["ID"]>;
};

export type QueryDocumentsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  alias?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  external_document?: InputMaybe<Scalars["Boolean"]>;
  extraction_status?: InputMaybe<Scalars["String"]>;
  hash?: InputMaybe<Scalars["JSONString"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_status?: InputMaybe<Scalars["String"]>;
  is_counter_party_level?: InputMaybe<Scalars["Boolean"]>;
  is_deal_level?: InputMaybe<Scalars["Boolean"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  is_legacy?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  original_name?: InputMaybe<Scalars["String"]>;
  page_count?: InputMaybe<Scalars["Int"]>;
  prizmdoc_id?: InputMaybe<Scalars["String"]>;
  project?: InputMaybe<Scalars["String"]>;
  qc_reason?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<Scalars["String"]>;
  visualizer_version?: InputMaybe<Scalars["Float"]>;
  assignee?: InputMaybe<Scalars["ID"]>;
  counter_party?: InputMaybe<Scalars["ID"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  document_type?: InputMaybe<Scalars["ID"]>;
  internal_assignee?: InputMaybe<Scalars["ID"]>;
  internal_state?: InputMaybe<Scalars["ID"]>;
  internal_workflow?: InputMaybe<Scalars["ID"]>;
  state?: InputMaybe<Scalars["ID"]>;
};

export type QueryAssigned_To_MeArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  alias?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  external_document?: InputMaybe<Scalars["Boolean"]>;
  extraction_status?: InputMaybe<Scalars["String"]>;
  hash?: InputMaybe<Scalars["JSONString"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_status?: InputMaybe<Scalars["String"]>;
  is_counter_party_level?: InputMaybe<Scalars["Boolean"]>;
  is_deal_level?: InputMaybe<Scalars["Boolean"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  is_legacy?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  original_name?: InputMaybe<Scalars["String"]>;
  page_count?: InputMaybe<Scalars["Int"]>;
  prizmdoc_id?: InputMaybe<Scalars["String"]>;
  project?: InputMaybe<Scalars["String"]>;
  qc_reason?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<Scalars["String"]>;
  visualizer_version?: InputMaybe<Scalars["Float"]>;
  assignee?: InputMaybe<Scalars["ID"]>;
  counter_party?: InputMaybe<Scalars["ID"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  document_type?: InputMaybe<Scalars["ID"]>;
  internal_assignee?: InputMaybe<Scalars["ID"]>;
  internal_state?: InputMaybe<Scalars["ID"]>;
  internal_workflow?: InputMaybe<Scalars["ID"]>;
  state?: InputMaybe<Scalars["ID"]>;
};

export type QueryData_PointsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  annotator_needs_review?: InputMaybe<Scalars["Boolean"]>;
  annotator_needs_review_type?: InputMaybe<Scalars["String"]>;
  display_value?: InputMaybe<Scalars["String"]>;
  has_accounting_impact?: InputMaybe<Scalars["Boolean"]>;
  hyperlink?: InputMaybe<Scalars["Int"]>;
  hyperlink_url?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_collision_resolved?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_1?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_2?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_3?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_4?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed_5?: InputMaybe<Scalars["Boolean"]>;
  last_updated_at?: InputMaybe<Scalars["DateTime"]>;
  pin?: InputMaybe<Scalars["Boolean"]>;
  user_needs_review?: InputMaybe<Scalars["Boolean"]>;
  user_needs_review_type?: InputMaybe<Scalars["String"]>;
  value_bool?: InputMaybe<Scalars["Boolean"]>;
  value_float?: InputMaybe<Scalars["Float"]>;
  value_iso_date?: InputMaybe<Scalars["DateTime"]>;
  value_list?: InputMaybe<Scalars["String"]>;
  value_str?: InputMaybe<Scalars["String"]>;
  data_point_field?: InputMaybe<Scalars["ID"]>;
  deal?: InputMaybe<Scalars["ID"]>;
  document?: InputMaybe<Scalars["ID"]>;
  last_updated_by?: InputMaybe<Scalars["ID"]>;
  matching_resolved_data_point?: InputMaybe<Scalars["ID"]>;
  parent_data_point?: InputMaybe<Scalars["ID"]>;
};

export type QueryDocument_TypesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_fallback_type?: InputMaybe<Scalars["Boolean"]>;
  multiple_documents?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ID"]>;
};

export type QueryDashboard_QueriesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  dashboard?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_shared?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  query_string?: InputMaybe<Scalars["String"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
};

export type QueryAnnotation_TodosArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["ID"]>;
  last_updated_at?: InputMaybe<Scalars["DateTime"]>;
  customer?: InputMaybe<Scalars["ID"]>;
};

export type QueryIntegrationsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  api_url?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  integration_type?: InputMaybe<Scalars["String"]>;
  token_type?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ID"]>;
};

export type QueryExtraction_MethodsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  allowed_for_every_customer?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  display_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_freetext?: InputMaybe<Scalars["Boolean"]>;
  is_tabular?: InputMaybe<Scalars["Boolean"]>;
  version?: InputMaybe<Scalars["String"]>;
};

export type QueryChecklist_QueryArgs = {
  deal_id?: InputMaybe<Scalars["ID"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
  checklist_query_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryDocument_With_Specific_AnnotationsArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
  data_point_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type QueryUsersArgs = {
  internal?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryDocuments_By_Counter_PartyArgs = {
  counter_party_id?: InputMaybe<Scalars["ID"]>;
  document_name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  filters?: InputMaybe<Scalars["JSONString"]>;
};

export type QueryDeals_By_Counter_PartyArgs = {
  counter_party_id?: InputMaybe<Scalars["ID"]>;
  deal_name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  filters?: InputMaybe<Scalars["JSONString"]>;
};

export type QueryDocuments_By_DealArgs = {
  deal_id?: InputMaybe<Scalars["ID"]>;
  document_name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryCounter_Parties_By_NameArgs = {
  counter_party_name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  exclude_counter_party_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type QueryDeals_By_NameArgs = {
  deal_name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryDeals_Affected_By_Document_Level_ChangeArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryManual_Upload_FieldsArgs = {
  config_type?: InputMaybe<Scalars["String"]>;
  document_type_name?: InputMaybe<Scalars["String"]>;
};

export type QueryAnnotation_TagsetArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryDocument_AttachmentsArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryDeal_AttachmentsArgs = {
  deal_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryDeals_And_Documents_With_AttachmentArgs = {
  attachment_document_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryTransition_AuditArgs = {
  parent_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryFetch_Advanced_Dashboard_FieldsArgs = {
  chosen_data_point_field_ids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
};

export type QueryFetch_Advanced_DashboardArgs = {
  chosen_data_point_field_ids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  filters?: InputMaybe<Scalars["JSONString"]>;
  sort_config?: InputMaybe<Array<InputMaybe<Scalars["JSONString"]>>>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryFetch_Advanced_Dashboard_CountArgs = {
  chosen_data_point_field_ids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  filters?: InputMaybe<Scalars["JSONString"]>;
  sort_config?: InputMaybe<Array<InputMaybe<Scalars["JSONString"]>>>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryFetch_Advanced_Deals_Dashboard_FieldsArgs = {
  chosen_data_point_field_ids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
};

export type QueryFetch_Advanced_Deals_DashboardArgs = {
  chosen_data_point_field_ids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  filters?: InputMaybe<Scalars["JSONString"]>;
  sort_config?: InputMaybe<Array<InputMaybe<Scalars["JSONString"]>>>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryFetch_Advanced_Deals_Dashboard_CountArgs = {
  chosen_data_point_field_ids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  filters?: InputMaybe<Scalars["JSONString"]>;
  sort_config?: InputMaybe<Array<InputMaybe<Scalars["JSONString"]>>>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryFetch_Dashboard_ExportArgs = {
  chosen_data_point_field_ids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  filters?: InputMaybe<Scalars["JSONString"]>;
  sort_config?: InputMaybe<Array<InputMaybe<Scalars["JSONString"]>>>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
  flow_type?: InputMaybe<Scalars["String"]>;
  dashboard_type?: InputMaybe<Scalars["String"]>;
};

export type QueryFetch_Integration_ValidationArgs = {
  data_point_field_id?: InputMaybe<Scalars["ID"]>;
  list_of_external_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryFetch_Advanced_Attachments_DashboardArgs = {
  filters?: InputMaybe<Scalars["JSONString"]>;
  sort_config?: InputMaybe<Array<InputMaybe<Scalars["JSONString"]>>>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryFetch_Advanced_Attachments_Dashboard_CountArgs = {
  filters?: InputMaybe<Scalars["JSONString"]>;
  sort_config?: InputMaybe<Array<InputMaybe<Scalars["JSONString"]>>>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryInternal_WorkflowArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryDeals_Affected_By_Document_DeleteArgs = {
  document_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryDocuments_Affected_By_Deal_DeleteArgs = {
  deal_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryLatest_State_AuditsArgs = {
  workflow_id?: InputMaybe<Scalars["ID"]>;
  deal_id?: InputMaybe<Scalars["ID"]>;
  document_id?: InputMaybe<Scalars["ID"]>;
  all_state_audits?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryDocument_Data_Point_Fields_By_Internal_NameArgs = {
  internal_name?: InputMaybe<Scalars["String"]>;
};

export type QueryChecklist_GroupsArgs = {
  resource_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryData_Points_For_GroupArgs = {
  resource_id?: InputMaybe<Scalars["ID"]>;
  group?: InputMaybe<Scalars["String"]>;
};

export type QueryNotes_Tab_CountArgs = {
  resource_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryNested_Children_Data_PointsArgs = {
  parent_data_point_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryComments_DataArgs = {
  resource_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryNotes_DataArgs = {
  resource_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryCustomers_By_NameArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type QueryCci_RolesArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type QueryCci_FeaturesArgs = {
  name?: InputMaybe<Scalars["String"]>;
  permission?: InputMaybe<Scalars["String"]>;
};

export type QueryCci_UsersArgs = {
  customer_id?: InputMaybe<Scalars["ID"]>;
  user_name?: InputMaybe<Scalars["String"]>;
  first_name?: InputMaybe<Scalars["String"]>;
  last_name?: InputMaybe<Scalars["String"]>;
};

export type QueryCci_WorkflowsArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type QueryCci_StatesArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type QueryCci_Document_TypesArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type QueryCci_GroupsArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type QueryCci_Deal_Data_Point_FieldsArgs = {
  name?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
};

export type QueryCci_Neutral_TagsArgs = {
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryPrompt_FragmentsArgs = {
  data_point_field_id: Scalars["ID"];
};

export type QueryLatest_Feedback_TemplateArgs = {
  feedback_type?: InputMaybe<Scalars["String"]>;
  extraction_field_type?: InputMaybe<Scalars["String"]>;
};

export type QueryLatest_Verified_SampleArgs = {
  document_id: Scalars["ID"];
  data_point_field_id: Scalars["ID"];
};

export type QueryLatest_Verified_Samples_By_DocumentArgs = {
  data_point_field_id: Scalars["ID"];
};

export type QueryLatest_Sample_Generation_RunArgs = {
  data_point_field_id: Scalars["ID"];
};

export type RenameAttachment = {
  __typename?: "RenameAttachment";
  attachment_document?: Maybe<Document>;
  attachment_document_meta?: Maybe<AdvancedDashboardDocumentMeta>;
  deals?: Maybe<Array<Maybe<Deal>>>;
  documents?: Maybe<Array<Maybe<Document>>>;
};

/**
 * Reorders data point field in the checklist
 * data_point_field_id: The deal data point field being moved
 * move_after_data_point_field_id: If move_after_data_point_field_id is not None, data_point_field_id will be moved after this
 * move_to_top_group: If move_to_top_group is not None, data_point_field_id will be moved to the top of this group
 * return: The data point fields whose priorities have been edited
 */
export type ReorderChecklistItem = {
  __typename?: "ReorderChecklistItem";
  data_point_fields?: Maybe<Array<Maybe<DataPointField>>>;
};

/** Mutation to rerun extraction for given list of documents or deals. */
export type RerunExtraction = {
  __typename?: "RerunExtraction";
  rerun_extraction_result?: Maybe<RerunExtractionResult>;
};

/** Represents the input for rerunning extraction */
export type RerunExtractionInput = {
  document_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  deal_ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

/** Union type for rerun extraction result */
export type RerunExtractionResult =
  | DocumentsRerunExtractionResult
  | DealsRerunExtractionResult;

/** Mutation to reset feedback for a given data point field and document. */
export type ResetFeedback = {
  __typename?: "ResetFeedback";
  verified_sample?: Maybe<VerifiedSample>;
};

export type ResolveCollisionManually = {
  __typename?: "ResolveCollisionManually";
  data_point?: Maybe<DataPoint>;
};

export type ResolveMatchingManually = {
  __typename?: "ResolveMatchingManually";
  data_point?: Maybe<DataPoint>;
};

export type RetryAnnotationFailure = {
  __typename?: "RetryAnnotationFailure";
  errors?: Maybe<Array<Maybe<RetryAnnotationSingleFailure>>>;
};

export type RetryAnnotationPayload =
  | RetryAnnotationSuccess
  | RetryAnnotationFailure;

export type RetryAnnotationSingleFailure = {
  __typename?: "RetryAnnotationSingleFailure";
  data_point_id?: Maybe<Scalars["ID"]>;
  error?: Maybe<Scalars["String"]>;
};

export type RetryAnnotationSuccess = {
  __typename?: "RetryAnnotationSuccess";
  document?: Maybe<Document>;
  data_points?: Maybe<Array<Maybe<DataPoint>>>;
};

export type Role = Node & {
  __typename?: "Role";
  customer?: Maybe<Customer>;
  default_deals_query?: Maybe<Scalars["String"]>;
  default_documents_query?: Maybe<Scalars["String"]>;
  features?: Maybe<FeatureConnection>;
  /** The ID of the object. */
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type RoleFeaturesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  permission?: InputMaybe<Scalars["String"]>;
};

export type RoleConnection = {
  __typename?: "RoleConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RoleEdge>>;
};

/** A Relay edge containing a `Role` and its cursor. */
export type RoleEdge = {
  __typename?: "RoleEdge";
  /** The item at the end of the edge */
  node?: Maybe<Role>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/**
 * GraphQL type for SampleGenerationCluster.
 *
 * This class represents a cluster of documents generated during the sample generation process.
 * It includes information about the cluster's status, size, and associated documents.
 */
export type SampleGenerationCluster = Node & {
  __typename?: "SampleGenerationCluster";
  cluster_representative_document?: Maybe<Document>;
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  /**
   * Represents a sample generation run in the system.
   *
   * This document keeps track of the status and timing of a sample generation process
   * for a specific data point field.
   */
  current_sample_generation_run_id?: Maybe<SampleGenerationRun>;
  current_status?: Maybe<Scalars["String"]>;
  /**
   * FILTERS, SORT FIELD TYPE
   * # TEXT - value_str (can also represent an address)
   * # BOOLEAN - value_bool
   * # DROP_DOWN - value_str
   * # MULTI_SELECT_DROP_DOWN - value_list
   * # DATE - value_iso_date
   * # DURATION - value_float (# TODO: value_duration)
   * # PERCENTAGE - value_float
   * # CURRENCY - value_float (# TODO: value_currency)
   * # CURRENCY_TYPE - value_str
   * # GEO_COUNTRY, GEO_STATE, GEO_CITY - value_str
   */
  data_point_field?: Maybe<DataPointField>;
  extraction_status?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  member_documents?: Maybe<DocumentConnection>;
  modified_at?: Maybe<Scalars["DateTime"]>;
  modified_by?: Maybe<User>;
  size?: Maybe<Scalars["Int"]>;
};

/**
 * GraphQL type for SampleGenerationCluster.
 *
 * This class represents a cluster of documents generated during the sample generation process.
 * It includes information about the cluster's status, size, and associated documents.
 */
export type SampleGenerationClusterMember_DocumentsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  alias?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  external_document?: InputMaybe<Scalars["Boolean"]>;
  extraction_status?: InputMaybe<Scalars["String"]>;
  hash?: InputMaybe<Scalars["JSONString"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_status?: InputMaybe<Scalars["String"]>;
  is_counter_party_level?: InputMaybe<Scalars["Boolean"]>;
  is_deal_level?: InputMaybe<Scalars["Boolean"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  is_legacy?: InputMaybe<Scalars["Boolean"]>;
  is_reviewed?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  original_name?: InputMaybe<Scalars["String"]>;
  page_count?: InputMaybe<Scalars["Int"]>;
  prizmdoc_id?: InputMaybe<Scalars["String"]>;
  project?: InputMaybe<Scalars["String"]>;
  qc_reason?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<Scalars["String"]>;
  visualizer_version?: InputMaybe<Scalars["Float"]>;
  assignee?: InputMaybe<Scalars["ID"]>;
  counter_party?: InputMaybe<Scalars["ID"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  document_type?: InputMaybe<Scalars["ID"]>;
  internal_assignee?: InputMaybe<Scalars["ID"]>;
  internal_state?: InputMaybe<Scalars["ID"]>;
  internal_workflow?: InputMaybe<Scalars["ID"]>;
  state?: InputMaybe<Scalars["ID"]>;
};

/** GraphQL type for SampleGenerationRun. */
export type SampleGenerationRun = Node & {
  __typename?: "SampleGenerationRun";
  current_status?: Maybe<Scalars["String"]>;
  /**
   * FILTERS, SORT FIELD TYPE
   * # TEXT - value_str (can also represent an address)
   * # BOOLEAN - value_bool
   * # DROP_DOWN - value_str
   * # MULTI_SELECT_DROP_DOWN - value_list
   * # DATE - value_iso_date
   * # DURATION - value_float (# TODO: value_duration)
   * # PERCENTAGE - value_float
   * # CURRENCY - value_float (# TODO: value_currency)
   * # CURRENCY_TYPE - value_str
   * # GEO_COUNTRY, GEO_STATE, GEO_CITY - value_str
   */
  data_point_field?: Maybe<DataPointField>;
  ended_at?: Maybe<Scalars["DateTime"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  started_at?: Maybe<Scalars["DateTime"]>;
  started_by?: Maybe<User>;
  clusters?: Maybe<Array<Maybe<SampleGenerationCluster>>>;
};

export type SemanticSearchField = {
  __typename?: "SemanticSearchField";
  field_name: Scalars["String"];
  id: Scalars["String"];
};

export type SingleDataPointInput = {
  data_point_id?: InputMaybe<Scalars["ID"]>;
  value?: InputMaybe<Scalars["String"]>;
  values?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type State = Node & {
  __typename?: "State";
  final: Scalars["Boolean"];
  /** The ID of the object. */
  id: Scalars["ID"];
  initial: Scalars["Boolean"];
  is_deleted: Scalars["Boolean"];
  name: Scalars["String"];
  priority: Scalars["Int"];
  role?: Maybe<Role>;
  workflow?: Maybe<Workflow>;
  /** The type of state */
  state_type: StateType;
};

export type StateAudit = {
  __typename?: "StateAudit";
  state_id?: Maybe<Scalars["ID"]>;
  audit?: Maybe<ChangeLog>;
};

export type StateConnection = {
  __typename?: "StateConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StateEdge>>;
};

/** A Relay edge containing a `State` and its cursor. */
export type StateEdge = {
  __typename?: "StateEdge";
  /** The item at the end of the edge */
  node?: Maybe<State>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export enum StateType {
  Initial = "INITIAL",
  Autocomplete = "AUTOCOMPLETE",
  Complete = "COMPLETE",
  Review = "REVIEW",
  Failure = "FAILURE",
  Legacy = "LEGACY",
}

/**
 * GraphQL mutation for submitting user-generated feedback on a verified sample in a document verification system.
 * It handles the update of the verified sample based on user feedback and manages the associated state changes.
 *
 * Attributes:
 *     verified_sample (graphene.Field): Field to contain the updated verified sample object after successful mutation.
 */
export type SubmitFeedback = {
  __typename?: "SubmitFeedback";
  verified_sample?: Maybe<VerifiedSample>;
};

export type SystemFailure = Node & {
  __typename?: "SystemFailure";
  created_at?: Maybe<Scalars["DateTime"]>;
  error_message: Scalars["String"];
  /** The ID of the object. */
  id: Scalars["ID"];
  error_code?: Maybe<Scalars["String"]>;
};

export type SystemFailureConnection = {
  __typename?: "SystemFailureConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SystemFailureEdge>>;
};

/** A Relay edge containing a `SystemFailure` and its cursor. */
export type SystemFailureEdge = {
  __typename?: "SystemFailureEdge";
  /** The item at the end of the edge */
  node?: Maybe<SystemFailure>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type TableCompositionConfig = Node & {
  __typename?: "TableCompositionConfig";
  column_description?: Maybe<Scalars["String"]>;
  column_name: Scalars["String"];
  column_type: Scalars["String"];
  /** The ID of the object. */
  id: Scalars["ID"];
};

export type TableCompositionConfigConnection = {
  __typename?: "TableCompositionConfigConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TableCompositionConfigEdge>>;
};

/** A Relay edge containing a `TableCompositionConfig` and its cursor. */
export type TableCompositionConfigEdge = {
  __typename?: "TableCompositionConfigEdge";
  /** The item at the end of the edge */
  node?: Maybe<TableCompositionConfig>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type TableCompositionConfigInput = {
  column_name: Scalars["String"];
  column_type: Scalars["String"];
};

export type Transition = Node & {
  __typename?: "Transition";
  current_state?: Maybe<State>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_deleted: Scalars["Boolean"];
  name: Scalars["String"];
  next_state?: Maybe<State>;
  push_on_transition: Scalars["Boolean"];
};

export type TransitionAudit = {
  __typename?: "TransitionAudit";
  states?: Maybe<Array<Maybe<CustomState>>>;
};

export type TransitionConnection = {
  __typename?: "TransitionConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TransitionEdge>>;
};

/** A Relay edge containing a `Transition` and its cursor. */
export type TransitionEdge = {
  __typename?: "TransitionEdge";
  /** The item at the end of the edge */
  node?: Maybe<Transition>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type UpdateAnnotationReviewState = {
  __typename?: "UpdateAnnotationReviewState";
  annotation: Annotation;
  document: Document;
  data_points: Array<DataPoint>;
};

export type User = Node & {
  __typename?: "User";
  customers?: Maybe<CustomerConnection>;
  default_deals_query?: Maybe<DashboardQuery>;
  default_documents_query?: Maybe<DashboardQuery>;
  demo: Scalars["Boolean"];
  first_name?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_super_admin: Scalars["Boolean"];
  last_name?: Maybe<Scalars["String"]>;
  notification_email_ids?: Maybe<Array<Maybe<Scalars["String"]>>>;
  notification_preferences?: Maybe<Array<Maybe<Scalars["String"]>>>;
  roles?: Maybe<RoleConnection>;
  user_name: Scalars["String"];
  checklist_queries: Array<ChecklistQuery>;
};

export type UserCustomersArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  alternate_emails?: InputMaybe<Scalars["String"]>;
  counter_party_level_classification_config?: InputMaybe<Scalars["JSONString"]>;
  customer_email?: InputMaybe<Scalars["String"]>;
  deal_level_classification_config?: InputMaybe<Scalars["JSONString"]>;
  document_deduplication_config?: InputMaybe<Scalars["JSONString"]>;
  email_domain_allow_list?: InputMaybe<Scalars["String"]>;
  email_domain_for_idp?: InputMaybe<Scalars["String"]>;
  expiry_date?: InputMaybe<Scalars["DateTime"]>;
  external_context_logic?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  idp_id?: InputMaybe<Scalars["String"]>;
  limits?: InputMaybe<Scalars["String"]>;
  manual_upload_config?: InputMaybe<Scalars["JSONString"]>;
  name?: InputMaybe<Scalars["String"]>;
  trial?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type UserRolesArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  default_deals_query?: InputMaybe<Scalars["String"]>;
  default_documents_query?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ID"]>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserEdge>>;
};

/** A Relay edge containing a `User` and its cursor. */
export type UserEdge = {
  __typename?: "UserEdge";
  /** The item at the end of the edge */
  node?: Maybe<User>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type UserLearningOption = Node & {
  __typename?: "UserLearningOption";
  option_label?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  /** User learning option name */
  option?: Maybe<UserLearningOptionName>;
};

export type UserLearningOptionConnection = {
  __typename?: "UserLearningOptionConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserLearningOptionEdge>>;
};

/** A Relay edge containing a `UserLearningOption` and its cursor. */
export type UserLearningOptionEdge = {
  __typename?: "UserLearningOptionEdge";
  /** The item at the end of the edge */
  node?: Maybe<UserLearningOption>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** Enum for possible user learning options */
export enum UserLearningOptionName {
  LearningIsCorrect = "LEARNING_IS_CORRECT",
  LearningNeedsImprovement = "LEARNING_NEEDS_IMPROVEMENT",
}

/** Enum for User Option */
export enum UserOption {
  CorrectlyExtracted = "CORRECTLY_EXTRACTED",
  CorrectlyNotExtracted = "CORRECTLY_NOT_EXTRACTED",
  IncorrectlyExtracted = "INCORRECTLY_EXTRACTED",
  CompletelyMissed = "COMPLETELY_MISSED",
}

/** GraphQL type for verified samples. */
export type VerifiedSample = Node & {
  __typename?: "VerifiedSample";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  data_point_annotations?: Maybe<AnnotationConnection>;
  /**
   * FILTERS, SORT FIELD TYPE
   * # TEXT - value_str (can also represent an address)
   * # BOOLEAN - value_bool
   * # DROP_DOWN - value_str
   * # MULTI_SELECT_DROP_DOWN - value_list
   * # DATE - value_iso_date
   * # DURATION - value_float (# TODO: value_duration)
   * # PERCENTAGE - value_float
   * # CURRENCY - value_float (# TODO: value_currency)
   * # CURRENCY_TYPE - value_str
   * # GEO_COUNTRY, GEO_STATE, GEO_CITY - value_str
   */
  data_point_field?: Maybe<DataPointField>;
  deal?: Maybe<Deal>;
  document?: Maybe<Document>;
  /** Models the user feedback */
  feedback?: Maybe<Feedback>;
  history?: Maybe<VerifiedSampleHistoryConnection>;
  /** The ID of the object. */
  id: Scalars["ID"];
  modified_at?: Maybe<Scalars["DateTime"]>;
  modified_by?: Maybe<User>;
  /** Category of feedback. */
  feedback_category?: Maybe<FeedbackCategory>;
  data_point_snapshot?: Maybe<DataPoint>;
  /** Feedback status of the verified sample */
  status?: Maybe<FeedbackStatus>;
  provenance?: Maybe<ProvenanceStatus>;
  /** First look status of the verified sample */
  first_look_status?: Maybe<FirstLookStatus>;
};

/** GraphQL type for verified samples. */
export type VerifiedSampleData_Point_AnnotationsArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  core_id?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internal_name?: InputMaybe<Scalars["String"]>;
  is_deleted?: InputMaybe<Scalars["Boolean"]>;
  label_name?: InputMaybe<Scalars["String"]>;
  label_type?: InputMaybe<Scalars["String"]>;
  last_updated_at?: InputMaybe<Scalars["DateTime"]>;
  review_state?: InputMaybe<Scalars["String"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
  last_updated_by?: InputMaybe<Scalars["ID"]>;
};

/** GraphQL type for verified samples. */
export type VerifiedSampleHistoryArgs = {
  before?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  created_by?: InputMaybe<Scalars["ID"]>;
};

export type VerifiedSampleHistory = Node & {
  __typename?: "VerifiedSampleHistory";
  created_at?: Maybe<Scalars["DateTime"]>;
  created_by?: Maybe<User>;
  /** The ID of the object. */
  id: Scalars["ID"];
  status?: Maybe<FeedbackStatus>;
};

export type VerifiedSampleHistoryConnection = {
  __typename?: "VerifiedSampleHistoryConnection";
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VerifiedSampleHistoryEdge>>;
};

/** A Relay edge containing a `VerifiedSampleHistory` and its cursor. */
export type VerifiedSampleHistoryEdge = {
  __typename?: "VerifiedSampleHistoryEdge";
  /** The item at the end of the edge */
  node?: Maybe<VerifiedSampleHistory>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

export type Workflow = Node & {
  __typename?: "Workflow";
  customer?: Maybe<Customer>;
  /** The ID of the object. */
  id: Scalars["ID"];
  is_deleted: Scalars["Boolean"];
  is_non_linear?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
};

export enum WorkspaceIndustry {
  AccommodationServices = "ACCOMMODATION_SERVICES",
  AdministrativeSupportServices = "ADMINISTRATIVE_SUPPORT_SERVICES",
  AgricultureForestryFishingHunting = "AGRICULTURE_FORESTRY_FISHING_HUNTING",
  ArtsEntertainmentRecreation = "ARTS_ENTERTAINMENT_RECREATION",
  Automotive = "AUTOMOTIVE",
  Banking = "BANKING",
  Construction = "CONSTRUCTION",
  Education = "EDUCATION",
  FinanceInsurance = "FINANCE_INSURANCE",
  FoodBeverageServices = "FOOD_BEVERAGE_SERVICES",
  HealthCareSocialAssistance = "HEALTH_CARE_SOCIAL_ASSISTANCE",
  InformationTechnology = "INFORMATION_TECHNOLOGY",
  Manufacturing = "MANUFACTURING",
  MiningOilGasExtraction = "MINING_OIL_GAS_EXTRACTION",
  ProfessionalScientificTechnicalServices = "PROFESSIONAL_SCIENTIFIC_TECHNICAL_SERVICES",
  PublicAdministration = "PUBLIC_ADMINISTRATION",
  RealEstateRentalLeasing = "REAL_ESTATE_RENTAL_LEASING",
  Retail = "RETAIL",
  SoftwareDevelopment = "SOFTWARE_DEVELOPMENT",
  Telecommunications = "TELECOMMUNICATIONS",
  TransportationLogistics = "TRANSPORTATION_LOGISTICS",
  TravelTourism = "TRAVEL_TOURISM",
  Utilities = "UTILITIES",
  WholesaleTrade = "WHOLESALE_TRADE",
  Other = "OTHER",
}

export type WorkspaceMetadata = Node & {
  __typename?: "WorkspaceMetadata";
  business_workflow: Scalars["String"];
  /** The ID of the object. */
  id: Scalars["ID"];
  supported_file_extensions: Array<Scalars["String"]>;
  workspace_app?: Maybe<Scalars["String"]>;
};
