import * as queryString from 'query-string'
import { DealsQuery } from '../graphql/codegen/operations'
import { SearchParams } from '../utils/searchParamUtils'
import { StateType } from '../graphql/codegen/schemas'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSetDealStateMutation, useStatesQuery } from '../graphql/codegen/hooks'

// types

type _DealFromQuery = NonNullable<NonNullable<NonNullable<DealsQuery['deals']>['edges']>[0]>['node']

// hooks

export const useTransitionDealStateFromAutocompleteToL1Review = (dealId: string, deal?: _DealFromQuery) => {
  const history = useHistory()
  const location = useLocation()
  const [isDealStateProcessing, setIsDealStateProcessing] = useState(false)
  const { data: statesData } = useStatesQuery()

  const handleDealStateComplete = useCallback(() => {
    setIsDealStateProcessing(false)

    const searchParams = new URLSearchParams(location.search)
    searchParams.delete(SearchParams.DEAL_STATE_CONVERT_AUTOCOMPLETE_TO_L1_REVIEW)

    history.replace({
      hash: location.hash,
      pathname: location.pathname,
      search: searchParams.toString() ? `?${searchParams.toString()}` : ''
    })
  }, [history, location.hash, location.pathname, location.search])

  const [setDealState] = useSetDealStateMutation({ onCompleted: handleDealStateComplete })

  const L1_REVIEW_STATE_ID = useMemo(() => {
    // return `id` of `state` with `state_type` of `REVIEW` with the lowest `priority`

    return (
      statesData?.states?.edges.reduce(
        (acc, current) =>
          current?.node?.state_type !== StateType.Review
            ? acc
            : !acc
            ? current
            : (current.node?.priority ?? Infinity) < (acc.node?.priority ?? Infinity)
            ? current
            : acc,
        null
      )?.node?.id ?? null
    )
  }, [statesData])

  const shouldTransitionDealState = Boolean(
    queryString.parse(location.search)[SearchParams.DEAL_STATE_CONVERT_AUTOCOMPLETE_TO_L1_REVIEW] !== undefined &&
      deal?.state?.state_type === StateType.Autocomplete &&
      L1_REVIEW_STATE_ID
  )

  useEffect(() => {
    ;(async () => {
      if (shouldTransitionDealState) {
        setIsDealStateProcessing(true)

        try {
          await setDealState({
            variables: { dealId, stateId: L1_REVIEW_STATE_ID! }
          })
        } catch (error) {
          console.error('Error updating deal state:', error)

          setIsDealStateProcessing(false)
        }
      }
    })()
  }, [L1_REVIEW_STATE_ID, deal, dealId, location.search, setDealState, shouldTransitionDealState])

  return { isDealStateProcessing }
}
