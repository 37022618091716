import { Box } from '@mui/material'
import { CciDealDataPointFieldsDocument, MatchingChildrenDataPointFieldsDocument, useCreateDataPointFieldMutation } from '../../../../../graphql/codegen/hooks'
import { CollisionTypeLabels } from '../../RightPanel/RightPanel_components/ChecklistTab/SharedInputs/CollisionTypeInput'
import { DisplayYesOrNo } from '../../Utils'
import { FieldTypes } from '../../RightPanel/RightPanel_components/ChecklistTab/CreateInputs/FieldTypeInput'
import { _DocumentType as _ResolutionStrategy } from '../../RightPanel/RightPanel_components/ChecklistTab/SharedInputs/MatchFields'
import { displayExtractionFieldType } from '../../RightPanel/RightPanel_components/ChecklistTab/SharedInputs/ExtractionFieldTypeInput'
import { formatMentions } from '../../../../../utils/commentUtils'
import { getDataTableMatchRuleLabel, getFieldTypeLabel, getMatchRuleLabel, getSourceLabel } from '../../../../../utils/cci'
import { isEmpty } from 'lodash'
import { useCciMainContext } from '../../../CCI_Main'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../components/Button'
import React, { FC, useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

// types

type _ReviewCreationProps = { closeModal: () => void; values: any }

// constants

export const FIELD_CREATION_SUCCESS_MESSAGE = 'Field successfully created.'

// components

export const ReviewCreation: FC<_ReviewCreationProps> = ({ closeModal, values }) => {
  const { setActiveComponent, setMessageTitle, setModalContent, setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess, setSelectedItem } =
    useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const { t } = useTranslation()

  const [createDataPointFieldMutation, { error: createDPFError, loading: createDPFLoading }] = useCreateDataPointFieldMutation({
    awaitRefetchQueries: true,
    onCompleted: response => {
      setActiveComponent(null)
      setModalContent({ dataPointField: response.create_data_point_field!.data_point_field })
      setMessageTitle(response.create_data_point_field!.data_point_field!.name)
      setModalSuccess(FIELD_CREATION_SUCCESS_MESSAGE)
      setSelectedItem('')
    },
    refetchQueries: [{ query: CciDealDataPointFieldsDocument }, { query: MatchingChildrenDataPointFieldsDocument }]
  })

  useEffect(() => {
    if (confirmed) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [confirmed])

  useEffect(() => {
    if (createDPFLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Creating Field…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [createDPFLoading])

  useEffect(() => {
    if (createDPFError) {
      setMessageTitle('Error creating field:')
      setModalError(createDPFError)
    }
    // eslint-disable-next-line
    }, [createDPFError])

  const handleSubmit = () => {
    const localValues = { ...values }

    if (localValues.field_semantics) {
      localValues.field_semantics = formatMentions(localValues.field_semantics)
    }

    const options = formatOptions()

    delete localValues.matchFieldType
    delete localValues.matchFieldLabelList
    delete localValues.options
    delete localValues.tag_type

    createDataPointFieldMutation({ variables: { options, ...localValues } })
  }

  const formatOptions = () => {
    if (!values?.options?.length) {
      return undefined
    }

    // strip option level neutral tags if tag type is field level
    if (values?.tag_type !== 'option_level') {
      return JSON.stringify(
        values?.options?.map((item: any) => {
          return { option: item?.option }
        })
      )
    } else {
      return JSON.stringify(values?.options)
    }
  }

  return (
    <>
      {values?.name && (
        <div className={css.reviewDiv}>
          <h4>{`Name:`}</h4>
          <p>{values?.name}</p>
        </div>
      )}

      {values?.group && (
        <div className={css.reviewDiv}>
          <h4>{`Group:`}</h4>
          <p>{values?.group}</p>
        </div>
      )}

      {values?.source && (
        <div className={css.reviewDiv}>
          <h4>{`Source:`}</h4>
          <p>{getSourceLabel(values.source)}</p>
        </div>
      )}

      {values?.neutral_tag && (
        <div className={css.reviewDiv}>
          <h4>{`Automation Tag:`}</h4>
          <p>{values?.neutral_tag}</p>
        </div>
      )}

      {values?.field_type && (
        <div className={css.reviewDiv}>
          <h4>{`Field Type:`}</h4>
          <p>{getFieldTypeLabel(values.field_type)}</p>
        </div>
      )}

      {!isEmpty(values?.extraction_field_type) && (
        <div className={css.reviewDiv}>
          <h4>Extraction Type:</h4>

          <p>{displayExtractionFieldType(values.extraction_field_type)}</p>
        </div>
      )}

      {values?.match_children_fields && (
        <div className={css.reviewDiv} style={{ marginBottom: 0 }}>
          <h4>Match Fields:</h4>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {values.matchFieldLabelList.map((matchFieldLabel: string) => (
              <div className={css.reviewOptions} key={matchFieldLabel}>
                <p style={{ width: '100%' }}>{matchFieldLabel}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {values?.match_rule && (
        <div className={css.reviewDiv}>
          <h4>Match Rule:</h4>
          <p>{values.matchFieldType === FieldTypes.DATA_TABLE ? getDataTableMatchRuleLabel(values.match_rule) : getMatchRuleLabel(values.match_rule)}</p>
        </div>
      )}

      {!isEmpty(values?.options) && (
        <div className={css.reviewDiv} style={{ marginBottom: 0 }}>
          <h4>{`Options:`}</h4>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {values?.options?.map((item: any, index: number) => (
              <div className={css.reviewOptions} key={item?.option + index}>
                <p style={{ width: '100%' }}>{item?.option}</p>
                {item?.neutral_tag && <p style={{ width: '100%' }}>{`Automation Tag: ${item?.neutral_tag}`}</p>}
              </div>
            ))}
          </div>
        </div>
      )}

      {values?.value_format && (
        <div className={css.reviewDiv}>
          <h4>{`Value Format:`}</h4>
          <p>{values?.value_format}</p>
        </div>
      )}

      {values?.default_value && (
        <div className={css.reviewDiv}>
          <h4>{`Default Value:`}</h4>
          <p>{values?.default_value}</p>
        </div>
      )}

      {values?.collision_type && (
        <div className={css.reviewDiv}>
          <h4>{`Collision Type:`}</h4>

          <p>{CollisionTypeLabels[values?.collision_type as keyof typeof CollisionTypeLabels]}</p>
        </div>
      )}

      {!isEmpty(values?.resolution_strategy) && (
        <div className={css.reviewDiv}>
          <h4>{`Resolution Strategy:`}</h4>

          <Box sx={{ textAlign: 'right' }}>
            {values.resolution_strategy.map((resolutionStrategy: _ResolutionStrategy, index: number) => (
              <Box key={index}>{resolutionStrategy}</Box>
            ))}
          </Box>
        </div>
      )}

      {values?.field_semantics && (
        <div className={css.reviewDiv}>
          <h4>Field Semantics:</h4>

          {/* Handle nested brackets in `react-mentions` */}
          <p>{values?.field_semantics.replace(/@\[(.*?)\]\((.*?)\)/g, '“$1”')}</p>
        </div>
      )}

      {values?.description && (
        <div className={css.reviewDiv}>
          <h4>Additional Info:</h4>

          <p>{values?.description}</p>
        </div>
      )}

      <div className={css.reviewDiv}>
        <h4>Always hide this field:</h4>

        <DisplayYesOrNo value={!values?.display_on_checklist} />
      </div>

      <div className={css.reviewDiv}>
        <h4>Hide this field only when it has no value:</h4>

        <DisplayYesOrNo value={!values?.display_if_empty} />
      </div>

      {!values?.display_on_checklist && (
        <div className={css.reviewDiv}>
          <h4>Display annotation highlights:</h4>

          <DisplayYesOrNo value={values?.display_annotations} />
        </div>
      )}

      <div className={css.reviewDiv}>
        <h4>Hide accounting impact button:</h4>

        <DisplayYesOrNo value={!values?.display_accounting_impact} />
      </div>

      <div className={css.reviewDiv}>
        <h4>Display as default column in {t('Deals')} and Documents table views:</h4>

        <DisplayYesOrNo value={values?.default_field_on_dashboard} />
      </div>

      <TypeToConfirm setConfirmed={setConfirmed} />

      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>

        <WithTooltip content={isDisabled ? 'Type Confirm to proceed' : ''}>
          <Button disabled={isDisabled} onClick={handleSubmit}>
            Submit
          </Button>
        </WithTooltip>
      </div>
    </>
  )
}
